import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { AnyLayer, LngLatLike, MapLayerMouseEvent, MapboxEvent } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapGL, {
  FullscreenControl,
  GeolocateControl,
  Layer,
  NavigationControl,
  Popup,
  ScaleControl,
  Source,
} from 'react-map-gl';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapGL from 'react-map-gl';
import styled from 'styled-components';
import { IMainPageContext, MainPageContext } from '../contexts/MainPageContext';
import { IMapBoxContext, MapBoxContext } from '../contexts/MapBoxContext';
import { IHoverInfo, IPoint } from '../interfaces/map.interface';
import AddMapServiceCustomControl from './CustomControls/AddMapService.custom';
import GoToXYCustomControl from './CustomControls/GoToXY.custom';
import HiddenCustomControl from './CustomControls/Hidden.custom';
import IdentityCustomControl from './CustomControls/Identity.custom';
import MeasurementCustomControl from './CustomControls/Measurement.custom';
import PanCustomControl from './CustomControls/Pan.custom';
import PanelControl from './map_controls/PanelControl';
import PrintControl from './map_controls/PrintControl';
import StyleSwitchControl from './map_controls/StyleSwitchControl';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import HomeCustomControl from './CustomControls/Home.custom';
import { tab } from '@testing-library/user-event/dist/tab';
import LatLngHover from './CustomControls/LatLngHover.custom';
import { UserContext } from '../contexts/UserContext';
import { lineString } from '@turf/turf';
import axios from 'axios';
import apiMapbox from '../apis/mapbox';
import { ISuggestion } from '../interfaces/base_map.interface';
import Information from './CustomControls/Information.custom';
import { metersToKilometers, secondsToHoursMinutes } from '../utils/unit-convert';
import DirectionToggleCustomControl from './CustomControls/DirectionToggle.custom';
import { LAYER_SERVICE_TYPE } from '../interfaces/enums';
import Toggle3DCustomControl from './CustomControls/Toggle3D.custom';

// import GeocoderControl from './map_controls/GeocoderControl';
// import DrawControl from './map_controls/DrawControl';

interface IMapBoxProps {
  hiddenMainSearch?: boolean;
}

const layerStyles: AnyLayer[] = [
  {
    id: 'source-search-polygon',
    type: 'fill',
    source: 'search_result',
    paint: {
      // 'fill-outline-color': '#000000',
      'fill-color': '#B42222',
      'fill-opacity': 0.5,
    },
    filter: ['==', '$type', 'Polygon'],
  },
  {
    id: 'source-search-point',
    type: 'circle',
    source: 'search_result',

    paint: {
      // 'circle-radius': 10,
      'circle-radius': {
        base: 10,
        stops: [
          [12, 10],
          [22, 15],
        ],
      },
      'circle-color': '#00f',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': 0.5,
    },
    filter: ['==', '$type', 'Point'],
  },
];

const MapBox: React.FC<IMapBoxProps> = ({ hiddenMainSearch }: IMapBoxProps) => {
  const {
    defaultLatlng,
    searchResults,
    map,
    setMap,
    map1,
    setMap1,
    hoverInfo,
    setHoverInfo,
    shouldShowPopup,
    viewState,
    setViewState,
    setShouldShowPopup,
    layerSelected,
    openCompareMap,
  } = useContext<IMainPageContext>(MainPageContext);

  const { userInformation } = useContext(UserContext);
  const [measureRuler, setMeasureRuler] = useState<{ from: number[]; to: number[] }>({
    from: [],
    to: [],
  });
  const [rulerResult, setRulerResult] = useState(0);
  const [squareResult, setSquareResult] = useState(0);
  const [tableResult, setTableResult] = useState({ lat: 0, lng: 0 });
  const tablePoint = useRef<any>();
  const measureRulerFrom = useRef<any>(undefined);
  const measureRulerTo = useRef<any>(undefined);
  const mapDraw = useRef<any>(undefined);
  const goToXY = useRef<any>(undefined);

  const { customMapServicesLayersSelected } = useContext<IMapBoxContext>(MapBoxContext);

  const [identitySelect, setIdentitySelect] = useState<
    { latitude: number; longitude: number } | undefined
  >(undefined);
  const [openDirection, setOpenDirection] = useState(false);
  const [hoverLocation, setHoverLocation] = useState({ lat: 0, lng: 0 });
  const [isHiddenTools, setIsHiddenTools] = useState(false);
  const [selectedCustomTool, setSelectedCustomTool] = useState<string>('');
  const [interactiveLayerIds, setInteractiveLayerIds] = useState<string[]>([]);
  const [currentLocation, setCurrentLocation] = useState<IPoint | undefined>(undefined);
  const [measureSelect, setMeasureSelect] = useState<'area' | 'ruler' | 'table' | undefined>(
    undefined
  );
  const [locationSelected, setLocationSelected] = useState<{
    lat: string | undefined;
    lng: string | undefined;
  }>({
    lat: undefined,
    lng: undefined,
  });
  //#region Map Event
  const [routeSource, setRouteSource] = useState<
    | {
        duration: number;
        distance: number;
        source: any;
      }
    | undefined
  >(undefined);
  useEffect(() => {
    if (!hoverInfo) {
      setRouteSource(undefined);
    }
  }, [hoverInfo]);
  const addRoute = async (start: number[], end: number[]) => {
    const origin = `${start[1]},${start[0]}`;
    const destination = `${end[1]},${end[0]}`;
    const routeResult = await apiMapbox.getRoute({ origin, destination });
    map?.setZoom(7);
    map?.setCenter([start[1], start[0]]);
    const routeData = lineString(routeResult.routes[0].geometry.coordinates);
    setRouteSource({
      duration: routeResult.routes[0].duration,
      distance: routeResult.routes[0].distance,
      source: routeData,
    });
  };
  const handleGoToLocation = (location: { lat: string; lng: string }) => {
    if (!map) return;
    if (goToXY.current) {
      goToXY.current.remove();
      goToXY.current = undefined;
    }
    const latlng: LngLatLike = [parseFloat(location.lng), parseFloat(location.lat)];
    goToXY.current = new mapboxgl.Marker().setLngLat(latlng).addTo(map);
    map.flyTo({
      center: latlng,
      zoom: 11,
    });
  };
  useEffect(() => {
    if (measureRuler.to.length && measureRuler.from.length) {
      const distance = turf.distance(measureRuler.to, measureRuler.from, { units: 'kilometers' });
      setRulerResult(distance);
    } else {
      setRulerResult(0);
    }
  }, [measureRuler]);
  const handleUpdateArea = (e: any, draw: any) => {
    const data = draw.getAll();
    if (data.features.length > 0) {
      const area = turf.area(data);
      setSquareResult(area);
    } else {
      if (e.type !== 'draw.delete') alert('Click the map to draw a polygon.');
    }
  };
  useEffect(() => {
    if (!map) return;
    if (measureSelect === 'area') {
      mapDraw.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: false,
          trash: false,
        },
        defaultMode: 'draw_polygon',
      });
      map.addControl(mapDraw.current);
      map.on('draw.create', (e) => handleUpdateArea(e, mapDraw.current));
      map.on('draw.delete', (e) => handleUpdateArea(e, mapDraw.current));
      map.on('draw.update', (e) => handleUpdateArea(e, mapDraw.current));
    } else {
      if (mapDraw.current) {
        // mapDraw.current.deleteAll();
        map.removeControl(mapDraw.current);
        mapDraw.current = undefined;
      }
    }
    if (measureSelect !== 'table') {
      if (tablePoint.current) {
        tablePoint.current.remove();
        setTableResult({ lat: 0, lng: 0 });
      }
    }
    if (measureSelect !== 'ruler') {
      setRulerResult(0);
      setSquareResult(0);
      setMeasureRuler({
        from: [],
        to: [],
      });
      try {
        map.removeLayer('route');
        map.removeSource('route');
      } catch {
        console.log('no layer delete');
      }
      if (measureRulerFrom.current) {
        measureRulerFrom.current.remove();
        measureRulerTo.current.remove();
        measureRulerFrom.current = undefined;
        measureRulerTo.current = undefined;
      }
    }
  }, [measureSelect]);
  const handleMapsEvent = (event: MapLayerMouseEvent) => {
    if (!map) return;
    const { lngLat } = event;
    if (selectedCustomTool === 'identity' && !identitySelect) {
      setIdentitySelect({
        latitude: lngLat.lat,
        longitude: lngLat.lng,
      });
    }
    if (measureSelect === 'table') {
      if (tablePoint.current) {
        tablePoint.current.remove();
      }
      tablePoint.current = new mapboxgl.Marker({
        color: 'green',
      })
        .setLngLat([lngLat.lng, lngLat.lat])
        .addTo(map);
      setTableResult({ lat: lngLat.lat, lng: lngLat.lng });
    } else if (measureSelect === 'ruler') {
      const measurerl: any = [lngLat.lng, lngLat.lat];
      if (
        (measureRuler.from.length && measureRuler.to.length) ||
        (!measureRuler.from.length && !measureRuler.to.length)
      ) {
        if (measureRulerFrom.current && measureRulerTo.current) {
          map.removeLayer('route');
          map.removeSource('route');
          measureRulerFrom.current.remove();
          measureRulerTo.current.remove();
        }
        setMeasureRuler({ from: measurerl, to: [] });
        measureRulerFrom.current = new mapboxgl.Marker({
          color: 'green',
        })
          .setLngLat(measurerl)
          .addTo(map);
      } else {
        setMeasureRuler({ ...measureRuler, to: measurerl });
        measureRulerTo.current = new mapboxgl.Marker({
          color: 'red',
        })
          .setLngLat(measurerl)
          .addTo(map);

        map.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: [measureRuler.from, measurerl],
            },
          },
        });
        map.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#1aa648',
            'line-width': 8,
          },
        });
      }
    }
  };
  const onMapClick = useCallback((event: MapLayerMouseEvent) => {
    const { features, lngLat } = event;
    const featureClicked = features && features[0];

    if (featureClicked && lngLat.lng && lngLat.lat) {
      const hoverInfo: IHoverInfo = {
        lng: lngLat.lng,
        lat: lngLat.lat,
        property: featureClicked?.properties,
      };
      setHoverInfo(hoverInfo);
      setShouldShowPopup(true);
    } else {
      setHoverInfo(undefined);
      setShouldShowPopup(false);
    }
  }, []);

  //#endregion Map Events

  //#region Draw
  const [features, setFeatures] = useState({});

  const onUpdate = useCallback((e: { features: { id: any }[] }) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures } as any;
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e: { features: { id: any }[] }) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures } as any;
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);
  //#endregion

  useEffect(() => {
    if (!map) return;
    setMeasureSelect(undefined);
    setIdentitySelect(undefined);
    setLocationSelected({ lat: undefined, lng: undefined });
    if (goToXY.current) {
      goToXY.current.remove();
      goToXY.current = undefined;
    }
    if (map.getCanvas()) {
      if (selectedCustomTool === 'pan') {
        map.getCanvas().style.cursor = 'grab';
      } else {
        map.getCanvas().style.cursor = 'grab';
      }
    }
  }, [selectedCustomTool]);

  useEffect(() => {
    if (!map) return;
    const searchLayers = layerStyles
      .filter((style) => style.id !== undefined)
      .map((style) => style.id || '');
    const availableLayers = searchLayers.filter((layerId) => map.getLayer(layerId));
    setInteractiveLayerIds(availableLayers);
  }, [searchResults]);

  // load current location
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setCurrentLocation({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      });
    });
  }, []);

  const directionToDestination = (destination: IPoint) => {
    const url = `https://www.google.com/maps/dir/${currentLocation?.lat?.toString()},${currentLocation?.lng?.toString()}/${destination?.lat?.toString()},${destination?.lng?.toString()}`;
    window.open(url, '_blank', 'noopener');
  };
  const [startPoint, setStartPoint] = useState<any>();
  const [destination, setDestination] = useState<any>();
  const [startSuggestions, setStartSuggestions] = useState<ISuggestion[]>([]);
  const [destinationSuggestions, setDestinationSuggestions] = useState<ISuggestion[]>([]);
  const fetchSuggestions = async (inputValue: any, setSuggestionsFunc: any) => {
    if (!inputValue) {
      setSuggestionsFunc([]);
      return;
    }
    const response = await apiMapbox.getPlaceSuggestion({ query: inputValue });
    setSuggestionsFunc(response.features);
  };
  const handleSuggestionClick = (
    suggestion: ISuggestion,
    setSuggestionsFunc: any,
    type: string
  ) => {
    if (type === 'start') {
      if (destination) {
        addRoute(
          [suggestion.geometry.coordinates[1], suggestion.geometry.coordinates[0]],
          [destination.geometry.coordinates[1], destination.geometry.coordinates[0]]
        );
      }
    } else {
      if (startPoint) {
        addRoute(
          [startPoint.geometry.coordinates[1], startPoint.geometry.coordinates[0]],
          [suggestion.geometry.coordinates[1], suggestion.geometry.coordinates[0]]
        );
      }
    }
    setSuggestionsFunc(suggestion); // or setDestination for the other input
    // Store the coordinates of the suggestion if needed: suggestion.geometry.coordinates
    setStartSuggestions([]);
    setDestinationSuggestions([]);
  };
  const handleRenderDirectionSelector = () => {
    if ((routeSource && userInformation?.data?.role === 1) || openDirection) {
      return (
        <div>
          <DirectionsControlContainer>
            <div>
              <DirectionLabel>เรื่มจาก</DirectionLabel>
              <DirectionInput
                placeholder="Choose a starting place"
                value={startPoint?.place_name}
                onChange={(e) => {
                  setDestinationSuggestions([]);
                  setStartPoint(e.target.value);
                  fetchSuggestions(e.target.value, setStartSuggestions);
                }}
              />
              {startSuggestions.length > 0 && (
                <SuggestionDropdown>
                  {startSuggestions.map((suggestion) => (
                    <SuggestionItem
                      key={suggestion.id}
                      onClick={() => handleSuggestionClick(suggestion, setStartPoint, 'start')}
                    >
                      {suggestion?.place_name}
                    </SuggestionItem>
                  ))}
                </SuggestionDropdown>
              )}
            </div>
            <div>
              <DirectionLabel>ถึง</DirectionLabel>
              <DirectionInput
                placeholder="Choose a destination"
                value={destination?.place_name}
                onChange={(e) => {
                  setStartSuggestions([]);
                  setDestination(e.target.value);
                  fetchSuggestions(e.target.value, setDestinationSuggestions);
                }}
              />
              {destinationSuggestions.length > 0 && (
                <SuggestionDropdown>
                  {destinationSuggestions.map((suggestion) => (
                    <SuggestionItem
                      key={suggestion.id}
                      onClick={() =>
                        handleSuggestionClick(suggestion, setDestination, 'destination')
                      }
                    >
                      {suggestion?.place_name}
                    </SuggestionItem>
                  ))}
                </SuggestionDropdown>
              )}
            </div>
            <div>
              ระยะเวลา : {`${routeSource ? secondsToHoursMinutes(routeSource.duration) : 0}`}
            </div>
            <div>
              ระยะทาง :
              {`${routeSource ? metersToKilometers(routeSource.distance).toFixed(2) : 0} KM`}
            </div>
            <DirectionButton
              onClick={() => {
                setRouteSource(undefined);
                setStartPoint(undefined);
                setDestination(undefined);
                setOpenDirection(false);
              }}
            >
              ยกเลิกการแสดงเส้นทาง
            </DirectionButton>
          </DirectionsControlContainer>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const [activeMap, setActiveMap] = useState<'left' | 'right'>('left');
  const onLeftMoveStart = useCallback(() => setActiveMap('left'), []);
  const onRightMoveStart = useCallback(() => setActiveMap('right'), []);
  const onMove = useCallback((evt: any) => setViewState(evt.viewState), []);
  const width = useMemo(() => {
    if (typeof window === 'undefined') {
      return 100;
    } else {
      if (hiddenMainSearch) {
        return window.innerWidth;
      } else {
        return window.innerWidth - screen.width * 0.25;
      }
    }
  }, [hiddenMainSearch]);

  const leftMapPadding = useMemo(() => {
    return {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    };
  }, [width, openCompareMap, hiddenMainSearch]);
  const rightMapPadding = useMemo(() => {
    return { right: openCompareMap ? width / 2 : 0, top: 0, left: 0, bottom: 0 };
  }, [width, openCompareMap, hiddenMainSearch]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div
        style={{
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
        }}
      >
        <ReactMapGL
          {...viewState}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
          padding={leftMapPadding}
          onMoveStart={onLeftMoveStart}
          onMove={(evt) => activeMap === 'left' && onMove(evt)}
          // initialViewState={defaultLatlng}
          maxPitch={75}
          mapStyle="mapbox://styles/mapbox/satellite-v9"
          // terrain={{ source: 'mapbox-dem', exaggeration: 1.5 }}
          onMouseMove={(e) => {
            setHoverLocation({ lat: e.lngLat.lat, lng: e.lngLat.lng });
          }}
          onLoad={({ target }: MapboxEvent) => {
            setMap(target);
          }}
          onZoom={() => {
            if (!map) return;
          }}
          onRotate={() => {
            if (!map) return;
          }}
          interactiveLayerIds={interactiveLayerIds}
          onClick={(event) => {
            onMapClick(event);
            handleMapsEvent(event);
          }}
        >
          {!openCompareMap && (
            <>
              {handleRenderDirectionSelector()}
              {routeSource && (
                <Source type="geojson" data={routeSource.source}>
                  <Layer
                    id="route-layer"
                    type="line"
                    source="route"
                    layout={{
                      'line-join': 'round',
                      'line-cap': 'round',
                    }}
                    paint={{
                      'line-color': '#FF0000',
                      'line-width': 2,
                    }}
                  />
                </Source>
              )}
              {/* <GeocoderControl
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY as string}
        position="top-left"
      /> */}
              <HiddenCustomControl
                map={map}
                setIsHiddenTools={setIsHiddenTools}
                isHiddenTools={isHiddenTools}
              />
              {!isHiddenTools && (
                <>
                  <NavigationControl position="top-right" style={{ marginTop: '60px' }} />

                  {/* <FullscreenControl position="top-right" /> */}
                  <PrintControl
                    position="top-right"
                    setSelectedCustomTool={setSelectedCustomTool}
                  />
                  {/* <DrawControl
            position="top-right"
            displayControlsDefault={false}
            controls={{
              point: true,
              line_string: true,
              polygon: true,
              trash: true,
            }}
            // defaultMode="draw_polygon"
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          /> */}
                  <GeolocateControl position="top-right" />
                  {map && (
                    <>
                      {!openDirection && userInformation?.data?.role === 1 && (
                        <DirectionToggleCustomControl
                          map={map1}
                          setOpenDirection={setOpenDirection}
                        />
                      )}
                      <LatLngHover map={map} location={hoverLocation} />
                      <Information map={map} />
                      <HomeCustomControl
                        map={map}
                        selectedCustomTool={selectedCustomTool}
                        setSelectedCustomTool={setSelectedCustomTool}
                      />
                      <MeasurementCustomControl
                        tableResult={tableResult}
                        squareResult={squareResult}
                        rulerResult={rulerResult}
                        setMeasureSelect={setMeasureSelect}
                        measureSelect={measureSelect}
                        map={map}
                        selectedCustomTool={selectedCustomTool}
                        setSelectedCustomTool={setSelectedCustomTool}
                      />
                      <AddMapServiceCustomControl
                        map={map}
                        selectedCustomTool={selectedCustomTool}
                        setSelectedCustomTool={setSelectedCustomTool}
                      />

                      <GoToXYCustomControl
                        handleGoToLocation={handleGoToLocation}
                        locationSelected={locationSelected}
                        setLocationSelected={setLocationSelected}
                        map={map}
                        selectedCustomTool={selectedCustomTool}
                        setSelectedCustomTool={setSelectedCustomTool}
                      />
                      {/* <MapLayerCustomControl
            map={map}
            selectedCustomTool={selectedCustomTool}
            setSelectedCustomTool={setSelectedCustomTool}
          /> */}
                      <PanCustomControl
                        map={map}
                        selectedCustomTool={selectedCustomTool}
                        setSelectedCustomTool={setSelectedCustomTool}
                      />
                      <Toggle3DCustomControl map={map} />
                      {/* <IdentityCustomControl
                map={map}
                selectedCustomTool={selectedCustomTool}
                setSelectedCustomTool={setSelectedCustomTool}
                identitySelect={identitySelect}
              /> */}
                    </>
                  )}
                  <ScaleControl position="bottom-right" />
                  <PanelControl polygons={Object.values(features)} />
                </>
              )}

              {/* Fix source for terrain 3D */}
              <Source
                id="mapbox-dem"
                url="mapbox://mapbox.mapbox-terrain-dem-v1"
                type="raster-dem"
                tileSize={512}
                maxzoom={14}
              />
              {searchResults?.length && (
                <Source
                  id="search_result"
                  type="geojson"
                  data={{
                    type: 'FeatureCollection',
                    features: searchResults
                      ?.filter((item) => item.geojson_feature.geometry.type !== null)
                      .map((item): GeoJSON.Feature => item.geojson_feature),
                  }}
                >
                  {layerStyles &&
                    layerStyles.map((layerStyle) => <Layer key={layerStyle.id} {...layerStyle} />)}
                </Source>
              )}
              {layerSelected &&
                layerSelected.map((layer) => {
                  if (
                    layer.layer_service_type === LAYER_SERVICE_TYPE.WMS ||
                    layer.layer_service_type === LAYER_SERVICE_TYPE.WMTS
                  ) {
                    // console.log(`add WMS master_layer_${layer.layer_id}`);
                    return (
                      <Source
                        key={`master_layer_${layer.layer_id}`}
                        id={`wms_${layer.layer_id}`}
                        type="raster"
                        tiles={[
                          layer.layer_url,
                          // 'https://img.nj.gov/imagerywms/Natural2015?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Natural2015',
                        ]}
                        tileSize={256}
                      >
                        <Layer
                          key={`master_layer_${layer.layer_id}`}
                          type="raster"
                          source={`wms_${layer.layer_id}`}
                          paint={{ 'raster-opacity': layer.opacity / 100 }}
                        />
                      </Source>
                    );
                  } else if (layer.layer_service_type === LAYER_SERVICE_TYPE.WFS) {
                    // console.log(`add WFS master_layer_${layer.layer_id}`);
                    const layerStyleWFS: AnyLayer = {
                      id: `${layer.layer_id}`,
                      type: 'fill',
                      source: 'search_result',
                      paint: {
                        'fill-color': '#cccccc',
                        'fill-opacity': 0.5,
                      },
                      filter: ['==', '$type', 'Polygon'],
                    };
                    return (
                      <Source
                        key={`master_layer_${layer.layer_id}`}
                        id={`wfs_${layer.layer_id}`}
                        type="geojson"
                        data={layer.layer_url}
                      >
                        {layerStyleWFS && <Layer key={`${layer.layer_id}`} {...layerStyleWFS} />}
                      </Source>
                    );
                  }
                })}
              {customMapServicesLayersSelected &&
                customMapServicesLayersSelected.map((layer) => {
                  // console.log(`add ${customMapServiceLayerKey} to map`);
                  return (
                    <Source
                      key={`custom_map_service_${layer.id}`}
                      id={`wms_custom_map_service_${layer.id}`}
                      type="raster"
                      tiles={[layer.layer_url]}
                      tileSize={256}
                    >
                      <Layer
                        key={`layer_custom_map_service_${layer.id}`}
                        type="raster"
                        source={`wms_custom_map_service_${layer.id}`}
                        paint={{ 'raster-opacity': layer.opacity / 100 }}
                      />
                    </Source>
                  );
                })}
              {shouldShowPopup && hoverInfo && hoverInfo?.lat && hoverInfo?.lng && (
                <Popup
                  key={hoverInfo.lat + hoverInfo.lng}
                  longitude={hoverInfo?.lng}
                  latitude={hoverInfo?.lat}
                  offset={[0, -10]}
                  closeButton={true}
                  className="custom-popup"
                  onClose={() => {
                    setRouteSource(undefined);
                    setShouldShowPopup(false);
                  }}
                >
                  {hoverInfo?.property &&
                    Object.keys(hoverInfo?.property)
                      .filter((key) => !['id', 'lat', 'long'].includes(key))
                      .map((key, index) => {
                        return (
                          <SPopupContentRow key={`popup_row_${index}`}>
                            <SPopupContentLabel>{key}</SPopupContentLabel>{' '}
                            {hoverInfo && hoverInfo?.property && hoverInfo?.property[key]}
                          </SPopupContentRow>
                        );
                      })}
                  {userInformation?.data?.role === 1 && (
                    <SPopupContentGoogleMapDirection
                      onClick={() => {
                        const originLocation: number[] =
                          currentLocation?.lat && currentLocation.lng
                            ? [currentLocation.lat, currentLocation.lng]
                            : [defaultLatlng.latitude, defaultLatlng.longitude];
                        hoverInfo?.lat &&
                          hoverInfo?.lng &&
                          addRoute(originLocation, [hoverInfo.lat, hoverInfo.lng]);
                        setStartPoint({
                          place_name: `ที่อยู่ปัจจุบัน`,
                          geometry: { coordinates: [originLocation[1], originLocation[0]] },
                        });
                        setDestination({
                          place_name: `${hoverInfo?.property?.['ชื่อป่าสงวน']} ${hoverInfo?.property?.['จังหวัด']} ${hoverInfo?.property?.['อำเภอ']} ${hoverInfo?.property?.['ตำบล']}`,
                          geometry: { coordinates: [hoverInfo.lng, hoverInfo.lat] },
                        });
                      }}
                    >
                      แสดงเส้นทางบนแผนที่
                    </SPopupContentGoogleMapDirection>
                  )}
                  <SPopupContentGoogleMapDirection
                    key={`popup_row_999`}
                    onClick={() => {
                      hoverInfo?.lat &&
                        hoverInfo?.lng &&
                        directionToDestination({ lat: hoverInfo.lat, lng: hoverInfo.lng });
                    }}
                  >
                    นำทางด้วย google map
                  </SPopupContentGoogleMapDirection>
                </Popup>
              )}
            </>
          )}
          <StyleSwitchControl hiddenMainSearch={hiddenMainSearch} />
        </ReactMapGL>
      </div>
      {openCompareMap && (
        <div
          style={{
            width: '50%',
            position: 'absolute',
            top: 0,
            left: '50%',
            height: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 20px',
            zIndex: 3,
          }}
        >
          <ReactMapGL
            {...viewState}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
            padding={rightMapPadding}
            onMoveStart={onRightMoveStart}
            onMove={(evt) => activeMap === 'right' && onMove(evt)}
            // initialViewState={defaultLatlng}
            maxPitch={75}
            mapStyle="mapbox://styles/mapbox/satellite-v9"
            // terrain={{ source: 'mapbox-dem', exaggeration: 1.5 }}
            onMouseMove={(e) => {
              setHoverLocation({ lat: e.lngLat.lat, lng: e.lngLat.lng });
            }}
            onLoad={({ target }: MapboxEvent) => {
              setMap1(target);
            }}
            onZoom={() => {
              if (!map1) return;
            }}
            onRotate={() => {
              if (!map1) return;
            }}
            interactiveLayerIds={interactiveLayerIds}
            onClick={(event) => {
              onMapClick(event);
              handleMapsEvent(event);
            }}
          >
            {handleRenderDirectionSelector()}
            {routeSource && (
              <Source type="geojson" data={routeSource.source}>
                <Layer
                  id="route-layer"
                  type="line"
                  source="route"
                  layout={{
                    'line-join': 'round',
                    'line-cap': 'round',
                  }}
                  paint={{
                    'line-color': '#FF0000',
                    'line-width': 2,
                  }}
                />
              </Source>
            )}
            {/* <GeocoderControl
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY as string}
        position="top-left"
      /> */}
            <HiddenCustomControl
              map={map1}
              setIsHiddenTools={setIsHiddenTools}
              isHiddenTools={isHiddenTools}
            />
            {!isHiddenTools && (
              <>
                <NavigationControl position="top-right" style={{ marginTop: '60px' }} />

                {/* <FullscreenControl position="top-right" /> */}
                <PrintControl position="top-right" setSelectedCustomTool={setSelectedCustomTool} />
                {/* <DrawControl
            position="top-right"
            displayControlsDefault={false}
            controls={{
              point: true,
              line_string: true,
              polygon: true,
              trash: true,
            }}
            // defaultMode="draw_polygon"
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          /> */}
                <GeolocateControl position="top-right" />
                {map1 && (
                  <>
                    <LatLngHover map={map1} location={hoverLocation} />
                    <Information map={map1} />
                    <HomeCustomControl
                      map={map1}
                      selectedCustomTool={selectedCustomTool}
                      setSelectedCustomTool={setSelectedCustomTool}
                    />
                    <MeasurementCustomControl
                      tableResult={tableResult}
                      squareResult={squareResult}
                      rulerResult={rulerResult}
                      setMeasureSelect={setMeasureSelect}
                      measureSelect={measureSelect}
                      map={map1}
                      selectedCustomTool={selectedCustomTool}
                      setSelectedCustomTool={setSelectedCustomTool}
                    />
                    <AddMapServiceCustomControl
                      map={map1}
                      selectedCustomTool={selectedCustomTool}
                      setSelectedCustomTool={setSelectedCustomTool}
                    />

                    <GoToXYCustomControl
                      handleGoToLocation={handleGoToLocation}
                      locationSelected={locationSelected}
                      setLocationSelected={setLocationSelected}
                      map={map1}
                      selectedCustomTool={selectedCustomTool}
                      setSelectedCustomTool={setSelectedCustomTool}
                    />
                    {/* <MapLayerCustomControl
            map={map}
            selectedCustomTool={selectedCustomTool}
            setSelectedCustomTool={setSelectedCustomTool}
          /> */}
                    <PanCustomControl
                      map={map1}
                      selectedCustomTool={selectedCustomTool}
                      setSelectedCustomTool={setSelectedCustomTool}
                    />
                    <Toggle3DCustomControl map={map1} />
                    {/* <IdentityCustomControl
                map={map}
                selectedCustomTool={selectedCustomTool}
                setSelectedCustomTool={setSelectedCustomTool}
                identitySelect={identitySelect}
              /> */}
                  </>
                )}
                <ScaleControl position="bottom-right" />
                <PanelControl polygons={Object.values(features)} />
              </>
            )}
            <StyleSwitchControl hiddenMainSearch={hiddenMainSearch} />

            {/* Fix source for terrain 3D */}
            <Source
              id="mapbox-dem"
              url="mapbox://mapbox.mapbox-terrain-dem-v1"
              type="raster-dem"
              tileSize={512}
              maxzoom={14}
            />
            {searchResults?.length && (
              <Source
                id="search_result"
                type="geojson"
                data={{
                  type: 'FeatureCollection',
                  features: searchResults
                    ?.filter((item) => item.geojson_feature.geometry.type !== null)
                    .map((item): GeoJSON.Feature => item.geojson_feature),
                }}
              >
                {layerStyles &&
                  layerStyles.map((layerStyle) => <Layer key={layerStyle.id} {...layerStyle} />)}
              </Source>
            )}

            {layerSelected &&
              layerSelected.map((layer) => {
                if (
                  layer.layer_service_type === LAYER_SERVICE_TYPE.WMS ||
                  layer.layer_service_type === LAYER_SERVICE_TYPE.WMTS
                ) {
                  // console.log(`add WMS master_layer_${layer.layer_id}`);
                  return (
                    <Source
                      key={`master_layer_${layer.layer_id}`}
                      id={`wms_${layer.layer_id}`}
                      type="raster"
                      tiles={[
                        layer.layer_url,
                        // 'https://img.nj.gov/imagerywms/Natural2015?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Natural2015',
                      ]}
                      tileSize={256}
                    >
                      <Layer
                        key={`master_layer_${layer.layer_id}`}
                        type="raster"
                        source={`wms_${layer.layer_id}`}
                        paint={{ 'raster-opacity': layer.opacity / 100 }}
                      />
                    </Source>
                  );
                } else if (layer.layer_service_type === LAYER_SERVICE_TYPE.WFS) {
                  // console.log(`add WFS master_layer_${layer.layer_id}`);
                  const layerStyleWFS: AnyLayer = {
                    id: `${layer.layer_id}`,
                    type: 'fill',
                    source: 'search_result',
                    paint: {
                      'fill-color': '#cccccc',
                      'fill-opacity': 0.5,
                    },
                    filter: ['==', '$type', 'Polygon'],
                  };
                  return (
                    <Source
                      key={`master_layer_${layer.layer_id}`}
                      id={`wfs_${layer.layer_id}`}
                      type="geojson"
                      data={layer.layer_url}
                    >
                      {layerStyleWFS && <Layer key={`${layer.layer_id}`} {...layerStyleWFS} />}
                    </Source>
                  );
                }
              })}

            {customMapServicesLayersSelected &&
              customMapServicesLayersSelected.map((layer) => {
                // console.log(`add ${customMapServiceLayerKey} to map`);
                return (
                  <Source
                    key={`custom_map_service_${layer.id}`}
                    id={`wms_custom_map_service_${layer.id}`}
                    type="raster"
                    tiles={[layer.layer_url]}
                    tileSize={256}
                  >
                    <Layer
                      key={`layer_custom_map_service_${layer.id}`}
                      type="raster"
                      source={`wms_custom_map_service_${layer.id}`}
                      paint={{ 'raster-opacity': layer.opacity / 100 }}
                    />
                  </Source>
                );
              })}

            {shouldShowPopup && hoverInfo && hoverInfo?.lat && hoverInfo?.lng && (
              <Popup
                key={hoverInfo.lat + hoverInfo.lng}
                longitude={hoverInfo?.lng}
                latitude={hoverInfo?.lat}
                offset={[0, -10]}
                closeButton={true}
                className="custom-popup"
                onClose={() => {
                  setRouteSource(undefined);
                  setShouldShowPopup(false);
                }}
              >
                {hoverInfo?.property &&
                  Object.keys(hoverInfo?.property)
                    .filter((key) => !['id', 'lat', 'long'].includes(key))
                    .map((key, index) => {
                      return (
                        <SPopupContentRow key={`popup_row_${index}`}>
                          <SPopupContentLabel>{key}</SPopupContentLabel>{' '}
                          {hoverInfo && hoverInfo?.property && hoverInfo?.property[key]}
                        </SPopupContentRow>
                      );
                    })}
                {userInformation?.data?.role === 1 && (
                  <SPopupContentGoogleMapDirection
                    onClick={() => {
                      const originLocation: number[] =
                        currentLocation?.lat && currentLocation.lng
                          ? [currentLocation.lat, currentLocation.lng]
                          : [defaultLatlng.latitude, defaultLatlng.longitude];
                      hoverInfo?.lat &&
                        hoverInfo?.lng &&
                        addRoute(originLocation, [hoverInfo.lat, hoverInfo.lng]);
                      setStartPoint({
                        place_name: `ที่อยู่ปัจจุบัน`,
                        geometry: { coordinates: [originLocation[1], originLocation[0]] },
                      });
                      setDestination({
                        place_name: `${hoverInfo?.property?.['ชื่อป่าสงวน']} ${hoverInfo?.property?.['จังหวัด']} ${hoverInfo?.property?.['อำเภอ']} ${hoverInfo?.property?.['ตำบล']}`,
                        geometry: { coordinates: [hoverInfo.lng, hoverInfo.lat] },
                      });
                    }}
                  >
                    แสดงเส้นทางบนแผนที่
                  </SPopupContentGoogleMapDirection>
                )}
                <SPopupContentGoogleMapDirection
                  key={`popup_row_999`}
                  onClick={() => {
                    hoverInfo?.lat &&
                      hoverInfo?.lng &&
                      directionToDestination({ lat: hoverInfo.lat, lng: hoverInfo.lng });
                  }}
                >
                  นำทางด้วย google map
                </SPopupContentGoogleMapDirection>
              </Popup>
            )}
          </ReactMapGL>
        </div>
      )}
    </div>
  );
};

export default MapBox;

const SPopupContentRow = styled.div`
  font-size: 11pt;
  line-height: 20pt;
`;

const SPopupContentLabel = styled.div`
  float: left;
  width: 80px;
  font-weight: bold;
`;

const SPopupContentGoogleMapDirection = styled.div`
  font-size: 11pt;
  line-height: 20pt;
  padding-top: 5px;
  cursor: pointer;
  &:hover {
    color: blue;
    text-decoration-line: underline;
  }
`;
const DirectionsControlContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 5px;
  left: 5px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  /* width: 400px; */
  & > div {
    display: flex;
    align-items: center;
  }
`;

const DirectionLabel = styled.div`
  font-weight: bold;
  color: #444;
  font-size: 14px;
  margin-bottom: 8px;
  width: 150px;
`;

const DirectionInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 15px;
  outline: none;
`;

const DirectionButton = styled.button`
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  background-color: #f1f1f1;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-right: 5px;
  outline: none;

  &:hover {
    background-color: #ddd;
  }
`;
const SuggestionDropdown = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 120px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 150px;
  overflow-y: auto;
`;

const SuggestionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  font-size: 14px;

  &:hover {
    background-color: #f7f7f7;
  }

  &:last-child {
    border-bottom: none;
  }
`;
