import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import Keycloak, { KeycloakConfig } from 'keycloak-js';
import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import apiLogin from './apis/login';
import MainPageContextProvider from './contexts/MainPageContext';
import MasterDataContextProvider from './contexts/MasterDataContext';
import UserContextProvider from './contexts/UserContext';
import AdminPage from './pages/Admin.page';
import MainPage from './pages/Main.page';

const keycloakConfig: KeycloakConfig = {
  url: process.env.REACT_APP_KEY_CLOAK_BASE_URL || `https://auth.forest.go.th/`,
  realm: process.env.REACT_APP_KEY_CLOAK_REALM || `rfd`,
  clientId: process.env.REACT_APP_KEY_CLOAK_CLIENT_ID || `forest-map`,
};
const authInstance = new Keycloak(keycloakConfig);
authInstance
  .init({
    onLoad: 'check-sso',
    checkLoginIframe: false,
    pkceMethod: 'S256',
    enableLogging: true,
    logoutMethod: 'POST',
    // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    // redirectUri: window.location.origin + '/keycloak/login/success',
  })
  .then(
    (auth) => {
      console.log(`this is auth`, auth);
      if (auth) {
        console.info('Authenticated');
        console.log('auth', auth);
        console.log('Keycloak', authInstance);
        authInstance.onTokenExpired = () => {
          console.log('token expired');
        };

        apiLogin
          .loginWithKeycloak({
            username: authInstance.tokenParsed?.preferred_username,
            password: authInstance.token as string,
          })
          .then(
            (response) => {
              console.log(response);
              localStorage.setItem('userLogin', JSON.stringify(response));
              if (window.location.pathname !== '/emp') window.location.reload();
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },
    () => {
      console.error('Authenticated Failed');
    }
  );

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainPage />,
  },
  {
    path: '/emp',
    element: <MainPage />,
  },
  {
    path: '/admin',
    element: <AdminPage />,
  },
]);
const App: React.FC = () => {
  return (
    <>
      <UserContextProvider keycloakInstance={authInstance}>
        <MasterDataContextProvider>
          <MainPageContextProvider>
            <ConfigProvider
              theme={{
                // algorithm: theme.compactAlgorithm,
                token: {
                  colorBgContainer: '#FFF',
                  colorBgSpotlight: '#336633',
                },
              }}
            >
              <RouterProvider router={router} />
            </ConfigProvider>
          </MainPageContextProvider>
        </MasterDataContextProvider>
      </UserContextProvider>
    </>
  );
};

export default App;
