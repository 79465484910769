import { Button, Form, Input, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { users } from '../../mocks/user.mock';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons';
interface DataType {
  no: number;
  name: string;
  information: string;
  status: string;
}
enum UserStatus {
  admin = 'ผู้ดูแลระบบ',
  user = 'ผู้ใช้งาน',
  emp = 'พนักงาน',
}
const UsersController = () => {
  const [userData, setUserData] = useState(users);
  const [open, setOpen] = useState(false);
  const [isCreate, setIsCreate] = useState<boolean>(true);
  const columns: ColumnsType<DataType> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: 'ชื่อกลุ่มผู้ใช้งาน',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'information',
      key: 'information',
    },
    {
      title: 'สถานะการใช้งาน',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'แก้ไข',
      dataIndex: 'edit',
      key: 'edit',
      render: () => {
        return (
          <div>
            <EditOutlined />
          </div>
        );
      },
    },
    {
      title: 'ลบ',
      dataIndex: 'delete',
      key: 'delete',
      render: () => {
        return (
          <div>
            <DeleteOutlined />
          </div>
        );
      },
    },
  ];
  const [form] = Form.useForm();

  const onSearch = () => {
    const filterUsername = form.getFieldValue('name');
    if (!filterUsername) return;
    const filterUsers = users.data?.filter((account) => {
      if (filterUsername) {
        return account.full_name.indexOf(filterUsername) > -1;
      } else {
        return [...users.data];
      }
    });
    setUserData({ data: filterUsers });
  };
  const onReset = () => {
    form.resetFields();
    setUserData(users);
  };
  return (
    <SContainer>
      <h2>ค้นหา</h2>
      <div>
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 8 }} form={form} layout="horizontal">
          <Form.Item name="name" label="ชื่อกลุ่มผู้ใช้งาน">
            <Input
              allowClear
              onChange={(e) => {
                form.setFieldValue('username', e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
            <Button
              type="primary"
              style={{ marginRight: '0.75rem', background: '#336633' }}
              onClick={onSearch}
            >
              ค้นหา
            </Button>
            <Button htmlType="button" onClick={onReset}>
              ยกเลิก
            </Button>
          </Form.Item>
        </Form>
      </div>
      <hr style={{ border: '1px solid #ddd' }} />
      <div>
        <Button
          type="dashed"
          style={{ marginBottom: '1rem', marginTop: '0.5rem' }}
          onClick={() => {
            setIsCreate(true);
            setOpen(true);
          }}
        >
          <UserAddOutlined /> เพิ่มข้อมูล
        </Button>
        <Table
          columns={columns}
          dataSource={userData.data.map((e, i) => {
            return {
              no: i + 1,
              name: e.full_name,
              information: e.information,
              status: UserStatus[e.status],
            };
          })}
        />
      </div>
    </SContainer>
  );
};
export default UsersController;
const SContainer = styled.div`
  width: 100%;
`;
