import { Button } from 'antd';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ListAdminMenu } from '../../configs/list_admin_menu';
import { IAdminMenu } from '../../interfaces/admin_menu.interface';
import { UserContext } from '../../contexts/UserContext';
interface IAdminMenuComponent {
  selectedMenu: IAdminMenu | undefined;
  setSelectedMenu: React.Dispatch<React.SetStateAction<IAdminMenu | undefined>>;
}
const AdminMenu = ({ selectedMenu, setSelectedMenu }: IAdminMenuComponent) => {
  const { handleLoginGuest } = useContext(UserContext);

  return (
    <SContainer>
      <h2>เมนู </h2>
      {ListAdminMenu.map((e, i) => {
        return (
          <div key={i}>
            <SMenu
              className={selectedMenu?.text === e?.text ? 'selected' : ''}
              onClick={() => e.clickable && setSelectedMenu(e)}
            >
              {e.icon}
              {e.text}
            </SMenu>
            {e.subMenu &&
              e.subMenu.map((el, index) => {
                return (
                  <SSubMenu
                    className={selectedMenu?.text === el?.text ? 'selected' : ''}
                    key={index}
                    onClick={() => el.clickable && setSelectedMenu(el)}
                  >
                    {el.text}
                  </SSubMenu>
                );
              })}
          </div>
        );
      })}
      <Button
        type="primary"
        style={{ background: 'red', marginLeft: '5px', marginTop: '20px' }}
        onClick={() => handleLoginGuest()}
      >
        Logout
      </Button>
    </SContainer>
  );
};
export default AdminMenu;
const SContainer = styled.div`
  /* width: 25%; */
  /* border-right: solid 1px black; */
`;
const SMenu = styled.div`
  margin-left: 5px;
  cursor: pointer;
  margin-top: 10px;
  &.selected {
    font-weight: bold;
  }
`;
const SSubMenu = styled.div`
  margin-left: 45px;
  cursor: pointer;
  margin-top: 10px;
  &.selected {
    font-weight: bold;
  }
`;
