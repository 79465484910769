import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  UserAddOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import apiAdmin from '../../apis/admin';
import { IBaseMap } from '../../interfaces/base_map.interface';
import { userRoleIdToRoleEnum } from '../../interfaces/admin_menu.interface';
import { baseMapType } from '../../configs/list_admin_menu';

const { confirm, info, error } = Modal;
// const showConfirmDelete = (record: any) => {
//   confirm({
//     title: 'ข้อความจากระบบ',
//     icon: <ExclamationCircleFilled />,
//     content: 'ยืนยันการลบข้อมูล',
//     okText: 'ตกลง',
//     cancelText: 'ยกเลิก',
//     onOk() {
//       console.log('OK');
//       info({ title: 'ข้อความจากระบบ', content: 'ลบข้อมูลเรียบร้อย' });
//     },
//     onCancel() {
//       console.log('Cancel');
//     },
//   });
// };
const DEFAULT_ROLE_OPTION = [
  { value: 0, label: 'Guest' },
  { value: 1, label: 'User' },
  { value: 999, label: 'Admin' },
];
const { Option } = Select;
const LayerManagement = () => {
  const [isUserAvailable, setIsUserAvailable] = useState(false);
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [mapDataDisplay, setMapDataDisplay] = useState<IBaseMap[]>([]);
  const [defaultMapDataDisplay, setDefaultMapDataDisplay] = useState<IBaseMap[]>([]);
  const handleFetchBaseMap = async () => {
    const basemapResult = await apiAdmin.fetchBaseMapList();
    setMapDataDisplay(basemapResult);
    setDefaultMapDataDisplay(basemapResult);
  };
  const [editBaseMap, setEditBaseMap] = useState<any>();
  useEffect(() => {
    handleFetchBaseMap();
  }, []);
  const handleDelete = async (record: any) => {
    try {
      await apiAdmin.removeBaseMap(record.code);
      info({ title: 'ข้อความจากระบบ', content: 'ลบข้อมูลเรียบร้อย' });
      await handleFetchBaseMap();
    } catch (err: any) {
      error({
        title: 'ข้อความจากระบบ',
        content: `ไม่สามารถดำเนินงานได้(${err.response.data.message})`,
      });
    }
  };
  const showConfirmDelete = (record: any) => {
    confirm({
      title: 'ข้อความจากระบบ',
      icon: <ExclamationCircleFilled />,
      content: 'ยืนยันการลบข้อมูล',
      okText: 'ตกลง',
      cancelText: 'ยกเลิก',
      onOk() {
        handleDelete(record);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const columns: ColumnsType<any> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      sorter: (a: any, b: any) => a.no - b.no,
    },
    {
      title: 'ชื่อแผนที่ฐาน',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ชื่อผู้นำเข้า',
      dataIndex: 'importName',
      key: 'importName',
    },
    {
      title: 'วันที่นำเข้า',
      dataIndex: 'importDate',
      key: 'importDate',
    },
    {
      title: 'ลบ',
      dataIndex: 'delete',
      key: 'delete',
      onCell: (record) => {
        return {
          onClick: () => {
            showConfirmDelete(record);
          },
        };
      },
      render: () => {
        return <DeleteOutlined style={{ cursor: 'pointer' }} />;
      },
    },
    {
      title: 'แก้ไข',
      dataIndex: 'edit',
      key: 'edit',
      onCell: (record) => {
        return {
          onClick: () => {
            const editbasemap = {
              ...record,
              is_active: record.status === 'เปิดใช้งาน' ? true : false,
            };
            setIsUserAvailable(editbasemap.is_active);
            setEditBaseMap(editbasemap);
            setIsCreate(false);
            setOpen(true);
          },
        };
      },
      render: () => {
        return (
          <div>
            <EditOutlined />
          </div>
        );
      },
    },
    {
      title: 'เปิดใช้งาน',
      dataIndex: 'status',
      key: 'status',
    },
  ];
  const onSearch = () => {
    const filterMapName = form.getFieldValue('name');
    if (!filterMapName) {
      setMapDataDisplay([...defaultMapDataDisplay]);
    }
    const filterMap = defaultMapDataDisplay?.filter((account) => {
      if (filterMapName) {
        return account.name.indexOf(filterMapName) > -1;
      } else {
        return [...defaultMapDataDisplay];
      }
    });
    setMapDataDisplay(filterMap);
  };
  const onReset = () => {
    form.resetFields();
    setMapDataDisplay(defaultMapDataDisplay);
  };
  const onModalFormOk = async () => {
    if (isCreate) {
      try {
        const createBaseMap = {
          name: modalForm.getFieldValue('name'),
          url: modalForm.getFieldValue('url'),
          icon: modalForm.getFieldValue('icon'),
          role: modalForm.getFieldValue('role') || 0,
          min_zoom: parseInt(modalForm.getFieldValue('min_zoom')) || 1,
          max_zoom: parseInt(modalForm.getFieldValue('max_zoom')) || 1,
          is_active: isUserAvailable,
          base_map_type: modalForm.getFieldValue('base_map_type') || baseMapType[0].value,
        };
        await apiAdmin.addBaseMap(createBaseMap);
        await handleFetchBaseMap();
        info({ title: 'ข้อความจากระบบ', content: 'ดำเนินการเรียบร้อย' });
      } catch (err: any) {
        error({
          title: 'ข้อความจากระบบ',
          content: `ไม่สามารถดำเนินงานได้(${err.response.data.message})`,
        });
      }
    } else {
      try {
        const updateBaseMap = {
          name: modalForm.getFieldValue('name') || editBaseMap.name,
          url: modalForm.getFieldValue('url') || editBaseMap.url,
          icon: modalForm.getFieldValue('icon') || editBaseMap.icon,
          role: modalForm.getFieldValue('role') || editBaseMap?.role,
          min_zoom: parseInt(modalForm.getFieldValue('min_zoom')) || editBaseMap.min_zoom || 1,
          max_zoom: parseInt(modalForm.getFieldValue('max_zoom')) || editBaseMap.max_zoom || 1,
          is_active: isUserAvailable,
          base_map_type: modalForm.getFieldValue('base_map_type') || editBaseMap.base_map_type,
        };
        await apiAdmin.editBaseMap({ code: editBaseMap.code, data: updateBaseMap });
        await handleFetchBaseMap();
        info({ title: 'ข้อความจากระบบ', content: 'ดำเนินการเรียบร้อย' });
      } catch (err: any) {
        error({
          title: 'ข้อความจากระบบ',
          content: `ไม่สามารถดำเนินงานได้(${err.response.data.message})`,
        });
      }
    }

    modalForm.resetFields();
    setOpen(false);
  };
  const onModalFormCancel = () => {
    modalForm.resetFields();
    setOpen(false);
  };
  return (
    <SContainer>
      <h2>ค้นหา</h2>
      <div>
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 8 }} form={form} layout="horizontal">
          <Form.Item name="name" label="ชื่อแผนที่ฐาน">
            <Input
              allowClear
              onChange={(e) => {
                form.setFieldValue('name', e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
            <Button
              type="primary"
              style={{ marginRight: '0.75rem', background: '#336633' }}
              onClick={onSearch}
            >
              ค้นหา
            </Button>
            <Button htmlType="button" onClick={onReset}>
              ยกเลิก
            </Button>
          </Form.Item>
        </Form>
      </div>
      <hr style={{ border: '1px solid #ddd' }} />
      <div>
        <Button
          type="dashed"
          style={{ marginBottom: '1rem', marginTop: '0.5rem' }}
          onClick={() => {
            setIsCreate(true);
            setOpen(true);
          }}
        >
          <UserAddOutlined /> เพิ่มข้อมูล
        </Button>
        <Table
          columns={columns}
          dataSource={mapDataDisplay.map((e, i) => {
            return {
              ...e,
              no: i + 1,
              importName: e.created_user.username,
              importDate: `${new Date(e.created_at)}`,
              status: e.is_active ? 'เปิดใช้งาน' : 'ปิด',
            };
          })}
        />
      </div>
      {open && (
        <Modal
          title={isCreate ? 'เพิ่มข้อมูลแผนที่ฐาน' : 'แก้ไขแผนที่ฐาน'}
          centered
          open={open}
          okText={isCreate ? 'บันทึก' : 'แก้ไข'}
          onOk={onModalFormOk}
          cancelText={'ยกเลิก'}
          onCancel={onModalFormCancel}
          width={1000}
        >
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
            form={modalForm}
            layout="horizontal"
          >
            <Form.Item name="name" label="Name">
              <Input
                allowClear
                defaultValue={isCreate ? '' : editBaseMap?.name}
                onChange={(e) => {
                  modalForm.setFieldValue('name', e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name="base_map_type" label="Service Type">
              <Select
                labelInValue
                defaultValue={
                  isCreate
                    ? { value: baseMapType[0].value, label: baseMapType[0].label }
                    : {
                        value: editBaseMap?.base_map_type,
                        label: editBaseMap?.base_map_type,
                      }
                }
                showSearch
                filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
                onChange={(e) => modalForm.setFieldValue('base_map_type', e.value)}
              >
                {baseMapType?.map(({ value, label }) => {
                  return (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="url" label="Service url">
              <Input
                allowClear
                defaultValue={isCreate ? '' : editBaseMap?.url}
                onChange={(e) => {
                  modalForm.setFieldValue('url', e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name="icon" label="Icon">
              <Input
                allowClear
                defaultValue={isCreate ? '' : editBaseMap?.icon}
                onChange={(e) => {
                  modalForm.setFieldValue('icon', e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name="role" label="Role">
              <Select
                labelInValue
                defaultValue={
                  isCreate
                    ? DEFAULT_ROLE_OPTION[0]
                    : { value: editBaseMap?.role, label: userRoleIdToRoleEnum[editBaseMap?.role] }
                }
                showSearch
                filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
                onChange={(e) => modalForm.setFieldValue('role', e.value)}
              >
                {DEFAULT_ROLE_OPTION?.map(({ value, label }) => {
                  return (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="min_zoom" label="Min zoom">
              <Input
                allowClear
                type="number"
                defaultValue={isCreate ? 1 : editBaseMap.min_zoom}
                onChange={(e) => {
                  modalForm.setFieldValue('min_zoom', e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name="max_zoom" label="Max zoom">
              <Input
                allowClear
                type="number"
                defaultValue={isCreate ? 1 : editBaseMap.max_zoom}
                onChange={(e) => {
                  modalForm.setFieldValue('max_zoom', e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name="is_active" label="เปิดใช้งาน" valuePropName="checked">
              <Switch
                defaultChecked={isCreate ? false : editBaseMap.is_active}
                onChange={(e) => {
                  setIsUserAvailable(e);
                  // modalForm.setFieldValue('is_active', e);
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </SContainer>
  );
};
export default LayerManagement;
const SContainer = styled.div`
  padding: 20px;
  width: 100%;
  & > div {
    margin-top: 15px;
  }
`;
