import React, { useContext, useEffect, useState } from 'react';
import {
  CloseOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Slider } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Map } from 'mapbox-gl';
import styled from 'styled-components';
import WorldIcon from '../../assets/icons/world.svg';
import { MainPageContext } from '../../contexts/MainPageContext';
import { MapBoxContext } from '../../contexts/MapBoxContext';
import { ICustomMapServiceLayer } from '../../interfaces/map.interface';
import { IMasterLayerCheckboxGroup } from '../../interfaces/search.interface';
import MapService from '../../services/MapService';
import CustomControl from './index.custom';

interface CustomControlProps {
  map: Map | null;
  selectedCustomTool: string;
  setSelectedCustomTool: React.Dispatch<React.SetStateAction<string>>;
}
const AddMapServiceCustomControl = ({
  map,
  selectedCustomTool,
  setSelectedCustomTool,
}: CustomControlProps) => {
  // config

  const iconStyle: React.CSSProperties = {
    fontSize: '14px',
    cursor: 'pointer',
    marginRight: '10px',
    marginTop: '3px',
  };

  const [openPopup, setOpenPopup] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [form] = Form.useForm();
  const { isMobile } = useContext(MainPageContext);
  useEffect(() => {
    if (selectedCustomTool !== 'add_map_service') {
      setOpenPopup(false);
    }
  }, [selectedCustomTool]);

  const { customMapServices, setCustomMapServices } = useContext(MapBoxContext);

  const [mapServiceUrl, setMapServiceUrl] = useState<string>('');
  const [customMapServiceLayersChecked, setCustomMapServiceLayersChecked] = useState<
    IMasterLayerCheckboxGroup[]
  >([]);

  const mapService = new MapService();
  const handleOnSubmit = async () => {
    if (!mapServiceUrl) return;

    if (!mapService.isValidUrl(mapServiceUrl)) {
      console.warn(`Invalid URL ${mapServiceUrl}`);
      return;
    }

    const newCustomMapService = await mapService.makeRequest(mapServiceUrl);
    setCustomMapServices([...customMapServices, newCustomMapService]);
    setIsSubmitted(true);
  };

  const handleCustomMapServiceChecked = (
    customMapServiceId: string,
    layer: ICustomMapServiceLayer,
    checked: boolean
  ) => {
    const newObjectCustomMapServices = [...customMapServices];
    const cmsIndex = newObjectCustomMapServices.findIndex((el) => el.id === customMapServiceId);
    const cmsLayerIndex = newObjectCustomMapServices[cmsIndex].layers?.findIndex(
      ({ id }) => id === layer.id
    );
    newObjectCustomMapServices[cmsIndex].layers[cmsLayerIndex].checked = checked;
    setCustomMapServices(newObjectCustomMapServices);
  };

  const handleCustomMapServiceOpacityChanged = (
    customMapServiceId: string,
    layer: ICustomMapServiceLayer,
    value: number
  ) => {
    const newObjectCustomMapServices = [...customMapServices];
    const cmsIndex = newObjectCustomMapServices.findIndex((el) => el.id === customMapServiceId);
    const cmsLayerIndex = newObjectCustomMapServices[cmsIndex].layers?.findIndex(
      ({ id }) => id === layer.id
    );
    newObjectCustomMapServices[cmsIndex].layers[cmsLayerIndex].opacity = value;
    setCustomMapServices(newObjectCustomMapServices);
  };

  const handleCustomMapServiceToggleExpand = (customMapServiceId: string) => {
    const newObjectCustomMapServices = [...customMapServices];
    const cmsIndex = newObjectCustomMapServices.findIndex((el) => el.id === customMapServiceId);
    newObjectCustomMapServices[cmsIndex].isExpand = !newObjectCustomMapServices[cmsIndex].isExpand;
    setCustomMapServices(newObjectCustomMapServices);
  };

  const handleDeleteCustomMapService = (customMapServiceId: string) => {
    const newCustomMapService = customMapServices.filter(({ id }) => id !== customMapServiceId);
    setCustomMapServices(newCustomMapService);
    setIsSubmitted(false);
  };

  return (
    <SContainer className={`${isMobile ? 'mobile' : ''}`}>
      {map && (
        <CustomControl
          map={map}
          top={'390px'}
          svg={WorldIcon}
          onClick={() => {
            setSelectedCustomTool('add_map_service'), setOpenPopup(true);
          }}
        />
      )}
      {openPopup && (
        <SPopupContainer className={`${isMobile ? 'mobile' : ''}`}>
          <div className="head">
            <div style={{ color: 'white' }}>Add Services</div>
            <div
              onClick={() => {
                setOpenPopup(false);
              }}
              style={{ cursor: 'pointer' }}
            >
              <CloseOutlined />
            </div>
          </div>
          <Form
            style={{ padding: '10px' }}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            form={form}
            layout="horizontal"
          >
            {!isSubmitted && (
              <Form.Item label="URL :">
                <Input
                  value={mapServiceUrl}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setMapServiceUrl(e.target.value);
                  }}
                />
              </Form.Item>
            )}
            {!isSubmitted && (
              <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Button
                  type="primary"
                  style={{ marginRight: '0.75rem' }}
                  onClick={() => {
                    handleOnSubmit();
                  }}
                >
                  ตกลง
                </Button>
                <Button
                  htmlType="button"
                  onClick={() => {
                    setOpenPopup(false);
                  }}
                >
                  ยกเลิก
                </Button>
              </Form.Item>
            )}
            {customMapServices &&
              customMapServices.map((customMapService) => {
                const checkboxGroupId = `custom_map_service_checkbox_group_${customMapService.id}`;

                return (
                  <div key={`custom_map_service_group_${customMapService.id}`}>
                    <SHeader
                      key={`group_header_${customMapService.id}`}
                      style={{ fontSize: isMobile ? '5vw' : '14px' }}
                    >
                      <div>
                        {customMapService.isExpand ? (
                          <MinusCircleOutlined
                            style={iconStyle}
                            onClick={() => {
                              handleCustomMapServiceToggleExpand(customMapService.id);
                            }}
                          />
                        ) : (
                          <PlusCircleOutlined
                            style={iconStyle}
                            onClick={() => {
                              handleCustomMapServiceToggleExpand(customMapService.id);
                            }}
                          />
                        )}
                        {customMapService.name}
                      </div>
                      <DeleteOutlined
                        style={{ ...iconStyle, fontSize: '20px', marginTop: '0' }}
                        onClick={() => {
                          handleDeleteCustomMapService(customMapService.id);
                        }}
                      />
                    </SHeader>
                    {customMapService.isExpand && (
                      <SCheckboxGroup
                        key={checkboxGroupId}
                        value={
                          customMapServiceLayersChecked.find(
                            (layer) => layer.id === checkboxGroupId
                          )?.value
                        }
                        onChange={(checkedValues: CheckboxValueType[]) => {
                          const item: IMasterLayerCheckboxGroup = {
                            id: checkboxGroupId,
                            value: checkedValues,
                          };
                          const otherCheckboxGroups = customMapServiceLayersChecked.filter(
                            (item) => item.id != checkboxGroupId
                          );
                          setCustomMapServiceLayersChecked([...otherCheckboxGroups, item]);
                        }}
                      >
                        {customMapService.layers?.map((layer, i: number) => {
                          const layerKey = `${customMapService.id}_${layer.id}`;
                          return (
                            <div key={`div_checkbox_${layerKey}`}>
                              <SOptionContainer key={i}>
                                <Checkbox
                                  key={layerKey}
                                  value={layerKey}
                                  style={{ fontSize: isMobile ? '5vw' : '14px' }}
                                  onChange={(e: CheckboxChangeEvent) => {
                                    handleCustomMapServiceChecked(
                                      customMapService.id,
                                      layer,
                                      e.target.checked
                                    );
                                  }}
                                >
                                  {layer.name}
                                </Checkbox>
                                <SSliderContainer>
                                  <Slider
                                    className={isMobile ? 'mobile' : ''}
                                    defaultValue={100}
                                    value={layer.opacity}
                                    disabled={!layer.checked}
                                    style={{ width: isMobile ? '200px' : '150px' }}
                                    trackStyle={{
                                      backgroundColor: '#669966',
                                      height: isMobile ? '15px' : '4px',
                                    }}
                                    onChange={(value: number) => {
                                      handleCustomMapServiceOpacityChanged(
                                        customMapService.id,
                                        layer,
                                        value
                                      );
                                    }}
                                  />
                                </SSliderContainer>
                              </SOptionContainer>
                            </div>
                          );
                        })}
                      </SCheckboxGroup>
                    )}
                  </div>
                );
              })}
          </Form>
        </SPopupContainer>
      )}
    </SContainer>
  );
};

export default AddMapServiceCustomControl;
const SContainer = styled.div`
  position: relative;
`;
const SPopupContainer = styled.div`
  position: absolute;
  min-width: 30vw;
  top: 10px;
  right: 60px;
  background-color: white;
  border-radius: 5px;
  z-index: 400;

  & > div.head {
    background-color: green;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
  }
  & > div {
    padding: 0px 5px 0px 5px;
  }
`;

const SImage = styled.img`
  width: 100%;
`;
const SSliderContainer = styled.div`
  display: flex;
  align-items: center;
  & > svg,
  span {
    margin-right: 5px;
  }
`;
const SOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  padding: 15px;
  & > label.ant-checkbox-group-item {
    margin-inline-start: 0;
  }
  & > label.ant-checkbox-wrapper {
    margin-inline-start: 0;
  }
`;
const SHeader = styled.div`
  background-color: rgba(102, 153, 102, 0.125);
  display: flex;
  justify-content: space-between;
  /* justify-content: center; */
  padding: 0px 15px;
  align-items: center;
  color: #000000;
`;
