export const userRoleIdToRoleEnum: any = {
  '999': 'admin',
  '0': 'guest',
  '1': 'user',
};
export const userRoleToRoleIdEnum: any = {
  admin: 999,
  guest: 0,
  user: 1,
};
interface ISubMenu {
  id: string;
  text: string;
  clickable: true;
  component: JSX.Element;
  icon?: JSX.Element;
}
export interface IAdminMenu {
  id: string;
  text: string;
  clickable: boolean;
  component: JSX.Element;
  subMenu?: ISubMenu[];
  icon?: JSX.Element;
}

export interface IUsers {
  username: string;
  information: string;
  password: string;
  full_name: string;
  status: 'admin' | 'emp' | 'user';
  image: string;
  role: 0 | 1 | 999;
  email: string;
  is_active: boolean;
  id: string;
}
export interface IUserRecordType {
  id: string;
  no: number;
  username: string;
  role: any;
  full_name: string;
  email: string;
}

// TODO: Depend on database
export interface IHistoryReportGuestRecordType {
  no: number;
  description: string;
  ip: string;
  eventDateTime: Date;
}

export interface IHistoryReportEmployeeRecordType {
  no: number;
  username: string;
  description: string;
  ip: string;
  eventDateTime: Date;
}
