import { EditOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import apiAdmin from '../../apis/admin';
import { ILayer, ILayerType } from '../../interfaces/layer.interface';

const { info, error } = Modal;
const { Option } = Select;
const LayerManagement = () => {
  const [isUserAvailable, setIsUserAvailable] = useState(false);
  const [layerDisplay, setLayerDisplay] = useState<ILayer[]>([]);
  const [layerType, setLayerType] = useState<ILayerType[]>([]);

  const [defaultLayerDisplay, setDefaultLayerDisplay] = useState<ILayer[]>([]);
  const [editLayer, setEditLayer] = useState<ILayer>();
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isCreate, setIsCreate] = useState<boolean>(true);
  const serviceType = [
    { value: 'WMS', label: 'WMS' },
    { value: 'WMTS', label: 'WMTS' },
    { value: 'WFS', label: 'WFS' },
  ];
  const columns: ColumnsType<any> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      sorter: (a: any, b: any) => a.no - b.no,
    },
    {
      title: 'ชื่อชั้นข้อมูล',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ชื่อผู้นำเข้า',
      dataIndex: 'importName',
      key: 'importName',
    },
    {
      title: 'วันที่นำเข้า',
      dataIndex: 'importDate',
      key: 'importDate',
    },
    {
      title: 'แก้ไข',
      dataIndex: 'edit',
      key: 'edit',
      onCell: (record) => {
        return {
          onClick: () => {
            const editlayer: ILayer = {
              ...record,
            };
            setIsUserAvailable(editlayer.is_active);
            setEditLayer(editlayer);
            setIsCreate(false);
            setOpen(true);
          },
        };
      },
      render: () => {
        return (
          <div>
            <EditOutlined />
          </div>
        );
      },
    },
    {
      title: 'เปิดใช้งาน',
      dataIndex: 'status',
      key: 'status',
    },
  ];
  const onSearch = () => {
    const filterLayername = form.getFieldValue('name');
    if (!filterLayername) {
      setLayerDisplay([...defaultLayerDisplay]);
    }
    const filterLayer = defaultLayerDisplay.filter((account) => {
      if (filterLayername) {
        return account.name.indexOf(filterLayername) > -1;
      } else {
        return [...defaultLayerDisplay];
      }
    });
    setLayerDisplay(filterLayer);
  };
  const onReset = () => {
    form.resetFields();
    setLayerDisplay(defaultLayerDisplay);
  };
  const onModalFormOk = async () => {
    if (isCreate) {
      try {
        const createLayer = {
          name: modalForm.getFieldValue('name'),
          service_type: modalForm.getFieldValue('service_type') || serviceType[0].value,
          service_url: modalForm.getFieldValue('service_url'),
          export_file: modalForm.getFieldValue('export_file'),
          layer_type_id: modalForm.getFieldValue('layer_type_id') || layerType[0].layer_type_id,
          legend: modalForm.getFieldValue('legend'),
          is_active: isUserAvailable,
        };
        await apiAdmin.addLayer(createLayer);
        await handleFetchLayer();
        info({ title: 'ข้อความจากระบบ', content: 'ดำเนินการเรียบร้อย' });
      } catch (err: any) {
        error({
          title: 'ข้อความจากระบบ',
          content: `ไม่สามารถดำเนินงานได้(${err.response.data.message})`,
        });
      }
    } else {
      try {
        if (!editLayer) return;
        const updateBaseMap = {
          name: modalForm.getFieldValue('name') || editLayer.name,
          service_type: modalForm.getFieldValue('service_type') || editLayer.service_type,
          service_url: modalForm.getFieldValue('service_url') || editLayer.service_url,
          export_file: modalForm.getFieldValue('export_file') || editLayer.export_file,
          layer_type_id: modalForm.getFieldValue('layer_type_id') || editLayer.layer_type_id,
          legend: modalForm.getFieldValue('legend') || editLayer.legend,
          is_active: isUserAvailable,
        };
        await apiAdmin.editLayer({ layerId: editLayer.id, data: updateBaseMap });
        await handleFetchLayer();
        info({ title: 'ข้อความจากระบบ', content: 'ดำเนินการเรียบร้อย' });
      } catch (err: any) {
        error({
          title: 'ข้อความจากระบบ',
          content: `ไม่สามารถดำเนินงานได้(${err.response.data.message})`,
        });
      }
    }
    modalForm.resetFields();
    setOpen(false);
  };
  const onModalFormCancel = () => {
    modalForm.resetFields();
    setOpen(false);
  };
  const handleFetchLayer = async () => {
    const layerFetchData = await apiAdmin.fetchLayerList();
    setLayerDisplay(layerFetchData);
    setDefaultLayerDisplay(layerFetchData);
  };
  const handleFetchLayerType = async () => {
    const layerTypeFetch = await apiAdmin.fetchLayerType();
    setLayerType(layerTypeFetch);
  };
  useEffect(() => {
    handleFetchLayer();
    handleFetchLayerType();
  }, []);
  return (
    <SContainer>
      <h2>ค้นหา</h2>
      <div>
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 8 }} form={form} layout="horizontal">
          <Form.Item name="name" label="ชื่อชั้นข้อมูล">
            <Input
              allowClear
              onChange={(e) => {
                form.setFieldValue('name', e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
            <Button
              type="primary"
              style={{ marginRight: '0.75rem', background: '#336633' }}
              onClick={onSearch}
            >
              ค้นหา
            </Button>
            <Button htmlType="button" onClick={onReset}>
              ยกเลิก
            </Button>
          </Form.Item>
        </Form>
      </div>
      <hr style={{ border: '1px solid #ddd' }} />
      <div>
        <Button
          type="dashed"
          style={{ marginBottom: '1rem', marginTop: '0.5rem' }}
          onClick={() => {
            setIsCreate(true);
            setOpen(true);
          }}
        >
          <UserAddOutlined /> เพิ่มข้อมูล
        </Button>
        <Table
          columns={columns}
          dataSource={layerDisplay.map((e, i) => {
            return {
              ...e,
              no: i + 1,
              importName: e.created_user.username,
              importDate: `${new Date(e.created_at)}`,
              status: e.is_active ? 'เปิดใช้งาน' : 'ปิด',
            };
          })}
        />
      </div>
      {open && (
        <Modal
          title={isCreate ? 'เพิ่มชั้นข้อมูล' : 'แก้ไขชั้นข้อมูล'}
          centered
          open={open}
          okText={isCreate ? 'บันทึก' : 'แก้ไข'}
          onOk={onModalFormOk}
          cancelText={'ยกเลิก'}
          onCancel={onModalFormCancel}
          width={1000}
        >
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
            form={modalForm}
            layout="horizontal"
          >
            <Form.Item name="name" label="ชื่อชั้นข้อมูล">
              <Input
                allowClear
                defaultValue={isCreate ? '' : editLayer?.name}
                onChange={(e) => {
                  modalForm.setFieldValue('name', e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name="service_type" label="Service Type">
              <Select
                labelInValue
                defaultValue={
                  isCreate
                    ? { value: serviceType[0].value, label: serviceType[0].label }
                    : {
                        value: editLayer?.service_type,
                        label: editLayer?.service_type,
                      }
                }
                showSearch
                filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
                onChange={(e) => modalForm.setFieldValue('service_type', e.value)}
              >
                {serviceType?.map(({ value, label }) => {
                  return (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="service_url" label="Service url">
              <Input
                allowClear
                defaultValue={isCreate ? '' : editLayer?.service_url}
                onChange={(e) => {
                  modalForm.setFieldValue('service_url', e.target.value);
                }}
              />
              <SExample>
                example :
                http://34.124.165.150/geoserver/ows?service=WMS&request=GetMap&layers=geonode:province
              </SExample>
            </Form.Item>
            <Form.Item name="export_file" label="Export File URL">
              <Input
                allowClear
                defaultValue={isCreate ? '' : editLayer?.export_file}
                onChange={(e) => {
                  modalForm.setFieldValue('export_file', e.target.value);
                }}
              />
              <SExample>
                example :
                http://34.124.165.150/geoserver/ows?service=WMS&request=GetMap&layers=geonode:province
              </SExample>
            </Form.Item>
            <Form.Item name="legend" label="legend">
              <Input
                allowClear
                defaultValue={isCreate ? '' : editLayer?.legend}
                onChange={(e) => {
                  modalForm.setFieldValue('legend', e.target.value);
                }}
              />
              <SExample>
                example :
                http://34.124.165.150/geoserver/ows?service=WMS&request=GetLegendGraphic&format=image/png&WIDTH=20&HEIGHT=20&LAYER=geonode:province&STYLE=province&version=1.3.0&sld_version=1.1.0&legend_options=fontAntiAliasing:true;fontSize:12;forceLabels:on
              </SExample>
            </Form.Item>
            <Form.Item name="layer_type_id" label="ประเภท">
              <Select
                labelInValue
                defaultValue={
                  isCreate
                    ? { value: layerType[0].layer_type_id, label: layerType[0].layer_type_name }
                    : {
                        value: editLayer?.layer_type_id,
                        label: editLayer?.layer_type_id,
                      }
                }
                showSearch
                filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
                onChange={(e) => modalForm.setFieldValue('layer_type_id', e.value)}
              >
                {layerType?.map(({ layer_type_id: value, layer_type_name: label }) => {
                  return (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
              {/* <Input
                allowClear
                type="number"
                defaultValue={isCreate ? '' : editLayer?.layer_type_id}
                onChange={(e) => {
                  modalForm.setFieldValue('layer_type_id', parseInt(e.target.value));
                }}
              /> */}
            </Form.Item>

            <Form.Item name="is_active" label="เปิดใช้งาน" valuePropName="checked">
              <Switch
                defaultChecked={isCreate ? false : editLayer?.is_active}
                onChange={(e) => {
                  setIsUserAvailable(e);
                  // modalForm.setFieldValue('is_active', e);
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </SContainer>
  );
};
export default LayerManagement;
const SExample = styled.div`
  font-size: 8px;
  font-style: italic;
  color: #959494;
`;
const SContainer = styled.div`
  padding: 20px;
  width: 100%;
  & > div {
    margin-top: 15px;
  }
`;
