export enum TAB_SEARCH {
  FOREST = 'FOREST',
  INSTITUTION = 'INSTITUTION',
  MULTI_PURPOSE = 'MULTI_PURPOSE',
  IMPORT_LAYER_DATA = 'IMPORT_LAYER_DATA',
  EXPORT_LAYER_DATA = 'EXPORT_LAYER_DATA',
  EXPORT_REPORT = 'EXPORT_REPORT',
  SEARCH = 'SEARCH',
  LAYER = 'LAYER',
}
export enum TAB_SEARCH_THAI {
  FOREST = 'ค้นหาข้อมูลป่าสงวนแห่งชาติ',
  INSTITUTION = 'ค้นหาที่ตั้งหน่วยงาน',
  MULTI_PURPOSE = 'ค้นหาอเนกประสงค์',
  IMPORT_LAYER_DATA = 'นำเข้าชั้นข้อมูล',
  EXPORT_LAYER_DATA = 'ส่งออกชั้นข้อมูล',
  EXPORT_REPORT = 'ส่งออกรายงาน',
  SEARCH = 'ค้นหาข้อมูล',
  LAYER = 'รายการชั้นข้อมูล',
}

export enum HISTORY_REPORT_TYPE {
  GUEST = 'GUEST', // ประชาชนทั่วไป
  EMPLOYEE = 'EMPLOYEE', // เจ้าหน้าที่
}

export enum USER_ROLE {
  GUEST = 0,
  EMPLOYEE = 1,
  ADMINISTRATOR = 999,
}

export enum MAPBOX_SCHEME {
  XYZ = 'xyz',
  TMS = 'tms',
}

export enum LAYER_SERVICE_TYPE {
  WMS = 'WMS',
  WMTS = 'WMTS',
  WFS = 'WFS',
}

export enum BASE_MAP_SERVICE_TYPE {
  TMS = 'TMS',
  WMS = 'WMS',
  WMTS = 'WMTS',
  WFS = 'WFS',
}
