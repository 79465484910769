import React from 'react';

import { Button, Form, Select } from 'antd';
import {
  optionAgency,
  optionDataTypes,
  optionNoticeYears,
  optionOffices,
  optionProvinces,
  optionUnderAgency,
} from '../../configs/search_choices';

interface ITabInstitutionProps {
  onSearch?: () => any;
  onCancel?: () => any;
  officeName?: string;
  province?: string;
  underAgency?: string;
  agency?: string;
}

const TabInstitution: React.FC<ITabInstitutionProps> = ({
  onSearch,
  onCancel,
  officeName,
  province,
  underAgency,
  agency,
}) => {
  const [form] = Form.useForm();

  const onSubmit = () => {
    onSearch && onSearch();
  };
  const onReset = () => {
    onCancel && onCancel();
    form.resetFields();
  };

  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} form={form} layout="horizontal">
      <Form.Item label="ประเภทข้อมูล">
        <Select
          labelInValue
          defaultValue={{ value: '-', label: 'กรุณาเลือก' }}
          // onChange={handleChange}
          options={optionDataTypes}
        />
      </Form.Item>
      <Form.Item label="จังหวัด">
        <Select
          labelInValue
          defaultValue={{ value: '-', label: 'กรุณาเลือก' }}
          // style={{ width: 120 }}
          // onChange={handleChange}
          options={optionProvinces}
        />
      </Form.Item>
      <Form.Item label="อำเภอ">
        <Select
          labelInValue
          defaultValue={{ value: '-', label: 'เลือกทั้งหมด' }}
          // style={{ width: 120 }}
          // onChange={handleChange}
          options={optionProvinces}
        />
      </Form.Item>
      <Form.Item label="ตำบล">
        <Select
          labelInValue
          defaultValue={{ value: '-', label: 'เลือกทั้งหมด' }}
          // style={{ width: 120 }}
          // onChange={handleChange}
          options={optionProvinces}
        />
      </Form.Item>
      <Form.Item label="ปีที่เริ่มปลูก">
        <Select
          labelInValue
          defaultValue={{ value: '-', label: 'เลือกทั้งหมด' }}
          style={{ width: 120 }}
          // onChange={handleChange}
          options={optionNoticeYears}
        />
        <span style={{ paddingLeft: '10px', paddingRight: '10px' }}> ถึง </span>
        <Select
          labelInValue
          defaultValue={{ value: '-', label: 'เลือกทั้งหมด' }}
          style={{ width: 120 }}
          // onChange={handleChange}
          options={optionNoticeYears}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
        <Button
          type="primary"
          style={{ marginRight: '0.75rem', background: '#336633' }}
          onClick={onSubmit}
        >
          ค้นหา
        </Button>
        <Button htmlType="button" onClick={onReset}>
          ยกเลิก
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TabInstitution;
