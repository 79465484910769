import React, { useEffect, useRef } from 'react';
import { Map } from 'mapbox-gl';
import styled from 'styled-components';
interface CustomControlProps {
  map: Map | null;
  setOpenDirection: React.Dispatch<React.SetStateAction<boolean>>;
}
const DirectionToggleCustomControl = ({ map, setOpenDirection }: CustomControlProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const handleOnClick = () => {
    setOpenDirection(true);
  };
  useEffect(() => {
    if (map && containerRef.current) {
      const controlEl = document.createElement('div');
      const newContent = document.createTextNode(`Direction`);
      controlEl.appendChild(newContent);
      controlEl.style.backgroundColor = 'white';
      controlEl.style.position = 'absolute';
      controlEl.style.fontSize = '15px';
      controlEl.style.top = '5px';
      controlEl.style.padding = '0px 10px';
      controlEl.style.left = '5px';
      controlEl.style.width = 'fit-content';
      controlEl.style.height = '20px';
      controlEl.style.borderRadius = '5px';
      controlEl.style.display = 'flex';
      controlEl.style.alignItems = 'center';
      controlEl.style.cursor = 'pointer';
      controlEl.addEventListener('click', handleOnClick);
      containerRef.current.appendChild(controlEl);

      return () => {
        controlEl.addEventListener('click', handleOnClick);
        containerRef.current && containerRef.current.removeChild(controlEl);
      };
    }
  }, [map]);

  return <div ref={containerRef} />;
};

export default DirectionToggleCustomControl;
const SContainer = styled.div`
  position: relative;
`;
