import React, { useContext, useEffect, useState } from 'react';
import { Map } from 'mapbox-gl';
import CustomControl from './index.custom';
import styled from 'styled-components';
import Pin from '../../assets/icons/pin.svg';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import { MainPageContext } from '../../contexts/MainPageContext';
import { utmToLatLon } from '../../utils/location-convert';
interface CustomControlProps {
  map: Map | null;
  selectedCustomTool: string;
  setSelectedCustomTool: React.Dispatch<React.SetStateAction<string>>;
  locationSelected: { lat: string | undefined; lng: string | undefined };
  setLocationSelected: React.Dispatch<
    React.SetStateAction<{
      lat: string | undefined;
      lng: string | undefined;
    }>
  >;
  handleGoToLocation: (location: { lat: string; lng: string }) => void;
}
const { Option } = Select;
const gotoXYOptions = [
  { value: 'latLng', label: 'Latitude/Longtitude' },
  { value: '47', label: 'UTM Zone 47N' },
  { value: '48', label: 'UTM Zone 48N' },
];
const GoToXYCustomControl = ({
  map,
  selectedCustomTool,
  setSelectedCustomTool,
  locationSelected,
  setLocationSelected,
  handleGoToLocation,
}: CustomControlProps) => {
  const [form] = Form.useForm();
  const [openPopup, setOpenPopup] = useState(false);
  const { isMobile } = useContext(MainPageContext);
  const [gotoXYSelected, setGotoXYSelected] = useState('latLng');
  const [utmSelected, setUTMSelected] = useState<{
    easting: number | undefined;
    northing: number | undefined;
  }>({
    easting: undefined,
    northing: undefined,
  });
  useEffect(() => {
    if (selectedCustomTool !== 'go_to_xy') {
      setOpenPopup(false);
    }
  }, [selectedCustomTool]);
  return (
    <SContainer>
      <CustomControl
        map={map}
        top={'440px'}
        svg={Pin}
        onClick={() => {
          setSelectedCustomTool('go_to_xy'), setOpenPopup(true);
        }}
      />
      {openPopup && (
        <SPopupContainer>
          <div className="head">
            <div style={{ color: 'white' }}>ไปยังตำแหน่ง (Go To XY)</div>
            <div
              onClick={() => {
                setOpenPopup(false);
              }}
              style={{ cursor: 'pointer' }}
            >
              <CloseOutlined />
            </div>
          </div>
          <Form
            style={{ padding: '10px 0px 10px 10px' }}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            form={form}
            layout="horizontal"
            labelWrap
          >
            <Form.Item label="ระบบพิกัด">
              <Select
                allowClear
                defaultValue={{ value: 'latLng', label: 'Latitude/Longtitude' }}
                onChange={(e: any) => {
                  setGotoXYSelected(e);
                }}
              >
                {gotoXYOptions.map((e, i) => {
                  return (
                    <Option key={i} value={e.value} style={isMobile ? { fontSize: '5vw' } : {}}>
                      {e.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            {gotoXYSelected === 'latLng' ? (
              <>
                <Form.Item label="ละติจูด (X)">
                  <Input
                    value={locationSelected.lat}
                    onChange={(e) =>
                      setLocationSelected({ ...locationSelected, lat: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item label="ลองติจูด (Y)">
                  <Input
                    value={locationSelected.lng}
                    onChange={(e) =>
                      setLocationSelected({ ...locationSelected, lng: e.target.value })
                    }
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item label="Easting">
                  <Input
                    value={utmSelected.easting}
                    onChange={(e) =>
                      setUTMSelected({ ...utmSelected, easting: parseFloat(e.target.value) })
                    }
                  />
                </Form.Item>
                <Form.Item label="Northing">
                  <Input
                    value={utmSelected.northing}
                    onChange={(e) =>
                      setUTMSelected({ ...utmSelected, northing: parseFloat(e.target.value) })
                    }
                  />
                </Form.Item>
              </>
            )}
            <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
              <Button
                type="primary"
                style={{ marginRight: '0.75rem' }}
                onClick={() => {
                  if (gotoXYSelected === 'latLng') {
                    if (locationSelected.lat && locationSelected.lng) {
                      handleGoToLocation({ lat: locationSelected.lat, lng: locationSelected.lng });
                    }
                  } else {
                    if (utmSelected.easting && utmSelected.northing) {
                      const latlngConverse = utmToLatLon(
                        utmSelected.easting,
                        utmSelected.northing,
                        parseInt(gotoXYSelected)
                      );
                      handleGoToLocation({
                        lat: `${latlngConverse.latitude}`,
                        lng: `${latlngConverse.longitude}`,
                      });
                    }
                  }
                }}
              >
                ตกลง
              </Button>
              <Button
                htmlType="button"
                onClick={() => {
                  setLocationSelected({ lat: undefined, lng: undefined });
                  setUTMSelected({ easting: undefined, northing: undefined });
                }}
              >
                ล้างข้อมูล
              </Button>
            </Form.Item>
          </Form>
        </SPopupContainer>
      )}
    </SContainer>
  );
};

export default GoToXYCustomControl;
const SContainer = styled.div`
  position: relative;
`;
const SPopupContainer = styled.div`
  position: absolute;
  min-width: 30vw;
  top: 10px;
  right: 60px;
  background-color: white;
  border-radius: 5px;
  z-index: 400;
  & > div.head {
    background-color: green;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
  }
  & > div {
    padding: 0px 5px 0px 5px;
  }
`;
