import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';
import { v4 as uuidv4 } from 'uuid';
import { ICustomMapService, ICustomMapServiceLayer } from '../interfaces/map.interface';

interface IGeoServerResponse {
  WMS_Capabilities: {
    Capability: {
      Layer: {
        Layer: {
          Name: string;
          Title: string;
        }[];
      };
    };
    Service: {
      Title: string;
      Name: string;
      Abstract: string;
    };
  };
}

class MapService {
  private _baseUrl: string;

  constructor() {
    this._baseUrl = '';
  }

  public makeRequest = async (wmsUrl: string): Promise<ICustomMapService> => {
    if (!this.isValidUrl(wmsUrl)) return {} as ICustomMapService;

    const { protocol, host, pathname, search, searchParams } = new URL(wmsUrl);
    this._baseUrl = `${protocol}//${host}${pathname}`;

    if (this._baseUrl === wmsUrl) {
      // GetCapabilities
      return await this._makeRequestGetCapabilities(wmsUrl);
    } else if (searchParams && searchParams.get('request') === 'GetCapabilities') {
      // GetCapabilities
      return await this._makeRequestGetCapabilities(wmsUrl);
    } else if (searchParams && searchParams.get('request') === 'GetMap') {
      // GetMap
      return await this._makeRequestGetMap(wmsUrl);
    } else {
      return {} as ICustomMapService;
    }
  };

  public isValidUrl = (wmsUrl: string) => {
    try {
      new URL(wmsUrl);
      return true;
    } catch (error) {
      return false;
    }
  };

  private _makeRequestGetMap = async (wmsUrl: string): Promise<ICustomMapService> => {
    try {
      const url = new URL(wmsUrl);
      const layerName: string = url.searchParams.get('layers')?.toString() || '-';
      const customMapService: ICustomMapService = {
        id: uuidv4(),
        name: url.hostname,
        description: url.hostname,
        map_service_url: wmsUrl,
        layers: [
          {
            id: uuidv4(),
            name: layerName,
            checked: false,
            layer_url: `${this._baseUrl}?service=WMS&request=GetMap&layers=${layerName}&format=image/png&height=256&width=256&srs=EPSG:3857&bbox={bbox-epsg-3857}&transparent=true`,
            opacity: 100,
          },
        ],
        isExpand: true,
      };
      return customMapService;
    } catch (error) {
      console.error(error);
      return {} as ICustomMapService;
    }
  };

  private _makeRequestGetCapabilities = async (wmsUrl: string): Promise<ICustomMapService> => {
    try {
      const data = await axios.get(wmsUrl);
      const parser = new XMLParser();
      const wms: IGeoServerResponse = parser.parse(data.data);

      const limitLayer =
        wms.WMS_Capabilities.Capability.Layer.Layer?.length > 10
          ? wms.WMS_Capabilities.Capability.Layer.Layer.slice(0, 10)
          : wms.WMS_Capabilities.Capability.Layer.Layer;

      const customMapService: ICustomMapService = {
        id: uuidv4(),
        name: wms.WMS_Capabilities.Service.Title,
        description: wms.WMS_Capabilities.Service.Abstract,
        map_service_url: wmsUrl,
        layers:
          limitLayer.map((layer) => {
            const item: ICustomMapServiceLayer = {
              id: layer.Name,
              name: layer.Title,
              checked: false,
              layer_url: `${this._baseUrl}?service=WMS&request=GetMap&layers=${layer.Name}&format=image/png&height=256&width=256&srs=EPSG:3857&bbox={bbox-epsg-3857}&transparent=true`,
              opacity: 100,
            };
            return item;
          }) || [],
        isExpand: true,
      };
      return customMapService;
    } catch (error) {
      console.error(error);
      return {} as ICustomMapService;
    }
  };
}

export default MapService;
