import { useEffect } from 'react';

export const useResize = (handler: any) => {
  useEffect(() => {
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [handler]);
};
