/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Form, Select } from 'antd';
import styled from 'styled-components';
import apiSearch from '../../apis/search';
import { MainPageContext, defaultOption } from '../../contexts/MainPageContext';
import { MasterDataContext } from '../../contexts/MasterDataContext';
import { IOption } from '../../interfaces/search.interface';
import Result from './Result.component ';

// antd
const { Option } = Select;

interface ITabSearchProps {
  onSearch?: () => any;
  onCancel?: () => any;
}
const TabSearch: React.FC<ITabSearchProps> = ({ onSearch, onCancel }) => {
  const {
    isMobile,
    searchType,
    setSearchType,
    searchTypeSelected,
    provinceSelected,
    setProvinceSelected,
    districtSelected,
    setDistrictSelected,
    subDistrictSelected,
    setSubDistrictSelected,
    departmentParentSelected,
    setDepartmentParentSelected,
    departmentGroupSelected,
    setDepartmentGroupSelected,
    searchQuery,
    setSearchQuery,
    shouldShowResult,
    setShouldShowResult,
    searchResults,
    setSearchResults,
    handleSearchResultClick,
    resetMap,
    resetContext,
  } = useContext(MainPageContext);
  const {
    searchSubjects,
    provinces,
    districts,
    subDistricts,
    departmentParents,
    departmentGroups,
    setProvinceCode,
    setDistrictCode,
  } = useContext(MasterDataContext);

  const [form] = Form.useForm();

  const [suggestions, setSuggestions] = useState<string[]>([]);

  const genQueryString = (): { [key: string]: string } => {
    if (!searchType) return {};

    // prepare query string
    const filterOptional: { [key: string]: string } = {};
    if (searchType.search_field_prov && searchType.search_field_prov != '')
      filterOptional['search_field_prov'] = provinceSelected.value.toString();
    if (searchType.search_field_amp && searchType.search_field_amp != '')
      filterOptional['search_field_amp'] = districtSelected.value.toString();
    if (searchType.search_field_tam && searchType.search_field_tam != '')
      filterOptional['search_field_tam'] = subDistrictSelected.value.toString();
    if (searchType.search_field_frm && searchType.search_field_frm != '')
      filterOptional['search_field_frm'] = departmentParentSelected.value.toString();
    if (searchType.search_field_org && searchType.search_field_org != '')
      filterOptional['search_field_org'] = departmentGroupSelected.value.toString();

    return filterOptional;
  };

  //#region Filter Options
  const [openOption, setOpenOption] = useState(true);
  const optionProvinces = useMemo(() => {
    const options: IOption[] = [defaultOption];
    const op = provinces?.map(({ code, name }) => {
      return { value: code, label: name };
    });
    return [...options, ...op] as IOption[];
  }, [provinces]);
  const optionDistricts = useMemo(() => {
    const options: IOption[] = [defaultOption];
    const op = districts?.map(({ code, name }) => {
      return { value: code, label: name };
    });
    return [...options, ...op] as IOption[];
  }, [districts]);
  const optionSubDistricts = useMemo(() => {
    const options: IOption[] = [defaultOption];
    const op = subDistricts?.map(({ code, name }) => {
      return { value: code, label: name };
    });
    return [...options, ...op] as IOption[];
  }, [subDistricts]);
  const optionDepartmentParents = useMemo(() => {
    const options: IOption[] = [defaultOption];
    const op = departmentParents?.map(({ code, name }) => {
      return { value: code, label: name };
    });
    return [...options, ...op] as IOption[];
  }, [departmentParents]);
  const optionDepartmentGroups = useMemo(() => {
    const options: IOption[] = [defaultOption];
    const op = departmentGroups?.map(({ code, name }) => {
      return { value: code, label: name };
    });
    return [...options, ...op] as IOption[];
  }, [departmentGroups]);

  //#endregion

  //#region Handle Event Change
  const handleSearchTypeChange = (option: IOption) => {
    const searchTypeSelected = searchSubjects.find((subject) => subject.code == option.value);
    setSearchType(searchTypeSelected);
    // reset
    setSuggestions([]);
    setProvinceSelected(defaultOption);
    setDistrictSelected(defaultOption);
    setSubDistrictSelected(defaultOption);
    setDepartmentParentSelected(defaultOption);
    setDepartmentGroupSelected(defaultOption);
    setProvinceCode('');
  };

  const handleSearchTextChanged = async () => {
    if (!searchQuery || !searchType) return;

    // Suggestion
    if (searchQuery.length >= 3) {
      const filterOptions = genQueryString();
      const { suggests } = await apiSearch.fetchSuggest(
        searchType.code,
        searchQuery,
        filterOptions
      );
      setSuggestions(suggests);
    }
  };

  const handleFilterProvinceChange = (option: IOption) => {
    setProvinceCode(option?.value?.toString() || defaultOption.value.toString());
    setProvinceSelected(option);
    setDistrictSelected(defaultOption);
    setSubDistrictSelected(defaultOption);
  };

  const handleFilterDistrictChange = (option: IOption) => {
    setDistrictCode(option.value.toString());
    setDistrictSelected(option);
    setSubDistrictSelected(defaultOption);
  };

  const handleFilterSubDistrictChange = (option: IOption) => {
    setSubDistrictSelected(option);
  };

  const handleFilterDepartmentParentChange = (option: IOption) => {
    setDepartmentParentSelected(option);
  };

  const handleFilterDepartmentGroupChange = (option: IOption) => {
    setDepartmentGroupSelected(option);
  };

  const handleOnSubmit = async () => {
    if (!searchType) return;

    setShouldShowResult(false);
    onSearch && onSearch();
    resetMap();

    // prepare query string
    const filterOptional = genQueryString();

    const results = await apiSearch.fetchQuery(searchType.code, searchQuery, filterOptional);
    setSearchResults(results);
    setShouldShowResult(true);
  };

  const handleOnReset = async () => {
    onCancel && onCancel();
    setShouldShowResult(false);
    setSearchQuery('');
    setProvinceCode('');
    setDistrictCode('');
    setProvinceSelected(defaultOption);
    setDistrictSelected(defaultOption);
    setSubDistrictSelected(defaultOption);
    setDepartmentParentSelected(defaultOption);
    setDepartmentGroupSelected(defaultOption);

    form.resetFields();
    resetContext();
  };
  //#endregion

  useEffect(() => {
    handleSearchTextChanged();
  }, [searchQuery]);

  // custom style
  return (
    <SContainer>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        layout={'horizontal'}
        size={'large'}
        labelWrap
      >
        <Form.Item label="ประเภทการค้นหา" initialValue="กรุณาเลือก">
          <Select
            labelInValue
            defaultValue={defaultOption}
            value={searchTypeSelected}
            onChange={handleSearchTypeChange}
          >
            {searchSubjects?.map(({ code, name }) => {
              return (
                <Option key={code} value={code} style={isMobile ? { fontSize: '4vw' } : {}}>
                  {name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="คำค้น">
          <AutoComplete
            disabled={
              subDistrictSelected.value || provinceSelected.value || districtSelected.value
                ? true
                : false
            }
            value={searchQuery}
            onSelect={(text: any) => setSearchQuery(text)}
            onChange={(text: any) => setSearchQuery(text)}
            placeholder={searchType?.place_holder}
            allowClear
          >
            {suggestions.map((e, i) => {
              return (
                <Option key={i} value={e} style={isMobile ? { fontSize: '4w' } : {}}>
                  {e}
                </Option>
              );
            })}
          </AutoComplete>
        </Form.Item>
        {openOption && searchType && searchType?.search_field_prov && (
          <Form.Item label="จังหวัด">
            <Select
              disabled={searchTypeSelected.value === 1 && searchQuery ? true : false}
              labelInValue
              defaultValue={defaultOption}
              value={provinceSelected}
              showSearch
              filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
              onChange={handleFilterProvinceChange}
            >
              {optionProvinces?.map(({ value, label }) => {
                return (
                  <Option key={value} value={value} style={isMobile ? { fontSize: '4w' } : {}}>
                    {label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        {/* filter district อำเภอ */}
        {openOption && searchType && searchType?.search_field_amp && (
          <Form.Item label="อำเภอ">
            <Select
              disabled={searchTypeSelected.value === 1 && searchQuery ? true : false}
              className={`${isMobile ? 'mobile' : ''}`}
              labelInValue
              defaultValue={defaultOption}
              value={districtSelected}
              showSearch
              filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
              onChange={handleFilterDistrictChange}
            >
              {optionDistricts?.map(({ value, label }) => {
                return (
                  <Option key={value} value={value} style={isMobile ? { fontSize: '4w' } : {}}>
                    {label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        {/* filter sub district ตำบล */}
        {openOption && searchType && searchType?.search_field_tam && (
          <Form.Item label="ตำบล">
            <Select
              disabled={searchTypeSelected.value === 1 && searchQuery ? true : false}
              className={`${isMobile ? 'mobile' : ''}`}
              labelInValue
              defaultValue={defaultOption}
              value={subDistrictSelected}
              showSearch
              filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
              onChange={handleFilterSubDistrictChange}
            >
              {optionSubDistricts?.map(({ value, label }) => {
                return (
                  <Option key={value} value={value} style={isMobile ? { fontSize: '4w' } : {}}>
                    {label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        {/* filter department parent สำนักจัดการ */}
        {openOption && searchType && searchType?.search_field_frm && (
          <Form.Item label="สำนักจัดการ">
            <Select
              className={`${isMobile ? 'mobile' : ''}`}
              labelInValue
              defaultValue={defaultOption}
              value={departmentParentSelected}
              showSearch
              filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
              onChange={handleFilterDepartmentParentChange}
            >
              {optionDepartmentParents?.map(({ value, label }) => {
                return (
                  <Option key={value} value={value} style={isMobile ? { fontSize: '4w' } : {}}>
                    {label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        {/* filter department group สังกัดหน่วยงาน */}
        {openOption && searchType && searchType?.search_field_frm && (
          <Form.Item label="สังกัดหน่วยงาน" initialValue="กรุณาเลือก">
            <Select
              className={`${isMobile ? 'mobile' : ''}`}
              labelInValue
              defaultValue={defaultOption}
              value={departmentGroupSelected}
              showSearch
              filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
              onChange={handleFilterDepartmentGroupChange}
            >
              {optionDepartmentGroups?.map(({ value, label }) => {
                return (
                  <Option key={value} value={value} style={isMobile ? { fontSize: '4w' } : {}}>
                    {label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        <SMoreOptions
          onClick={() => setOpenOption(!openOption)}
          className={`${isMobile ? 'mobile' : ''}`}
        >
          {openOption ? <MinusCircleOutlined /> : <PlusCircleOutlined />} <div>เงื่อนไขอื่น</div>
        </SMoreOptions>

        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          <Button
            type="primary"
            style={{
              marginRight: '0.75rem',
              background: '#336633',
            }}
            onClick={() => {
              handleOnSubmit();
            }}
          >
            ค้นหา
          </Button>
          <Button htmlType="button" onClick={handleOnReset}>
            ยกเลิก
          </Button>
        </Form.Item>

        {/* {ผลลัพธ์การค้นหา} */}
        {shouldShowResult && searchResults && (
          <div>
            <Result searchResults={searchResults} onResultClick={handleSearchResultClick} />
          </div>
        )}
      </Form>
    </SContainer>
  );
};

export default TabSearch;
const SContainer = styled.div`
  overflow-x: hidden;
`;

const SMoreOptions = styled.div`
  display: flex;
  margin-bottom: 30px;
  justify-content: flex-end;
  cursor: pointer;
  & > div {
    margin-left: 10px;
  }
  &.mobile {
    font-size: 4vw;
  }
`;
