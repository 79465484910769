import proj4 from 'proj4';
export const utmToLatLon = (
  easting: number,
  northing: number,
  zoneNum: number,
  northernHemisphere = true
) => {
  let latitude;
  let longitude;

  if (zoneNum === 47) {
    const utmZone47N = '+proj=utm +zone=47 +north +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    const wgs84 = 'EPSG:4326';
    const [lon, lat] = proj4(utmZone47N, wgs84, [easting, northing]);
    latitude = lat;
    longitude = lon;
  } else if (zoneNum === 48) {
    const UTM_ZONE_48N = '+proj=utm +zone=48 +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    const WGS84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
    const [lon, lat] = proj4(UTM_ZONE_48N, WGS84, [easting, northing]);
    latitude = lat;
    longitude = lon;
  }
  return { latitude, longitude };
};
