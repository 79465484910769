import React, { useContext, useMemo, useState } from 'react';

// import styled from 'styled-components/macro';
import { CloseOutlined, DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { TAB_SEARCH, TAB_SEARCH_THAI } from '../../interfaces/enums';
import TabForest from './TabForest.component';
import TabInstitution from './TabInstitution.component';
import TabMultiPurpose from './TabMultiPurpose.component';
import TabImportLayerData from './TabImportLayerData.component';
import TabExportLayerData from './TabExportLayerData.component';
import TabExportReport from './TabExportReport.component';
import TabSearch from './TabSearch.component';
import TabLayer from './TabLayer.component';
import styled from 'styled-components';
import { MainPageContext } from '../../contexts/MainPageContext';
interface IMainSearchProps {
  tabIndex?: TAB_SEARCH;
  setTabIndex: React.Dispatch<React.SetStateAction<TAB_SEARCH>>;
  isEmp: boolean;
  hiddenMainSearch: boolean;
  setHiddenMainSearch: React.Dispatch<React.SetStateAction<boolean>>;
}
interface ITabItems {
  label: string;
  key: TAB_SEARCH;
  children: JSX.Element;
  need_permission: boolean;
}
const MainSearch: React.FC<IMainSearchProps> = ({
  tabIndex = TAB_SEARCH.SEARCH,
  setTabIndex,
  isEmp,
  hiddenMainSearch,
  setHiddenMainSearch,
}) => {
  const { isMobile } = useContext(MainPageContext);
  const [shouldShowResult, setShouldShowResult] = useState<boolean>(false);
  const showResult = () => {
    setShouldShowResult(true);
  };
  const hideResult = () => {
    setShouldShowResult(false);
  };
  const tabItems: ITabItems[] = [
    {
      label: 'ค้นหาข้อมูล',
      key: TAB_SEARCH.SEARCH,
      children: (
        <TabSearch
          onSearch={showResult}
          onCancel={hideResult}
          // shouldShowResult={shouldShowResult}
        />
      ),
      need_permission: false,
    },

    {
      label: 'รายการชั้นข้อมูล',
      key: TAB_SEARCH.LAYER,
      children: <TabLayer onSearch={showResult} onCancel={hideResult} />,
      need_permission: false,
    },
    {
      label: 'ค้นหาข้อมูลป่าสงวนแห่งชาติ',
      key: TAB_SEARCH.FOREST,
      children: <TabForest onSearch={showResult} onCancel={hideResult} />,
      need_permission: true,
    },
    {
      label: 'ค้นหาที่ตั้งหน่วยงาน',
      key: TAB_SEARCH.INSTITUTION,
      children: <TabInstitution onSearch={showResult} onCancel={hideResult} />,
      need_permission: true,
    },
    {
      label: 'ค้นหาอเนกประสงค์',
      key: TAB_SEARCH.MULTI_PURPOSE,
      children: <TabMultiPurpose onSearch={showResult} onCancel={hideResult} />,
      need_permission: true,
    },
    {
      label: 'นำเข้าชั้นข้อมูล',
      key: TAB_SEARCH.IMPORT_LAYER_DATA,
      children: <TabImportLayerData />,
      need_permission: true,
    },
    {
      label: 'ส่งออกชั้นข้อมูล',
      key: TAB_SEARCH.EXPORT_LAYER_DATA,
      children: <TabExportLayerData />,
      need_permission: true,
    },
    {
      label: 'ส่งออกรายงาน',
      key: TAB_SEARCH.EXPORT_REPORT,
      children: <TabExportReport />,
      need_permission: true,
    },
  ];
  const renderResult = useMemo(() => {
    return tabItems.find((e) => e.key === tabIndex);
  }, [tabIndex]);

  const tabItemShow = useMemo(() => {
    return tabItems.filter((e) => e.need_permission === false);
  }, [isEmp]);
  return (
    <SContainer className={isMobile ? 'mobile' : ''}>
      <div className="menu">
        {tabIndex === TAB_SEARCH.SEARCH || tabIndex === TAB_SEARCH.LAYER ? (
          tabItemShow.map((e, i) => {
            return (
              <div
                className={`layerButton ${e.key === tabIndex ? 'selected' : ''}`}
                onClick={() => {
                  setTabIndex(e.key);
                }}
                key={i}
              >
                {e.label}
              </div>
            );
          })
        ) : (
          <h2>{TAB_SEARCH_THAI[tabIndex]}</h2>
        )}
      </div>
      <div className="content">{renderResult?.children}</div>
    </SContainer>
  );
};

export default MainSearch;
const SContainer = styled.div`
  padding: 20px;
  height: 100%;
  overflow: scroll;
  & > div {
    &.menu {
      overflow-x: scroll;
      display: flex;
      padding: 15px 0px;
      white-space: nowrap;
      &::-webkit-scrollbar {
        display: none;
      }
      & > div {
        &.layerButton {
          margin-right: 10px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid #a7782b;
          border-radius: 20px;
          padding: 0 15px;
          &.selected {
            color: white;
            background-color: rgba(167, 120, 43, 1);
          }
        }
      }
    }
  }
`;
