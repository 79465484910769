import React, { createContext, ReactNode, useEffect, useState } from 'react';
import apiLogin from '../apis/login';
import { IUserInformation } from '../interfaces/user.interface';

export interface IUserContext {
  userInformation: IUserInformation | undefined;
  setUserInformation: React.Dispatch<React.SetStateAction<IUserInformation | undefined>>;
  handleLoginGuest: () => void;
  keycloakInstance: Keycloak.KeycloakInstance;
}

export const UserContext = createContext<IUserContext>({
  userInformation: undefined,
  setUserInformation: () => {
    //
  },
  handleLoginGuest: () => {
    //
  },
  keycloakInstance: {} as Keycloak.KeycloakInstance,
});

interface IUserContextProvider {
  keycloakInstance: Keycloak.KeycloakInstance;
  children: ReactNode;
}
const UserContextProvider: React.FC<IUserContextProvider> = ({ keycloakInstance, children }) => {
  const [userInformation, setUserInformation] = useState<IUserInformation | undefined>(undefined);
  const handleLoginGuest = async () => {
    keycloakInstance.logout();
    // setUserInformation(undefined);
    const userGuest = await apiLogin.login({ username: 'guest', password: 'password' });
    setUserInformation(userGuest);
  };
  useEffect(() => {
    const userLocal = localStorage.getItem('userLogin') as string;
    if (userLocal) {
      setUserInformation(JSON.parse(userLocal));
    } else {
      handleLoginGuest();
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        userInformation,
        setUserInformation,
        handleLoginGuest,
        keycloakInstance,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
