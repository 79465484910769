import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-map-gl';
import styled from 'styled-components/macro';
import mapDarkIcon from '../../assets/images/dark.png';
import defaultIcon from '../../assets/images/street.png';
import mapStreetIcon from '../../assets/images/street.png';
import mapSatelliteIcon from '../../assets/images/satellite.jpeg';
import { MasterDataContext } from '../../contexts/MasterDataContext';
import { BASE_MAP_SERVICE_TYPE, MAPBOX_SCHEME } from '../../interfaces/enums';
import BaseMapStyle from './BaseMapStyle';
import { ConfigBaseMap } from '../../interfaces/map.interface';
import { MainPageContext } from '../../contexts/MainPageContext';
import { Modal } from 'antd';

// Default
const defaultBaseMaps: ConfigBaseMap[] = [
  {
    code: 'dark',
    name: `Dark`,
    url: 'mapbox://styles/mapbox/dark-v11',
    icon: mapDarkIcon,
    is_raster: false,
    base_map_type: BASE_MAP_SERVICE_TYPE.WMTS,
  },
  {
    code: 'street',
    name: `Street`,
    url: 'mapbox://styles/mapbox/streets-v12',
    icon: mapStreetIcon,
    is_raster: false,
    base_map_type: BASE_MAP_SERVICE_TYPE.WMTS,
  },
  {
    code: 'satellite',
    name: `Satellite`,
    url: 'mapbox://styles/mapbox/satellite-v9',
    icon: mapSatelliteIcon,
    is_raster: false,
    base_map_type: BASE_MAP_SERVICE_TYPE.WMTS,
  },
];

interface StyleSwitchControlProps {
  hiddenMainSearch?: boolean;
  selected?: string;
}

const StyleSwitchControl: React.FC<StyleSwitchControlProps> = ({
  hiddenMainSearch = false,
  selected,
}) => {
  const { masterBaseMaps } = useContext(MasterDataContext);
  const [baseMaps, setBaseMaps] = useState<ConfigBaseMap[]>([...defaultBaseMaps]);
  const { isMobile } = useContext(MainPageContext);

  useEffect(() => {
    if (!masterBaseMaps || masterBaseMaps?.length < 1) return;
    const masterBaseMapsConvert = masterBaseMaps.map((masterBaseMap): ConfigBaseMap => {
      const baseMap: ConfigBaseMap = {
        code: masterBaseMap.code,
        name: masterBaseMap.name,
        url: masterBaseMap.url,
        icon: masterBaseMap.icon,
        is_raster: masterBaseMap.is_raster,
        base_map_type: masterBaseMap.base_map_type,
        scheme: masterBaseMap.scheme || MAPBOX_SCHEME.XYZ,
        tile_size: masterBaseMap.tile_size || 256,
        attribution: masterBaseMap.attribution || '',
        min_zoom: masterBaseMap.min_zoom || 1,
        max_zoom: masterBaseMap.max_zoom || 22,
      };
      return baseMap;
    });
    setBaseMaps([...defaultBaseMaps, ...masterBaseMapsConvert]);
  }, [masterBaseMaps]);

  const [isHidden, setIsHidden] = useState(true);
  const [activeStyle, setActiveStyle] = useState<{ code: string | number; icon: string }>({
    code: 'dark',
    icon: '',
  });
  const renderMapStyleSelection = () => {
    if (isMobile) {
      return (
        <Modal
          open={true}
          footer=""
          onCancel={() => {
            setIsHidden(true);
          }}
        >
          {baseMaps && (
            <SMapStyleControlGroup className="mobile">
              <BaseMapStyle
                baseMaps={baseMaps}
                setActiveStyle={setActiveStyle}
                activeStyle={activeStyle}
              />
            </SMapStyleControlGroup>
          )}
        </Modal>
      );
    } else {
      return (
        <SMapStyleControlGroup>
          {baseMaps && (
            <BaseMapStyle
              baseMaps={baseMaps}
              setActiveStyle={setActiveStyle}
              activeStyle={activeStyle}
            />
          )}
        </SMapStyleControlGroup>
      );
    }
  };
  return (
    <>
      {baseMaps && (
        <SContainer
          className={`${
            isMobile ? 'mobile mapboxgl-ctrl-bottom-left' : 'mapboxgl-ctrl-bottom-left'
          } ${hiddenMainSearch ? '' : 'openSearch'}`}
        >
          <div className={`${isMobile ? 'mobile' : ''} mapboxgl-ctrl mapboxgl-ctrl-group`}>
            <SMapStyleControlButton
              className={`${isMobile ? 'mobile' : ''} ${isHidden ? '' : 'show'}`}
            >
              <SMapStyleIcon
                key={`map-default-selected`}
                onClick={(e) => {
                  setIsHidden(!isHidden);
                }}
                // isActive={activeStyle === baseMaps[0].code}
              >
                <SMapStyleIconImage className={isMobile ? 'mobile' : ''}>
                  <img src={activeStyle.icon || defaultIcon}></img>
                </SMapStyleIconImage>
                <SMapStyleIconText className={isMobile ? 'mobile' : ''}>
                  Select background
                </SMapStyleIconText>
              </SMapStyleIcon>
            </SMapStyleControlButton>
            {!isHidden && renderMapStyleSelection()}
          </div>
        </SContainer>
      )}
    </>
  );
};

export default StyleSwitchControl;
const SContainer = styled.div`
  &.mobile {
    width: fit-content;
    margin-bottom: 25px;
    &.openSearch {
      margin-bottom: 480px;
    }
  }
`;
const SMapStyleControlGroup = styled.div`
  position: absolute;
  bottom: 25px;
  left: 140px;
  width: 395px;
  height: fit-content;
  max-height: 50vh;
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(134, 163, 184, 0.7);
  border-radius: 0.25rem;
  padding: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  &.mobile {
    position: relative;
    height: fit-content;
    max-height: 50vh;
    width: 100%;
    left: 0;
    top: 0;
    background-color: white;
  }
`;

const SMapStyleControlButton = styled.div`
  &.mobile {
    position: relative;
    width: fit-content;
    bottom: 0px;
  }
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 0;
  bottom: 25px;
  &.show {
    width: auto;
  }

  background: #f2f2f2;
  background-color: rgba(134, 163, 184, 0.7);
  border-radius: 0.25rem;
`;

const SMapStyleIcon = styled.div<{ isActive?: boolean }>`
  /* border: 2px solid ${(props) => (props.isActive ? '#5B8FB9' : 'white')}; */
  display: flex;
  flex-direction: column;
  text-align: center;
  /* width: 130px; */

  & > img {
    border: ${(props) => (props.isActive ? '2px solid #BFEAF5' : 'none')};
  }

  & > label {
    color: ${(props) => (props.isActive ? '#BFEAF5' : '#000')};
    font-weight: ${(props) => (props.isActive ? 800 : 400)};
  }

  &:hover > img {
    cursor: pointer;
    border: 2px solid #bfeaf5;
  }

  &:hover > label {
    color: #bfeaf5;
    font-weight: 800;
  }
`;

const SMapStyleIconImage = styled.div`
  width: 120px;
  height: 80px;
  border-radius: 0.25rem;
  overflow: hidden;
  &.mobile {
    width: 30vw;
    height: auto;
  }
  & > img {
    height: 100%;
    width: 100%;
  }
`;

const SMapStyleIconText = styled.label`
  width: 120px;
  &.mobile {
    width: 30vw;
  }
`;
