import React, { useContext } from 'react';
import { ConfigBaseMap } from '../../interfaces/map.interface';
import styled from 'styled-components';
import defaultIcon from '../../assets/images/street.png';
import { BASE_MAP_SERVICE_TYPE, MAPBOX_SCHEME } from '../../interfaces/enums';
import { useMap } from 'react-map-gl';
import { MainPageContext } from '../../contexts/MainPageContext';
interface IBaseMapStyle {
  baseMaps: ConfigBaseMap[];
  setActiveStyle: React.Dispatch<
    React.SetStateAction<{
      code: string | number;
      icon: string;
    }>
  >;
  activeStyle: { code: string | number; icon: string };
}
const BaseMapStyle = ({ baseMaps, setActiveStyle, activeStyle }: IBaseMapStyle) => {
  const { current: map } = useMap();
  const { isMobile } = useContext(MainPageContext);
  const changeMapStyle = (baseMap: ConfigBaseMap) => {
    // console.log(
    //   `Detect base map style was changed to ${baseMap.base_map_type} ${baseMap.name}.`,
    //   baseMap
    // );

    if (!baseMap.is_raster) {
      map?.getMap()?.setStyle(baseMap.url);
      return;
    }

    let fixStyle: mapboxgl.Style;
    switch (baseMap.base_map_type) {
      case BASE_MAP_SERVICE_TYPE.WMTS:
      case BASE_MAP_SERVICE_TYPE.TMS:
        fixStyle = {
          version: 8,
          sources: {
            'raster-tiles': {
              type: 'raster',
              scheme: baseMap.scheme || MAPBOX_SCHEME.XYZ,
              tiles: [baseMap.url],
              tileSize: baseMap.tile_size,
              attribution: baseMap.attribution,
            },
          },
          layers: [
            {
              id: 'custom-tiles',
              type: 'raster',
              source: 'raster-tiles',
              minzoom: baseMap.min_zoom,
              maxzoom: baseMap.max_zoom,
            },
          ],
        };
        map?.getMap()?.setStyle(fixStyle);
        break;

      case BASE_MAP_SERVICE_TYPE.WMS:
        fixStyle = {
          version: 8,
          sources: {
            'raster-tiles': {
              type: 'raster',
              tiles: [
                // TODO: Mapbox WMS limitation need to specify only srs=EPSG:3857&bbox={bbox-epsg-3857}
                `${baseMap.url}&format=image/png&height=256&width=256&srs=EPSG:3857&bbox={bbox-epsg-3857}&transparent=true`,
              ],
              tileSize: baseMap?.tile_size || 256,
              attribution: baseMap?.attribution || '',
            },
          },
          layers: [
            {
              id: 'custom-tiles',
              type: 'raster',
              source: 'raster-tiles',
            },
          ],
        };
        map?.getMap()?.setStyle(fixStyle);
        break;

      case BASE_MAP_SERVICE_TYPE.WFS:
        fixStyle = {
          version: 8,
          sources: {
            'raster-tiles': {
              type: 'geojson',
              data: baseMap.url,
              maxzoom: baseMap.max_zoom,
              tolerance: 10,
            },
          },
          layers: [
            {
              id: 'wfs-polygon',
              type: 'fill',
              source: 'raster-tiles',
              paint: {
                // 'fill-outline-color': '#000000',
                'fill-color': '#B42222',
                'fill-opacity': 0.5,
              },
              filter: ['==', '$type', 'Polygon'],
            },
          ],
        };
        map?.getMap()?.setStyle(fixStyle);
        break;
      default:
        break;
    }
  };
  return (
    <>
      {baseMaps.map((baseMap) => {
        return (
          <SMapStyleIcon
            key={`${isMobile ? 'mobile' : ''} map-${baseMap.code}`}
            onClick={(e) => {
              setActiveStyle({ code: baseMap.code, icon: baseMap.icon });
              changeMapStyle(baseMap);
            }}
            isActive={activeStyle.code === baseMap.code}
            style={{ marginLeft: '5px' }}
          >
            <SMapStyleIconImage>
              <img src={baseMap.icon || defaultIcon} />
            </SMapStyleIconImage>
            <SMapStyleIconText>{baseMap.name}</SMapStyleIconText>
          </SMapStyleIcon>
        );
      })}
    </>
  );
};
export default BaseMapStyle;
const SMapStyleIconText = styled.label`
  width: 120px;
`;
const SMapStyleIconImage = styled.div`
  width: 120px;
  height: 80px;
  border-radius: 0.25rem;
  overflow: hidden;
  & > img {
    height: 100%;
    width: 100%;
  }
`;
const SMapStyleIcon = styled.div<{ isActive?: boolean }>`
  /* border: 2px solid ${(props) => (props.isActive ? '#5B8FB9' : 'white')}; */
  overflow: hidden;
  width: 40%;
  display: flex;
  flex-direction: column;
  text-align: center;
  /* width: 130px; */

  & > img {
    border: ${(props) => (props.isActive ? '2px solid #BFEAF5' : 'none')};
  }

  & > label {
    color: ${(props) => (props.isActive ? '#BFEAF5' : '#000')};
    font-weight: ${(props) => (props.isActive ? 800 : 400)};
  }

  &:hover > img {
    cursor: pointer;
    border: 2px solid #bfeaf5;
  }
  height: fit-content;
  &:hover > label {
    color: #bfeaf5;
    font-weight: 800;
  }
`;
