import React from 'react';

import { Table } from 'antd';
import { ForestAPI$SearchQueryResult } from '../../interfaces/forest_api.interface';
import ResultItem from './ResultItem.component ';

interface IResultSearchProps {
  searchResults: ForestAPI$SearchQueryResult[];
  onResultClick?: (feature: GeoJSON.Feature) => void;
}

const Result: React.FC<IResultSearchProps> = ({ searchResults, onResultClick }) => {
  const dataSource = searchResults?.map((item, index) => {
    const record = {
      key: index,
      id: item.id,
      name: item.name,
      display: <ResultItem name={`[${item.id}] - ${item.name}`} address={item.description} />,
      latlng: [],
      geometry: item.geojson_feature,
    };
    return record;
  });

  return (
    <Table
      className="search-result"
      dataSource={dataSource}
      scroll={{ y: 240 }}
      columns={[
        {
          title: <h1 style={{ fontSize: '1.5em' }}>ผลลัพธ์การค้นหา</h1>,
          dataIndex: 'display',
          key: 'name',
        },
      ]}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            console.log(`record : `, record.geometry);
            if (!record?.geometry?.geometry.type) {
              console.warn(`The ${record.name} can not find field geometry!`, record);
            } else {
              onResultClick && onResultClick(record.geometry);
            }
          }, // click row
          // onDoubleClick: (event) => {}, // double click row
          // onContextMenu: (event) => {}, // right button click row
          // onMouseEnter: (event) => {}, // mouse enter row
          // onMouseLeave: (event) => {}, // mouse leave row
        };
      }}
    />
  );
};

export default Result;
