import { useControl } from 'react-map-gl';
import '../../libs/mapbox-gl-export-custom/css/styles.css';
import {
  DPI,
  Format,
  MapboxExportControl,
  PageOrientation,
  Size,
} from '../../libs/mapbox-gl-export-custom/lib';

const PrintControl = ({ position, setSelectedCustomTool }: any) => {
  useControl(
    () =>
      new MapboxExportControl({
        accessToken: process.env.REACT_APP_MAPBOX_KEY,
        PageSize: Size.A4,
        PageOrientation: PageOrientation.Portrait,
        Format: Format.PDF,
        DPI: DPI[72],
        Crosshair: false,
        PrintableArea: true,
        setSelectedCustomTool: setSelectedCustomTool,
      }),
    {
      position: position,
    }
  );

  return null;
};

export default PrintControl;
