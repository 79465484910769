/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form, Slider, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import styled from 'styled-components';
import { ReactComponent as Legend } from '../../assets/icons/legend.svg';
import { MainPageContext } from '../../contexts/MainPageContext';
import { MasterDataContext } from '../../contexts/MasterDataContext';
import { IMasterLayer, IMasterLayerCheckboxGroup } from '../../interfaces/search.interface';

interface ITabLayerProps {
  onSearch?: () => any;
  onCancel?: () => any;
}

const TabLayer: React.FC<ITabLayerProps> = ({ onSearch, onCancel }) => {
  const [form] = Form.useForm();
  const { isMobile, checkedLayers, setCheckedLayers, layerSelected, setLayerSelected } =
    useContext(MainPageContext);
  const { masterLayer } = useContext(MasterDataContext);

  return (
    <SContainer>
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form}>
        {masterLayer &&
          masterLayer.map((e) => {
            return (
              <div key={`group_${e.layer_type_id}`}>
                <SHeader
                  key={`group_header_${e.layer_type_id}`}
                  style={{ fontSize: isMobile ? '3.5vw' : '14px' }}
                >
                  {e.layer_type_name}
                </SHeader>
                <SCheckboxGroup
                  key={`checkbox_group_${e.layer_type_id}`}
                  value={
                    checkedLayers.find((layer) => layer.id === `checkbox_group_${e.layer_type_id}`)
                      ?.value
                  }
                  onChange={(checkedValues: CheckboxValueType[]) => {
                    const checkboxGroupId = `checkbox_group_${e.layer_type_id}`;
                    const item: IMasterLayerCheckboxGroup = {
                      id: checkboxGroupId,
                      value: checkedValues,
                    };
                    const otherCheckboxGroups = checkedLayers.filter(
                      (item) => item.id != checkboxGroupId
                    );
                    console.log(`on SCheckboxGroup changed `, item);
                    setCheckedLayers([...otherCheckboxGroups, item]);
                  }}
                >
                  {e.layers?.map((el, i: number) => {
                    const layerKey = `${e.layer_type_id}_${el.id}`;
                    const [masterLayerItem, setMasterLayerItem] = useState<IMasterLayer>();
                    const [isOpenDetail, setIsOpenDetail] = useState(false);
                    const [isOpenLegend, setIsOpenLegend] = useState(false);
                    const [opacity, setOpacity] = useState<number>(100);
                    const rowChecked = useMemo(() => {
                      const group = checkedLayers?.find(
                        (group) => group.id == `checkbox_group_${e.layer_type_id}`
                      );
                      if (!group) return true;

                      const isChecked = group?.value?.filter((key) => key === layerKey)?.length < 1;
                      return isChecked;
                    }, [checkedLayers]);

                    useEffect(() => {
                      if (!masterLayerItem) return;

                      const otherLayerSelects = layerSelected.filter(
                        (layer) => layer.layer_id !== el.id
                      );

                      if (masterLayerItem.checked) {
                        setLayerSelected([...otherLayerSelects, masterLayerItem]);
                      } else {
                        setLayerSelected(otherLayerSelects);
                      }
                    }, [masterLayerItem]);

                    return (
                      <div key={`div_checkbox_${layerKey}`}>
                        <SOptionContainer key={i}>
                          <Checkbox
                            key={layerKey}
                            value={layerKey}
                            style={{ fontSize: isMobile ? '3.5vw' : '14px' }}
                            onChange={(e: CheckboxChangeEvent) => {
                              const item: IMasterLayer = {
                                layer_id: el.id,
                                checked: e.target.checked,
                                layer_service_type: el.service_type,
                                layer_url: el.service_url,
                                opacity: opacity,
                              };
                              setMasterLayerItem(item);
                            }}
                          >
                            {el.name}
                          </Checkbox>
                          <SSliderContainer>
                            <InfoCircleOutlined
                              onClick={() => setIsOpenDetail(!isOpenDetail)}
                              style={{ fontSize: isMobile ? '3.5vw' : '14px' }}
                            />
                            <Legend
                              onClick={() => setIsOpenLegend(!isOpenLegend)}
                              style={{
                                width: isMobile ? '3.5vw' : '14px',
                                height: isMobile ? '3.5vw' : '14px',
                              }}
                            />
                            <Slider
                              className={isMobile ? 'mobile' : ''}
                              defaultValue={opacity}
                              disabled={rowChecked}
                              style={{ width: '150px' }}
                              trackStyle={{
                                backgroundColor: '#669966',
                                height: isMobile ? '10px' : '4px',
                              }}
                              onChange={(value: number) => {
                                setOpacity(value);
                                const item: IMasterLayer = {
                                  layer_id: el.id,
                                  checked: true,
                                  layer_service_type: el.service_type,
                                  layer_url: el.service_url,
                                  opacity: value,
                                };
                                setMasterLayerItem(item);
                              }}
                            />
                          </SSliderContainer>
                        </SOptionContainer>
                        {isOpenLegend && el?.legend && (
                          // <div style={{ fontSize: isMobile ? '40px' : '14px' }}>{el.legend}</div>
                          <SImage src={el.legend} />
                        )}
                        {isOpenDetail && el.detail && (
                          <Table
                            pagination={false}
                            columns={[
                              {
                                title: (
                                  <div style={{ fontSize: isMobile ? '3.5vw' : '14px' }}>
                                    รายการ
                                  </div>
                                ),
                                dataIndex: 'list',
                                key: 'list',

                                render: (text) => (
                                  <div style={{ fontSize: isMobile ? '3.5vw' : '14px' }}>
                                    {text}
                                  </div>
                                ),
                              },
                              {
                                title: (
                                  <div style={{ fontSize: isMobile ? '3.5vw' : '14px' }}>
                                    คำอธิบาย
                                  </div>
                                ),
                                dataIndex: 'detail',
                                key: 'detail',
                                render: (text) => (
                                  <div style={{ fontSize: isMobile ? '3.5vw' : '14px' }}>
                                    {text}
                                  </div>
                                ),
                              },
                            ]}
                            dataSource={[
                              { list: 'ชั้นข้อมูล', detail: el.detail.name },
                              { list: 'รายละเอียด', detail: el.detail.description },
                              { list: 'ข้อจำกัดการใช้งาน', detail: el.detail.limitation },
                              { list: 'แหล่งที่มา', detail: el.detail.source },
                            ]}
                          />
                        )}
                      </div>
                    );
                  })}
                </SCheckboxGroup>
              </div>
            );
          })}
      </Form>
    </SContainer>
  );
};

export default TabLayer;
const SContainer = styled.div``;
const SImage = styled.img`
  /* width: 100%; */
`;
const SSliderContainer = styled.div`
  display: flex;
  align-items: center;
  & > svg,
  span {
    margin-right: 5px;
  }
`;
const SOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  padding: 15px;
  & > label.ant-checkbox-group-item {
    margin-inline-start: 0;
  }
  & > label.ant-checkbox-wrapper {
    margin-inline-start: 0;
  }
`;
const SHeader = styled.div`
  background-color: rgba(102, 153, 102, 0.125);
  display: flex;
  /* justify-content: center; */
  padding: 0px 40px;
  align-items: center;
  color: #000000;
`;
