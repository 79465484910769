import React, { useContext, useEffect, useMemo, useState } from 'react';

import 'antd/dist/reset.css';

import { theme } from 'antd';
import HeaderComponent from '../components/Header.component';
import LoginModal from '../components/LoginModal.component';
import MapBox from '../components/MapBox.component';
import MainSearch from '../components/search/MainSearch.component';
import { MainPageContext } from '../contexts/MainPageContext';
import MapContextProvider from '../contexts/MapBoxContext';
import { TAB_SEARCH } from '../interfaces/enums';
import styled from 'styled-components';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import PrintExportTemplate from '../components/PrintExportTemplate';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const MainPage = () => {
  const navigate = useNavigate();
  const { userInformation } = useContext(UserContext);
  const isEmp = useMemo(() => {
    if (userInformation?.data?.role === 1) {
      return true;
    } else {
      return false;
    }
  }, [userInformation]);
  const { isMobile, resetMap, openPrintTemplateTable, setOpenPrintTemplateTable } =
    useContext(MainPageContext);
  const [hiddenMainSearch, setHiddenMainSearch] = useState(isMobile ? false : true);
  const [tabIndex, setTabIndex] = useState(TAB_SEARCH.SEARCH);
  const {
    token: { colorBgContainer, colorBgSpotlight },
  } = theme.useToken();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (!userInformation || userInformation.data?.role === 0) {
      navigate('/');
    } else if (userInformation.data?.role === 1) {
      navigate('/emp');
    } else if (userInformation.data?.role === 999) {
      navigate('/admin');
    }
  }, [userInformation]);
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }, [hiddenMainSearch]);
  return (
    <SContainer
      className={`${hiddenMainSearch ? 'hidden-main-search' : ''} ${isMobile ? 'mobile' : ''}`}
    >
      <LoginModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <div className="top">
        <HeaderComponent
          showModal={showModal}
          isEmp={isEmp}
          setTabIndex={setTabIndex}
          tabIndex={tabIndex}
          setHiddenMainSearch={setHiddenMainSearch}
        />
      </div>

      {isMobile ? (
        <div className="menu-mobile">
          <div className={`bar`}>
            <div
              className="btn-hidden"
              onClick={() => {
                setHiddenMainSearch(!hiddenMainSearch);
              }}
            >
              <div></div>
            </div>
          </div>
          {!hiddenMainSearch && (
            <div className="content">
              <MainSearch
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                isEmp={isEmp}
                hiddenMainSearch={hiddenMainSearch}
                setHiddenMainSearch={setHiddenMainSearch}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="menu">
          <div
            className={`hidden`}
            onClick={() => {
              setHiddenMainSearch(!hiddenMainSearch);
            }}
          >
            {hiddenMainSearch ? <CaretRightOutlined /> : <CaretLeftOutlined />}
          </div>
          <MainSearch
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            isEmp={isEmp}
            hiddenMainSearch={hiddenMainSearch}
            setHiddenMainSearch={setHiddenMainSearch}
          />
        </div>
      )}
      <MapContextProvider>
        <div className="map">
          <MapBox hiddenMainSearch={hiddenMainSearch} />
        </div>
      </MapContextProvider>
      {openPrintTemplateTable && (
        <PrintExportTemplate
          openPrintTemplateTable={openPrintTemplateTable}
          setOpenPrintTemplateTable={setOpenPrintTemplateTable}
        />
      )}
    </SContainer>
  );
};

export default MainPage;

const SContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  overflow: hidden;
  grid-template: 70px calc(100% - 70px) / 30% 1fr;
  position: relative;
  .mapboxgl-ctrl-zoom-in {
    width: 40px;
    height: 40px;
  }
  .mapboxgl-ctrl-zoom-out {
    width: 40px;
    height: 40px;
  }
  .mapboxgl-ctrl-compass {
    width: 40px;
    height: 40px;
  }
  .mapboxgl-ctrl-icon {
    width: 40px;
    height: 40px;
  }
  .mapboxgl-ctrl-geolocate {
    width: 40px;
    height: 40px;
  }
  &.hidden-main-search {
    grid-template: 70px calc(100% - 70px) / 0.1px 1fr;
  }
  &.mobile {
    font-size: 23px !important;
    grid-template: 70px 1fr / 1fr;
    position: relative;
    .menu-mobile {
      & > div.content {
        background-color: white;
      }
      min-height: 30px;
      max-height: 70%;
      position: absolute;
      width: 100vw;
      bottom: 0;
      z-index: 10;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: scroll;
      & > div.content {
        width: 100%;
      }
      & > div.bar {
        width: 100%;
        & > div.btn-hidden {
          width: 100%;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: white;
          border: 1px solid white;
          & > div {
            width: 20%;
            border-radius: 10px;
            height: 10px;
            background-color: #3d3c3c;
          }
        }
      }
    }
    .ant-btn {
      /* font-size: 40px; */
      width: fit-content;
      height: fit-content;
    }
    .ant-select-item-option-content {
      font-weight: 900;
    }
    .ant-form-item .ant-form-item-label > label {
      font-size: 4vw !important;
    }
    .ant-select-selector {
      font-size: 4vw !important;
    }
    font-size: 24px;
    & > div {
      &.top {
        background-color: #363;
      }
    }
  }
  & > div {
    &.top {
      grid-column-start: 1;
      grid-column-end: 3;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #696;
    }
    &.menu {
      position: relative;
      height: 100%;
      & > div.hidden {
        cursor: pointer;
        position: absolute;
        right: -30px;
        top: 250px;
        background: white;
        z-index: 20;
        width: 30px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px 20px 20px 0px;
      }
    }
  }
`;
