import { ZoomInOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components/macro';

interface IResultItemProps {
  name: string;
  address: string;
}

const ResultItem: React.FC<IResultItemProps> = ({ name, address }) => {
  return (
    <SResultItem className="search-result-item" style={{ cursor: 'pointer' }}>
      <div>
        <ZoomInOutlined
          style={{ fontSize: '28px', fontWeight: 'bold', padding: '0.5rem', marginRight: '0.5rem' }}
        />
      </div>
      <div>
        <SResultItemName>{name}</SResultItemName>
        <SResultItemAddress>{address}</SResultItemAddress>
      </div>
    </SResultItem>
  );
};

export default ResultItem;

const SResultItem = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem;
`;
const SResultItemName = styled.div``;
const SResultItemAddress = styled.div``;
