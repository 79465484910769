import axios from 'axios';

interface IGetRoute {
  origin: string;
  destination: string;
}
const mapboxToken = `${process.env.REACT_APP_MAPBOX_KEY}` || '';
const getRoute = async ({ origin, destination }: IGetRoute) => {
  try {
    const result = await axios.get(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${origin};${destination}?geometries=geojson&access_token=${mapboxToken}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};
interface IGetPlaceSuggestion {
  query: string;
}
const getPlaceSuggestion = async ({ query }: IGetPlaceSuggestion) => {
  try {
    const result = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        query
      )}.json?access_token=${mapboxToken}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};
const apiMapbox = {
  getRoute,
  getPlaceSuggestion,
};
export default apiMapbox;
