import { PieChartOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Modal, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import styled from 'styled-components';
import { IHistoryReportGuestRecordType } from '../../interfaces/admin_menu.interface';
import { HISTORY_REPORT_TYPE } from '../../interfaces/enums';
import { rawDataEmployee, rawDataGuest } from '../../mocks/report.mock';

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const { RangePicker } = DatePicker;

interface IHistoryReport {
  type: HISTORY_REPORT_TYPE;
}
const HistoryReport = ({ type = HISTORY_REPORT_TYPE.GUEST }: IHistoryReport) => {
  // History report for Guest
  const guestColumns: ColumnsType<IHistoryReportGuestRecordType> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: 'ประวัติการใช้งาน',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
    },
    {
      title: 'วันที่',
      dataIndex: 'eventDateTime',
      key: 'eventDateTime',
      render(value, record, index) {
        return record.eventDateTime.toISOString();
      },
    },
  ];
  const guestOptions = [
    {
      value: 'EVENT_001',
      label: 'ค้นหาข้อมูลเงื่อนไขคำ',
    },
    {
      value: 'EVENT_002',
      label: 'ค้นหาข้อมูลเงื่อนไขจังหวัด',
    },
    {
      value: 'EVENT_003',
      label: 'ค้นหาข้อมูลเงื่อนไขปีที่ปลูก',
    },
  ];

  // End History report for Guest

  // History report for Employee
  const employeeColumns: ColumnsType<IHistoryReportGuestRecordType> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: 'ชื่อผู้ใช้งาน',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'ประวัติการใช้งาน',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
    },
    {
      title: 'วันที่',
      dataIndex: 'eventDateTime',
      key: 'eventDateTime',
      render(value, record, index) {
        return record.eventDateTime.toISOString();
      },
    },
  ];
  const employeeOptions = [
    {
      value: 'EVENT_004',
      label: 'เข้าสู่ระบบ',
    },
    {
      value: 'EVENT_005',
      label: 'ค้นหาข้อมูล',
    },
    {
      value: 'EVENT_006',
      label: 'เข้าสู่ระบบ',
    },
    {
      value: 'EVENT_007',
      label: 'ส่งออกชั้นข้อมูล',
    },
    {
      value: 'EVENT_008',
      label: 'ส่งออกรายงาน',
    },
    {
      value: 'EVENT_009',
      label: 'จัดการผู้ใช้งาน',
    },
    {
      value: 'EVENT_010',
      label: 'จัดการกลุ่มผู้ใช้งาน',
    },
  ];
  // End History report for Employee

  const columns = type === HISTORY_REPORT_TYPE.GUEST ? guestColumns : employeeColumns;
  const options = type === HISTORY_REPORT_TYPE.GUEST ? guestOptions : employeeOptions;

  const dataSources =
    type === HISTORY_REPORT_TYPE.GUEST
      ? rawDataGuest?.map((item) => {
          return {
            key: item.no,
            no: item.no,
            description: item.description,
            ip: item.ip,
            eventDateTime: item.eventDateTime,
          };
        })
      : rawDataEmployee?.map((item) => {
          return {
            key: item.no,
            no: item.no,
            username: item.username,
            description: item.description,
            ip: item.ip,
            eventDateTime: item.eventDateTime,
          };
        });

  const data = {
    labels: options.map((item) => item.label),
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3].slice(0, options.length),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const [form] = Form.useForm();

  const onSearch = () => {
    console.log('search');
  };
  const onReset = () => {
    form.resetFields();
  };

  // Chart
  const [open, setOpen] = useState(false);

  return (
    <SContainer>
      <h2>ค้นหา</h2>
      <div>
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 8 }} form={form} layout="horizontal">
          <Form.Item label="ช่วงวันที่" valuePropName={'date'}>
            <RangePicker />
          </Form.Item>
          <Form.Item name="eventCode" label="ประวัติการใช้งาน" initialValue="เลือก">
            <Select
              allowClear
              options={options}
              onChange={(value) => {
                form.setFieldValue('eventCode', value);
              }}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
            <Button
              type="primary"
              style={{ marginRight: '0.75rem', background: 'green', color: 'white' }}
              onClick={onSearch}
            >
              ค้นหา
            </Button>
            <Button htmlType="button" onClick={onReset}>
              ยกเลิก
            </Button>
          </Form.Item>
        </Form>
      </div>

      <hr style={{ border: '1px solid #ddd' }} />
      <div>
        <Button
          type="dashed"
          style={{ marginBottom: '1rem', marginTop: '0.5rem' }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <PieChartOutlined /> วิเคราะห์สถิติ
        </Button>
        <Table columns={columns} dataSource={dataSources} />
      </div>
      <Modal
        title="ข้อมูลวิเคราะห์"
        centered
        open={open}
        okText="ตกลง"
        onOk={() => {
          setOpen(false);
        }}
        cancelText="ยกเลิก"
        onCancel={() => {
          setOpen(false);
        }}
        width={500}
      >
        <Pie data={data} />
      </Modal>
    </SContainer>
  );
};
export default HistoryReport;
const SContainer = styled.div`
  width: 100%;
`;
