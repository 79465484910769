import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { ICustomMapService, ICustomMapServiceLayer } from '../interfaces/map.interface';

export interface IMapBoxContext {
  customMapServices: ICustomMapService[];
  setCustomMapServices: Dispatch<SetStateAction<ICustomMapService[]>>;
  customMapServicesLayersSelected: ICustomMapServiceLayer[];
}

export const MapBoxContext = createContext<IMapBoxContext>({
  customMapServices: [],
  setCustomMapServices: useState,
  customMapServicesLayersSelected: [],

  // /* eslint-disable @typescript-eslint/no-unused-vars */
  // handleSearchResultClick: (feature) => {
  //   /**/
  // },
});

interface IMapBoxContextProvider {
  children: ReactNode;
}

const MapBoxContextProvider: React.FC<IMapBoxContextProvider> = ({ children }) => {
  const [customMapServices, setCustomMapServices] = useState<ICustomMapService[]>([]);
  const [customMapServicesLayersSelected, setCustomMapServicesLayersSelected] = useState<
    ICustomMapServiceLayer[]
  >([]);

  useEffect(() => {
    const layerSelected: ICustomMapServiceLayer[] = [];
    customMapServices.forEach((customMapService) => {
      customMapService.layers
        ?.filter(({ checked }) => checked)
        ?.forEach((layer) => {
          const customMapServiceLayerKey = `${customMapService.id}_${layer.id}`;
          const customMapServiceLayerSelected: ICustomMapServiceLayer = {
            id: customMapServiceLayerKey,
            name: layer.name,
            checked: layer.checked,
            layer_url: layer.layer_url,
            opacity: layer.opacity,
          };
          layerSelected.push(customMapServiceLayerSelected);
        });
    });
    setCustomMapServicesLayersSelected(layerSelected);
  }, [customMapServices]);

  return (
    <MapBoxContext.Provider
      value={{
        customMapServices,
        setCustomMapServices,
        customMapServicesLayersSelected,
      }}
    >
      {children}
    </MapBoxContext.Provider>
  );
};

export default MapBoxContextProvider;
