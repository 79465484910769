import { IUsers } from '../interfaces/admin_menu.interface';

export const users: { data: IUsers[] } = {
  data: [
    {
      id: '123122323234213',
      username: 'admin',
      information: 'Admin',
      password: 'admin',
      full_name: 'แอดมิน ลอง',
      status: 'admin',
      image: '',
      role: 999,
      email: 'admin@gmail.com',
      is_active: true,
    },
    {
      id: '123124213',
      username: 'a001',
      information: 'Employee',
      password: 'emp',
      full_name: 'พนักงาน ลอง',
      status: 'emp',
      image: '',
      role: 1,
      email: 'user@gmail.com',
      is_active: true,
    },
  ],
};
export const layerManage = {
  data: [
    { name: 'แปลงปลูกป่าภาครัฐ', importName: 'ทดสอบ', importDate: 1697261884000, status: true },
    { name: 'แปลงปลูกป่าเอกชน', importName: 'ทดสอบ', importDate: 1697261884000, status: true },
  ],
};
export const mapManage = {
  data: [
    { name: 'น.ส.3 (NS3)', importName: 'ทดสอบ', importDate: 1697261884000, status: true },
    { name: 'กรมแผนที่ทหาร NIMA', importName: 'ทดสอบ', importDate: 1697261884000, status: true },
  ],
};
