import React, { useContext, useEffect, useRef } from 'react';
import { Map } from 'mapbox-gl';
import { MainPageContext } from '../../contexts/MainPageContext';
interface InformationProps {
  map: Map | null;
}
const Information = ({ map }: InformationProps) => {
  const { isMobile } = useContext(MainPageContext);
  const containerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (map && containerRef.current) {
      const controlEl = document.createElement('div');
      const newContent = document.createTextNode(
        `เป็นข้อมูลที่ใช้สำหรับบริหารจัดการพื้นที่ ไม่สามารถใช้เป็นหลักฐานในการอ้างอิงทางกฏหมาย`
      );
      controlEl.appendChild(newContent);
      controlEl.style.backgroundColor = 'hsla(0,0%,100%,.75)';
      controlEl.style.position = 'absolute';
      controlEl.style.fontSize = isMobile ? '2vw' : '10px';
      controlEl.style.bottom = isMobile ? '75px' : '33px';
      controlEl.style.border = '1px solid black';
      controlEl.style.padding = '0px 10px';
      controlEl.style.right = isMobile ? '12px' : '310px';
      controlEl.style.width = isMobile ? '55%' : 'fit-content';
      controlEl.style.height = isMobile ? 'fit-content' : '20px';
      controlEl.style.lineHeight = '15px';
      controlEl.style.borderRadius = '0px';
      controlEl.style.display = 'flex';
      controlEl.style.alignItems = 'center';
      controlEl.style.cursor = 'pointer';

      containerRef.current.appendChild(controlEl);

      return () => {
        containerRef.current && containerRef.current.removeChild(controlEl);
      };
    }
  }, [map]);

  return <div ref={containerRef} />;
};
export default Information;
