import * as turf from '@turf/turf';
import { LngLatLike } from 'mapbox-gl';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useResize } from '../hooks/useResize';
import {
  ForestAPI$SearchQueryResult,
  ForestAPI$SearchSubject,
} from '../interfaces/forest_api.interface';
import { IHoverInfo } from '../interfaces/map.interface';
import { IMasterLayer, IMasterLayerCheckboxGroup, IOption } from '../interfaces/search.interface';
import { ColumnsType } from 'antd/es/table';

export const defaultOption: IOption = { value: '', label: 'กรุณาเลือก' };
export interface IMainPageContext {
  defaultLatlng: {
    longitude: number;
    latitude: number;
    zoom: number;
  };
  isMobile: boolean;
  viewState: {
    longitude: number;
    latitude: number;
    zoom: number;
  };
  setViewState: Dispatch<
    SetStateAction<{
      longitude: number;
      latitude: number;
      zoom: number;
    }>
  >;
  // Tab Search
  searchType: ForestAPI$SearchSubject | undefined;
  setSearchType: Dispatch<SetStateAction<ForestAPI$SearchSubject | undefined>>;
  searchTypeSelected: IOption;
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  provinceSelected: IOption;
  setProvinceSelected: Dispatch<SetStateAction<IOption>>;
  districtSelected: IOption;
  setDistrictSelected: Dispatch<SetStateAction<IOption>>;
  subDistrictSelected: IOption;
  setSubDistrictSelected: Dispatch<SetStateAction<IOption>>;
  departmentParentSelected: IOption;
  setDepartmentParentSelected: Dispatch<SetStateAction<IOption>>;
  departmentGroupSelected: IOption;
  setDepartmentGroupSelected: Dispatch<SetStateAction<IOption>>;
  shouldShowResult: boolean;
  setShouldShowResult: Dispatch<SetStateAction<boolean>>;
  searchResults: ForestAPI$SearchQueryResult[];
  setSearchResults: Dispatch<SetStateAction<ForestAPI$SearchQueryResult[]>>;
  handleSearchResultClick: (feature: GeoJSON.Feature) => void;

  // Tab Layer
  checkedLayers: IMasterLayerCheckboxGroup[];
  setCheckedLayers: Dispatch<SetStateAction<IMasterLayerCheckboxGroup[]>>;
  layerSelected: IMasterLayer[];
  setLayerSelected: Dispatch<SetStateAction<IMasterLayer[]>>;

  // Map
  map: mapboxgl.Map | null;
  setMap: Dispatch<SetStateAction<mapboxgl.Map | null>>;
  map1: mapboxgl.Map | null;
  setMap1: Dispatch<SetStateAction<mapboxgl.Map | null>>;
  focusCoordinates: GeoJSON.FeatureCollection;
  setFocusCoordinates: Dispatch<SetStateAction<GeoJSON.FeatureCollection>>;
  hoverInfo: IHoverInfo | undefined;
  setHoverInfo: Dispatch<SetStateAction<IHoverInfo | undefined>>;
  shouldShowPopup: boolean;
  setShouldShowPopup: Dispatch<SetStateAction<boolean>>;
  resetMap: () => void;
  resetContext: () => void;
  openPrintTemplateTable: IExportPrint | undefined;
  setOpenPrintTemplateTable: React.Dispatch<React.SetStateAction<IExportPrint | undefined>>;
  handleExportPrint: ({ type, province, startYear, endYear, table }: IExportPrint) => void;
  openCompareMap: boolean;
  setOpenCompareMap: React.Dispatch<React.SetStateAction<boolean>>;
}
interface IExportPrint {
  type: string;
  province: string;
  startYear: string;
  endYear: string;
  table: { data: any[]; column: ColumnsType<any> };
  district: string;
  subDistrict: string;
}
export const MainPageContext = createContext<IMainPageContext>({
  defaultLatlng: { longitude: 100.523186, latitude: 13.736717, zoom: 4 },
  isMobile: false,
  // Tab Search
  searchType: undefined,
  viewState: { longitude: 100.523186, latitude: 13.736717, zoom: 4 },
  setViewState: useState,
  setSearchType: useState,
  searchTypeSelected: defaultOption,
  provinceSelected: defaultOption,
  setProvinceSelected: useState,
  districtSelected: defaultOption,
  setDistrictSelected: useState,
  subDistrictSelected: defaultOption,
  setSubDistrictSelected: useState,
  departmentParentSelected: defaultOption,
  setDepartmentParentSelected: useState,
  departmentGroupSelected: defaultOption,
  setDepartmentGroupSelected: useState,
  searchQuery: '',
  setSearchQuery: useState,
  shouldShowResult: false,
  setShouldShowResult: useState,
  searchResults: [],
  setSearchResults: useState,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  handleSearchResultClick: (feature) => {
    /**/
  },

  // Tab Layer
  checkedLayers: [],
  setCheckedLayers: useState,
  layerSelected: [],
  setLayerSelected: useState,

  // Map
  map: null,
  setMap: useState,
  map1: null,
  setMap1: useState,
  focusCoordinates: {
    type: 'FeatureCollection',
    features: [],
  },
  setFocusCoordinates: useState,
  hoverInfo: undefined,
  setHoverInfo: useState,
  shouldShowPopup: false,
  setShouldShowPopup: useState,
  resetMap: () => {
    /**/
  },
  handleExportPrint: () => {
    //
  },
  resetContext: () => {
    /**/
  },
  openPrintTemplateTable: undefined,
  setOpenPrintTemplateTable: () => {
    //
  },
  openCompareMap: false,
  setOpenCompareMap: () => {
    //
  },
});

interface IMainPageContextProvider {
  children: ReactNode;
}

const MainPageContextProvider: React.FC<IMainPageContextProvider> = ({ children }) => {
  const isMobile = window.innerWidth <= 1200;
  console.log(window.innerWidth);
  const defaultLatlng = {
    longitude: 100.523186,
    latitude: 13.736717,
    zoom: 4,
  };
  const [viewState, setViewState] = useState({
    ...defaultLatlng,
  });
  //#region Tab Search
  const [searchType, setSearchType] = useState<ForestAPI$SearchSubject | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const searchTypeSelected: IOption = useMemo(() => {
    return !searchType ? defaultOption : { value: searchType?.code, label: searchType?.name };
  }, [searchType]);

  const [provinceSelected, setProvinceSelected] = useState<IOption>(defaultOption);
  const [districtSelected, setDistrictSelected] = useState<IOption>(defaultOption);
  const [subDistrictSelected, setSubDistrictSelected] = useState<IOption>(defaultOption);
  const [departmentParentSelected, setDepartmentParentSelected] = useState<IOption>(defaultOption);
  const [departmentGroupSelected, setDepartmentGroupSelected] = useState<IOption>(defaultOption);
  const [openCompareMap, setOpenCompareMap] = useState(false);
  const [shouldShowResult, setShouldShowResult] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<ForestAPI$SearchQueryResult[]>([]);
  //#endregion

  //#endregion Tab Master Layer
  const [checkedLayers, setCheckedLayers] = useState<IMasterLayerCheckboxGroup[]>([]);
  const [layerSelected, setLayerSelected] = useState<IMasterLayer[]>([]);

  //#endregion

  //#region Map
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [map1, setMap1] = useState<mapboxgl.Map | null>(null);
  const [hoverInfo, setHoverInfo] = useState<IHoverInfo | undefined>(undefined);
  const [shouldShowPopup, setShouldShowPopup] = useState<boolean>(false);
  const [focusCoordinates, setFocusCoordinates] = useState<GeoJSON.FeatureCollection>({
    type: 'FeatureCollection',
    features: [],
  });
  const [openPrintTemplateTable, setOpenPrintTemplateTable] = useState<IExportPrint | undefined>(
    undefined
  );
  const handleExportPrint = ({
    type,
    province,
    startYear,
    endYear,
    table,
    district,
    subDistrict,
  }: IExportPrint) => {
    setOpenPrintTemplateTable({ type, province, startYear, endYear, table, district, subDistrict });
  };
  // After click on search result, map will pan to object
  useEffect(() => {
    if (map) {
      const geom = turf.getGeom(focusCoordinates as any);
      const bbox = turf.bbox(geom);
      const bboxPolygon = turf.bboxPolygon(bbox);
      const option: mapboxgl.FitBoundsOptions = { duration: 2000 };
      if (focusCoordinates.features[0].geometry.type === 'Point') {
        if (focusCoordinates.features.length === 1) {
          console.log(`Pan to `, focusCoordinates.features);
          option.zoom = 12;
          option.center = (focusCoordinates.features[0].geometry.type === 'Point' && [
            focusCoordinates.features[0].geometry.coordinates[0],
            focusCoordinates.features[0].geometry.coordinates[1],
          ]) as LngLatLike;
        } else {
          option.padding = 150;
        }
      } else {
        option.padding = 30;
      }
      map.fitBounds(bboxPolygon.bbox as any, option);
    }
  }, [focusCoordinates]);

  // Resize by viewport
  const onResize = useCallback(() => {
    if (map) {
      map.resize();
    }
  }, [map]);
  useResize(onResize);

  useEffect(() => {
    map?.resize();
  }, [map]);

  // Resize by collapse menu
  useEffect(() => {
    setTimeout(() => {
      map?.resize();
    }, 0);
  }, [map]);
  //#endregion

  const handleSearchResultClick = (feature: GeoJSON.Feature) => {
    const generateGeoJson: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: [feature],
    };
    if (feature.geometry.type === 'Point') {
      const hoverInfo: IHoverInfo = {
        lng: feature.geometry.coordinates[0],
        lat: feature.geometry.coordinates[1],
        property: feature.properties,
      };
      setHoverInfo(hoverInfo);
      setShouldShowPopup(true);
    } else if (feature.geometry.type === 'MultiPolygon') {
      const hoverInfo: IHoverInfo = {
        lng: feature?.properties?.long,
        lat: feature?.properties?.lat,
        property: feature.properties,
      };
      setHoverInfo(hoverInfo);
      setShouldShowPopup(true);
    } else {
      setHoverInfo(undefined);
      setShouldShowPopup(false);
    }
    setFocusCoordinates(generateGeoJson);
  };

  useEffect(() => {
    if (searchResults.length < 1) {
      resetMap();
      return;
    }

    const generateGeoJson: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: searchResults.map((result) => result.geojson_feature),
    };
    setFocusCoordinates(generateGeoJson);
    setShouldShowPopup(false);
    setHoverInfo(undefined);
  }, [searchResults]);

  const resetMap = () => {
    setShouldShowPopup(false);
    setHoverInfo(undefined);

    if (!map) return;

    setViewState(defaultLatlng);
  };

  const resetContext = () => {
    console.log(`Reset context called`);
    setSearchResults([]);
    resetMap();
  };

  return (
    <MainPageContext.Provider
      value={{
        defaultLatlng,
        isMobile,
        // Tab Search
        searchType,
        setSearchType,
        searchTypeSelected,
        provinceSelected,
        setProvinceSelected,
        districtSelected,
        setDistrictSelected,
        subDistrictSelected,
        setSubDistrictSelected,
        departmentParentSelected,
        setDepartmentParentSelected,
        departmentGroupSelected,
        setDepartmentGroupSelected,
        searchQuery,
        viewState,
        setViewState,
        setSearchQuery,
        shouldShowResult,
        setShouldShowResult,

        searchResults,
        setSearchResults,
        handleSearchResultClick,
        openCompareMap,
        setOpenCompareMap,

        // Tab Layer
        checkedLayers,
        setCheckedLayers,
        layerSelected,
        setLayerSelected,

        // Map
        map,
        setMap,
        map1,
        setMap1,
        focusCoordinates,
        setFocusCoordinates,
        hoverInfo,
        setHoverInfo,
        shouldShowPopup,
        setShouldShowPopup,
        resetMap,
        resetContext,
        handleExportPrint,
        openPrintTemplateTable,
        setOpenPrintTemplateTable,
      }}
    >
      {children}
    </MainPageContext.Provider>
  );
};

export default MainPageContextProvider;
