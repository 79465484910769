import {
  ForestAPI$Response$DepartmentGroup,
  ForestAPI$Response$DepartmentParent,
  ForestAPI$Response$District,
  ForestAPI$Response$Districts,
  ForestAPI$Response$MasterBaseMaps,
  ForestAPI$Response$MasterLayers,
  ForestAPI$Response$Province,
  ForestAPI$Response$Provinces,
  ForestAPI$Response$SubDistricts,
} from '../interfaces/forest_api.interface';
import { instance } from './instance';

const fetchDepartmentParents = async (): Promise<ForestAPI$Response$DepartmentParent> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.get('/master/departments/parents', {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return { departments: [] };
  }
};

const fetchDepartmentGroups = async (): Promise<ForestAPI$Response$DepartmentGroup> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.get('/master/departments/groups', {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return { departments: [] };
  }
};

const fetchMasterLayers = async (): Promise<ForestAPI$Response$MasterLayers> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.get(`/master/layers`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return { master_layers: [] };
  }
};

const fetchMasterBaseMaps = async (): Promise<ForestAPI$Response$MasterBaseMaps> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.get(`/master/base/maps`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return { base_maps: [] };
  }
};

const fetchProvinces = async (): Promise<ForestAPI$Response$Provinces> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.get('/master/provinces', {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return { provinces: [] };
  }
};

const fetchProvince = async (provinceCode: string): Promise<ForestAPI$Response$Province> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    if (parseInt(provinceCode) <= 0) return { province: { code: '', name: '' } };

    const result = await instance.get(`/master/provinces/${provinceCode}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return { province: { code: '', name: '' } };
  }
};

const fetchDistricts = async (provinceCode: string): Promise<ForestAPI$Response$Districts> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    if (parseInt(provinceCode) <= 0) return { districts: [] };

    const result = await instance.get(`/master/provinces/${provinceCode}/districts`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return { districts: [] };
  }
};

const fetchDistrict = async (
  provinceCode: string,
  districtCode: string
): Promise<ForestAPI$Response$District> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.get(
      `/master/provinces/${provinceCode}/districts/${districtCode}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return result.data;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return { district: { code: '', name: '' } };
  }
};

const fetchSubDistricts = async (
  provinceCode: string,
  districtCode: string
): Promise<ForestAPI$Response$SubDistricts> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.get(
      `/master/provinces/${provinceCode}/districts/${districtCode}/subdistricts`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return result.data;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return { sub_districts: [] };
  }
};

const apiMasterData = {
  fetchDepartmentParents,
  fetchDepartmentGroups,
  fetchMasterLayers,
  fetchMasterBaseMaps,
  fetchProvinces,
  fetchProvince,
  fetchDistricts,
  fetchDistrict,
  fetchSubDistricts,
};

export default apiMasterData;
