import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  UserAddOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  IUserRecordType,
  IUsers,
  userRoleIdToRoleEnum,
  userRoleToRoleIdEnum,
} from '../../interfaces/admin_menu.interface';
import apiAdmin from '../../apis/admin';
const { confirm, info, error } = Modal;

// const showConfirmDelete = (record: IUserRecordType) => {
//   confirm({
//     title: 'ข้อความจากระบบ',
//     icon: <ExclamationCircleFilled />,
//     content: 'ยืนยันการลบข้อมูล',
//     okText: 'ตกลง',
//     cancelText: 'ยกเลิก',
//     onOk() {
//       console.log('OK');
//       info({ title: 'ข้อความจากระบบ', content: 'ลบข้อมูลเรียบร้อย' });
//     },
//     onCancel() {
//       console.log('Cancel');
//     },
//   });
// };
const UsersManagement = () => {
  const [isUserAvailable, setIsUserAvailable] = useState(false);
  const columns: ColumnsType<IUserRecordType> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: 'ชื่อผู้ใช้งาน',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'กลุ่มผู้ใช้งาน',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'ชื่อ - สกุล',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'อีเมล์',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'แก้ไข',
      dataIndex: 'edit',
      key: 'edit',
      onCell: (record: any, rowIndex) => {
        return {
          onClick: (e) => {
            const userIsActive = record.is_active === 'เปิดใช้งาน' ? true : false;
            const editUser: any = {
              ...record,
              isActive: userIsActive,
            };
            setIsUserAvailable(userIsActive);
            setEditUser(editUser);
            setIsCreate(false);
            setOpen(true);
          },
        };
      },
      render: () => {
        return (
          <div>
            <EditOutlined />
          </div>
        );
      },
    },
    // {
    //   title: 'ลบ',
    //   dataIndex: 'delete',
    //   key: 'delete',
    //   onCell: (record, rowIndex) => {
    //     return {
    //       onClick: (e) => {
    //         showConfirmDelete(record);
    //       },
    //     };
    //   },
    //   render: (_, record) => {
    //     return <DeleteOutlined style={{ cursor: 'pointer' }} />;
    //   },
    // },
    {
      title: 'สถานะ',
      dataIndex: 'is_active',
      key: 'is_active',
    },
  ];

  const [accounts, setAccounts] = useState<IUsers[]>();
  const [defaultAccounts, setDefaultAccounts] = useState<IUsers[]>();

  const handleFetchUser = async () => {
    const userList = await apiAdmin.fetchUserList();
    setAccounts(userList);
    setDefaultAccounts(userList);
  };
  useEffect(() => {
    handleFetchUser();
  }, []);

  const [form] = Form.useForm();

  const onSearch = () => {
    const filterUsername = form.getFieldValue('username');
    const filterRole =
      form.getFieldValue('role') == 'เลือก' ? undefined : form.getFieldValue('role');
    if (!filterUsername && !filterRole) return;

    const filterAccounts = defaultAccounts?.filter((account) => {
      if (filterUsername && filterRole) {
        return account.username.indexOf(filterUsername) >= 0 && account?.role == filterRole;
      } else if (filterUsername) {
        return account.username.indexOf(filterUsername);
      } else if (filterRole) {
        return account?.role == filterRole;
      } else {
        return true;
      }
    });

    setAccounts(filterAccounts);
  };
  const onReset = () => {
    setAccounts(defaultAccounts);
    form.resetFields();
  };

  // TODO - thapana should relate enums.USER_ROLE
  const optionRoles = [
    {
      value: 999,
      label: 'Administrator',
    },
    {
      value: 1,
      label: 'User',
    },
    {
      value: 0,
      label: 'Guest',
    },
  ];

  // Form Create or Edit
  const [modalForm] = Form.useForm();

  const [open, setOpen] = useState(false);
  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [editUser, setEditUser] = useState<IUsers>();
  const onModalFormOk = async () => {
    if (isCreate) {
      try {
        const userData = {
          username: modalForm.getFieldValue('username'),
          full_name: modalForm.getFieldValue('full_name'),
          email: modalForm.getFieldValue('email'),
          role: modalForm.getFieldValue('role').value,
          is_active: isUserAvailable,
        };
        console.log(userData);
        await apiAdmin.addUser(userData);
        await handleFetchUser();
        info({ title: 'ข้อความจากระบบ', content: 'ดำเนินการเรียบร้อย' });
      } catch (err: any) {
        error({
          title: 'ข้อความจากระบบ',
          content: `ไม่สามารถดำเนินงานได้(${err.response.data.message})`,
        });
      }
    } else {
      if (!editUser) return;
      try {
        const userData = {
          username: modalForm.getFieldValue('username') || editUser?.username || '',
          full_name: modalForm.getFieldValue('full_name') || editUser?.full_name || '',
          email: modalForm.getFieldValue('email') || editUser?.email || '',
          role:
            modalForm.getFieldValue('role') !== 'เลือก'
              ? modalForm.getFieldValue('role').value
              : userRoleToRoleIdEnum[editUser?.role] || 0,
          is_active: isUserAvailable,
        };
        await apiAdmin.updateUser({ userId: editUser.id, data: userData });
        await handleFetchUser();
        info({ title: 'ข้อความจากระบบ', content: 'ดำเนินการเรียบร้อย' });
      } catch (err: any) {
        error({
          title: 'ข้อความจากระบบ',
          content: `ไม่สามารถดำเนินงานได้(${err.response.data.message})`,
        });
      }
    }
    modalForm.resetFields();
    setOpen(false);
  };
  const onModalFormCancel = () => {
    modalForm.resetFields();
    setOpen(false);
  };
  return (
    <SContainer>
      <h2>ค้นหา</h2>
      <div>
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 8 }} form={form} layout="horizontal">
          <Form.Item name="username" label="ชื่อผู้ใช้งาน">
            <Input
              allowClear
              onChange={(e) => {
                form.setFieldValue('username', e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item name="role" label="กลุ่มผู้ใช้งาน" initialValue="เลือก">
            <Select
              allowClear
              options={optionRoles}
              onChange={(value) => {
                form.setFieldValue('role', value);
              }}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
            <Button
              type="primary"
              style={{ marginRight: '0.75rem', background: '#336633' }}
              onClick={onSearch}
            >
              ค้นหา
            </Button>
            <Button htmlType="button" onClick={onReset}>
              ยกเลิก
            </Button>
          </Form.Item>
        </Form>
      </div>

      <hr style={{ border: '1px solid #ddd' }} />
      <div>
        <Button
          type="dashed"
          style={{ marginBottom: '1rem', marginTop: '0.5rem' }}
          onClick={() => {
            setIsCreate(true);
            setOpen(true);
          }}
        >
          <UserAddOutlined /> เพิ่มข้อมูล
        </Button>
        {accounts?.length && (
          <Table
            columns={columns}
            dataSource={accounts?.map((e, i) => {
              return {
                id: e.id,
                key: i + 1,
                no: i + 1,
                username: e.username,
                role: userRoleIdToRoleEnum[e?.role],
                full_name: e.full_name,
                email: e.email,
                is_active: e.is_active ? 'เปิดใช้งาน' : 'ปิดใช้งาน',
              };
            })}
          />
        )}
      </div>
      {open && (
        <Modal
          title={isCreate ? 'เพิ่มข้อมูลผู้ใช้งาน' : 'แก้ไข'}
          centered
          open={open}
          okText={isCreate ? 'บันทึก' : 'แก้ไข'}
          onOk={onModalFormOk}
          cancelText={'ยกเลิก'}
          onCancel={onModalFormCancel}
          width={1000}
        >
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
            form={modalForm}
            layout="horizontal"
          >
            <Form.Item name="username" label="ชื่อผู้ใช้งาน">
              <Input
                allowClear
                defaultValue={isCreate ? '' : editUser?.username}
                onChange={(e) => {
                  modalForm.setFieldValue('username', e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name="email" label="อีเมล์">
              <Input
                allowClear
                defaultValue={isCreate ? '' : editUser?.email}
                onChange={(e) => {
                  modalForm.setFieldValue('email', e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name="full_name" label="ชื่อ - สกุล">
              <Input
                allowClear
                defaultValue={isCreate ? '' : editUser?.full_name}
                onChange={(e) => {
                  modalForm.setFieldValue('full_name', e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name="role" label="กลุ่มผู้ใช้งาน" initialValue="เลือก">
              <Select
                labelInValue
                allowClear
                options={optionRoles}
                defaultValue={
                  isCreate
                    ? optionRoles[0]
                    : {
                        label: editUser?.role,
                        value: userRoleToRoleIdEnum[editUser?.role || 'guest'],
                      }
                }
                onChange={(value) => {
                  modalForm.setFieldValue('role', value);
                }}
              />
            </Form.Item>
            <Form.Item name="is_active" label="เข้าใช้งานระบบ" valuePropName="checked">
              <Switch
                defaultChecked={isUserAvailable}
                onChange={(value) => {
                  setIsUserAvailable(value);
                  // modalForm.setFieldValue('is_active', value);
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </SContainer>
  );
};
export default UsersManagement;
const SContainer = styled.div`
  width: 100%;
`;
