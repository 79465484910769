import { BASE_MAP_SERVICE_TYPE } from '../interfaces/enums';
import { instance } from './instance';

const fetchUserList = async () => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.get('/users', { headers: { Authorization: `Bearer ${token}` } });
    return result.data.users;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    return false;
  }
};
interface IAddUser {
  username: string;
  full_name: string;
  email: string;
  role: number;
  is_active: boolean;
}
const addUser = async ({ username, full_name, email, role, is_active }: IAddUser) => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    await instance.post(
      '/users',
      { username, full_name, email, role, is_active },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return true;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    throw err;
  }
};
interface IUpdateUser {
  userId: string;
  data: IAddUser;
}
const updateUser = async ({ userId, data }: IUpdateUser) => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    await instance.put(`/users/${userId}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return true;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    throw err;
  }
};
const fetchBaseMapList = async () => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.get(`/basemaps`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data.base_maps;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return false;
  }
};
interface IAddBaseMap {
  name: string;
  url: string;
  icon: string;
  role: number;
  min_zoom: number;
  max_zoom: number;
  is_active: boolean;
  base_map_type: BASE_MAP_SERVICE_TYPE;
}
const addBaseMap = async ({
  name,
  url,
  icon,
  role,
  min_zoom,
  max_zoom,
  is_active,
  base_map_type,
}: IAddBaseMap) => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.post(
      `/basemaps`,
      { name, url, icon, role, min_zoom, max_zoom, is_active, base_map_type },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return true;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    throw err;
  }
};
interface IEditBaseMap {
  code: string;
  data: IAddBaseMap;
}
const editBaseMap = async ({ code, data }: IEditBaseMap) => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.put(`/basemaps/${code}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return true;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    throw err;
  }
};
const removeBaseMap = async (code: string) => {
  try {
    const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';

    const result = await instance.delete(`/basemaps/${code}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return true;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    throw err;
  }
};
const fetchLayerList = async () => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.get(`/layers`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data.layers;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return false;
  }
};
interface IAddLayer {
  name: string;
  service_type: string;
  service_url: string;
  export_file: string;
  layer_type_id: number;
  legend: string;
  is_active: boolean;
}
const addLayer = async ({
  name,
  service_type,
  service_url,
  export_file,
  layer_type_id,
  legend,
  is_active,
}: IAddLayer) => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.post(
      `/layers`,
      { name, service_type, service_url, export_file, layer_type_id, legend, is_active },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return true;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    throw err;
  }
};
interface IEditLayer {
  layerId: number;
  data: IAddLayer;
}
const editLayer = async ({ layerId, data }: IEditLayer) => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.put(`/layers/${layerId}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return true;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    throw err;
  }
};
const fetchLayerType = async () => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.get(`/layertypes`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data.layer_types;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return false;
  }
};
const apiAdmin = {
  fetchUserList,
  addUser,
  updateUser,
  fetchBaseMapList,
  addBaseMap,
  editBaseMap,
  removeBaseMap,
  fetchLayerList,
  addLayer,
  editLayer,
  fetchLayerType,
};
export default apiAdmin;
