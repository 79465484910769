/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';

import { Button, Form } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { MainPageContext } from '../../contexts/MainPageContext';

interface ITabImportLayerData {
  onSearch?: () => any;
  onCancel?: () => any;
}

const TabImportLayerData: React.FC<ITabImportLayerData> = ({ onSearch, onCancel }) => {
  const [form] = Form.useForm();
  const { isMobile } = useContext(MainPageContext);
  return (
    <Form labelCol={{ span: 1 }} wrapperCol={{ span: 1 }} form={form} layout="horizontal">
      <Form.Item
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: isMobile ? '60vw' : '20vw',
            height: isMobile ? '30vh' : '25vw',
            border: 'solid 1px green',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            borderRadius: '10px',
          }}
        >
          <Button
            type="primary"
            style={{
              width: isMobile ? '50vw' : '15vw',
              height: isMobile ? '50vw' : '15vw',
              backgroundColor: 'rgba(102, 153, 102, 0.5)',
            }}
            onClick={() => {
              window.open('https://geo.forest.go.th/#/ ', '_blank');
            }}
          >
            <ImportOutlined style={{ fontSize: '140px' }} rotate={270} />
          </Button>
          <div style={{ color: '#336633' }}>คลิกนำเข้าชั้นข้อมูล</div>
        </div>
      </Form.Item>
    </Form>
  );
};

export default TabImportLayerData;
