/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';

import { Button, Form, Input, Select } from 'antd';
import { optionNoticeYears } from '../../configs/search_choices';
import { MasterDataContext } from '../../contexts/MasterDataContext';

interface ITabForestProps {
  onSearch?: () => any;
  onCancel?: () => any;
}

const TabForest: React.FC<ITabForestProps> = ({ onSearch, onCancel }) => {
  const [form] = Form.useForm();
  const { optionProvinces } = useContext(MasterDataContext);
  const onSubmit = () => {
    onSearch && onSearch();
  };
  const onReset = () => {
    onCancel && onCancel();
    form.resetFields();
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      form={form}
      layout="horizontal"
      // initialValues={{ size: componentSize }}
      // onValuesChange={onFormLayoutChange}
      // size={componentSize as SizeType}
      // style={{ maxWidth: 600 }}
    >
      <Form.Item label="ปีที่ประกาศ">
        <Select
          labelInValue
          defaultValue={{ value: '-', label: 'กรุณาเลือก' }}
          // style={{ width: 120 }}
          // onChange={handleChange}
          options={optionNoticeYears}
        />
      </Form.Item>
      <Form.Item label="จังหวัด" initialValue="กรุณาเลือก">
        <Select
          labelInValue
          defaultValue={{ value: '-', label: 'กรุณาเลือก' }}
          // style={{ width: 120 }}
          // onChange={handleChange}
          options={optionProvinces}
        />
      </Form.Item>
      <Form.Item label="คำค้น">
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
        <Button
          type="primary"
          style={{ marginRight: '0.75rem', background: '#336633' }}
          onClick={onSubmit}
        >
          ค้นหา
        </Button>
        <Button htmlType="button" onClick={onReset}>
          ยกเลิก
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TabForest;
