export const optionNoticeYears = [
  {
    value: '2566',
    label: '2566',
  },
  {
    value: '2565',
    label: '2565',
  },
  {
    value: '2564',
    label: '2564',
  },
  {
    value: '2563',
    label: '2563',
  },
  {
    value: '2562',
    label: '2562',
  },
  {
    value: '2561',
    label: '2561',
  },
];

export const optionProvinces = [
  {
    value: 'กรุงเทพมหานคร',
    label: 'กรุงเทพมหานคร',
  },
  {
    value: 'เชียงใหม่',
    label: 'เชียงใหม่',
  },
  {
    value: 'อุตรดิตถ์',
    label: 'อุตรดิตถ์',
  },
  {
    value: 'ตาก',
    label: 'ตาก',
  },
  {
    value: 'สุโขทัย',
    label: 'สุโขทัย',
  },
  {
    value: 'พิษณุโลก',
    label: 'พิษณุโลก',
  },
];
export const optionDistrict = [
  {
    value: 'กงไกรลาศ',
    label: 'กงไกรลาศ',
  },
  {
    value: 'คูเมือง',
    label: 'คูเมือง',
  },
  {
    value: 'จังหาร',
    label: 'จังหาร',
  },
  {
    value: 'ชุมพลบุรี',
    label: 'ชุมพลบุรี',
  },
  {
    value: 'เชียงคำ',
    label: 'เชียงคำ',
  },
  {
    value: 'เชียงดาว',
    label: 'เชียงดาว',
  },
];
export const optionParish = [
  {
    value: 'เทศบาลตำบลสถาน',
    label: 'เทศบาลตำบลสถาน',
  },
  {
    value: 'เทศบาลตำบลบ้านแซว',
    label: 'เทศบาลตำบลบ้านแซว',
  },
  {
    value: 'เทศบาลตำบลป่าซาง',
    label: 'เทศบาลตำบลป่าซาง',
  },
  {
    value: 'เทศบาลตำบลจอมแจ้ง',
    label: 'เทศบาลตำบลจอมแจ้ง',
  },
  {
    value: 'เทศบาลตำบลแม่วาง',
    label: 'เทศบาลตำบลแม่วาง',
  },
  {
    value: 'เทศบาลตำบลปง',
    label: 'เทศบาลตำบลปง',
  },
];

export const optionOffices = [
  {
    value: 'สำนักจัดการทรัพยากรป่าไม้ที่ 10 (ราชบุรี)',
    label: 'สำนักจัดการทรัพยากรป่าไม้ที่ 10 (ราชบุรี)',
  },
];

export const optionUnderAgency = [
  {
    value: 'กรุงเทพมหานคร',
    label: 'กรุงเทพมหานคร',
  },
  {
    value: 'เชียงใหม่',
    label: 'เชียงใหม่',
  },
  {
    value: 'อุตรดิตถ์',
    label: 'อุตรดิตถ์',
  },
  {
    value: 'ตาก',
    label: 'ตาก',
  },
  {
    value: 'สุโขทัย',
    label: 'สุโขทัย',
  },
  {
    value: 'พิษณุโลก',
    label: 'พิษณุโลก',
  },
];

export const optionAgency = [
  {
    value: 'กรุงเทพมหานคร',
    label: 'กรุงเทพมหานคร',
  },
  {
    value: 'เชียงใหม่',
    label: 'เชียงใหม่',
  },
  {
    value: 'อุตรดิตถ์',
    label: 'อุตรดิตถ์',
  },
  {
    value: 'ตาก',
    label: 'ตาก',
  },
  {
    value: 'สุโขทัย',
    label: 'สุโขทัย',
  },
  {
    value: 'พิษณุโลก',
    label: 'พิษณุโลก',
  },
];

export const optionDataTypes = [
  {
    value: 'แปลงสวนป่ารัฐ',
    label: 'แปลงสวนป่ารัฐ',
  },
  {
    value: 'แปลงสวนป่าเอกชน',
    label: 'แปลงสวนป่าเอกชน',
  },
  {
    value: 'แปลงป่าชุมชน',
    label: 'แปลงป่าชุมชน',
  },
  {
    value: 'แปลงอนุญาตพื้นที่ใช้ประโยชน์ที่ดิน',
    label: 'แปลงอนุญาตพื้นที่ใช้ประโยชน์ที่ดิน',
  },
  {
    value: 'แนวเขตป่าสงวน',
    label: 'แนวเขตป่าสงวน',
  },
];

export const optionReportTypes = [
  {
    value: 'แปลงสวนป่ารัฐ',
    label: 'แปลงสวนป่ารัฐ',
  },
  {
    value: 'แปลงสวนป่าเอกชน',
    label: 'แปลงสวนป่าเอกชน',
  },
  {
    value: 'แปลงป่าชุมชน',
    label: 'แปลงป่าชุมชน',
  },
  {
    value: 'แปลงอนุญาตพื้นที่ใช้ประโยชน์ที่ดิน',
    label: 'แปลงอนุญาตพื้นที่ใช้ประโยชน์ที่ดิน',
  },
  {
    value: 'แนวเขตป่าสงวน',
    label: 'แนวเขตป่าสงวน',
  },
  {
    value: 'ที่ตั้งหน่วยงานของกรมป่าไม้',
    label: 'ที่ตั้งหน่วยงานของกรมป่าไม้',
  },
  {
    value: 'ที่ตั้งหน่วยป้องกันรักษาป่า',
    label: 'ที่ตั้งหน่วยป้องกันรักษาป่า',
  },
];
export const optionSeachType = [
  {
    value: 'ข้อมูลสถานที่สำคัญ',
    label: 'ข้อมูลสถานที่สำคัญ',
  },
  {
    value: 'ข้อมูลป่าสงวนแห่งชาติ',
    label: 'ข้อมูลป่าสงวนแห่งชาติ',
  },
  {
    value: 'ข้อมูลที่ตั้งหน่วยงาน',
    label: 'ข้อมูลที่ตั้งหน่วยงาน',
  },
];
