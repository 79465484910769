import {
  IHistoryReportEmployeeRecordType,
  IHistoryReportGuestRecordType,
} from '../interfaces/admin_menu.interface';

export const rawDataGuest: IHistoryReportGuestRecordType[] = [
  {
    no: 1,
    description: 'ค้นหาข้อมูลเงื่อนไข คำค้น ป่าเศรษฐกิจ',
    ip: '192.168.1.8',
    eventDateTime: new Date(2023, 4, 14, 11, 23, 12),
  },
  {
    no: 2,
    description: 'ค้นหาข้อมูลเงื่อนไข คำค้น เพชรบูรณ์',
    ip: '192.168.1.9',
    eventDateTime: new Date(2023, 4, 14, 11, 33, 12),
  },
  {
    no: 3,
    description: 'ค้นหาข้อมูลเงื่อนไข คำค้น ปีที่ปลูก พ.ศ. 2534 - 2540',
    ip: '192.168.1.2',
    eventDateTime: new Date(2023, 4, 14, 11, 53, 19),
  },
  {
    no: 4,
    description: 'ค้นหาข้อมูลเงื่อนไข คำค้น ป่าสัก',
    ip: '192.168.1.9',
    eventDateTime: new Date(2023, 4, 14, 11, 63, 43),
  },
  {
    no: 5,
    description: 'ค้นหาข้อมูลเงื่อนไข คำค้น กระบี่',
    ip: '192.168.1.2',
    eventDateTime: new Date(2023, 4, 15, 11, 13, 54),
  },
  {
    no: 6,
    description: 'ค้นหาข้อมูลเงื่อนไข คำค้น ป่าเศรษฐกิจ',
    ip: '192.168.1.8',
    eventDateTime: new Date(2023, 4, 14, 11, 23, 12),
  },
  {
    no: 7,
    description: 'ค้นหาข้อมูลเงื่อนไข คำค้น เพชรบูรณ์',
    ip: '192.168.1.9',
    eventDateTime: new Date(2023, 4, 14, 11, 33, 12),
  },
  {
    no: 8,
    description: 'ค้นหาข้อมูลเงื่อนไข คำค้น ปีที่ปลูก พ.ศ. 2534 - 2540',
    ip: '192.168.1.2',
    eventDateTime: new Date(2023, 4, 14, 11, 53, 19),
  },
];

export const rawDataEmployee: IHistoryReportEmployeeRecordType[] = [
  {
    no: 1,
    username: 'admin',
    description: 'เข้าสู่ระบบสำเร็จ',
    ip: '192.168.1.9',
    eventDateTime: new Date(2023, 4, 14, 11, 53, 19),
  },

  {
    no: 2,
    username: 'a001',
    description: 'เข้าสู่ระบบสำเร็จ',
    ip: '192.168.1.8',
    eventDateTime: new Date(2023, 4, 14, 11, 44, 19),
  },

  {
    no: 3,
    username: 'a001',
    description: 'นำเข้าชั้นข้อมูล',
    ip: '192.168.1.8',
    eventDateTime: new Date(2023, 4, 14, 11, 55, 19),
  },

  {
    no: 4,
    username: 'a001',
    description: 'นำเข้าชั้นข้อมูล',
    ip: '192.168.1.8',
    eventDateTime: new Date(2023, 4, 17, 22, 53, 19),
  },

  {
    no: 5,
    username: 'admin',
    description: 'จัดการผู้ใช้งาน',
    ip: '192.168.1.8',
    eventDateTime: new Date(2023, 4, 19, 22, 53, 19),
  },
];
