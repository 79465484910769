import React, { useEffect, useState } from 'react';
import { Map } from 'mapbox-gl';
import CustomControl from './index.custom';
import styled from 'styled-components';
import HandIcon from '../../assets/icons/hand.svg';

import { Form } from 'antd';
interface CustomControlProps {
  map: Map | null;
  selectedCustomTool: string;
  setSelectedCustomTool: React.Dispatch<React.SetStateAction<string>>;
}
const PanCustomControl = ({
  map,
  selectedCustomTool,
  setSelectedCustomTool,
}: CustomControlProps) => {
  const [form] = Form.useForm();
  const [openPopup, setOpenPopup] = useState(false);
  useEffect(() => {
    if (selectedCustomTool !== 'go_to_xy') {
      setOpenPopup(false);
    }
  }, [selectedCustomTool]);
  return (
    <SContainer>
      <CustomControl
        map={map}
        top={'490px'}
        svg={HandIcon}
        onClick={() => {
          setSelectedCustomTool('pan');
        }}
      />
    </SContainer>
  );
};

export default PanCustomControl;
const SContainer = styled.div`
  position: relative;
`;
const SPopupContainer = styled.div`
  position: absolute;
  min-width: 325px;
  top: 10px;
  right: 60px;
  background-color: white;
  border-radius: 5px;
  z-index: 400;
  & > div {
    padding: 0px 5px 0px 5px;
  }
`;
