/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';

import { Button, Form, Select } from 'antd';
import { optionAgency, optionOffices, optionUnderAgency } from '../../configs/search_choices';
import { MasterDataContext } from '../../contexts/MasterDataContext';

interface ITabInstitutionProps {
  onSearch?: () => any;
  onCancel?: () => any;
  officeName?: string;
  province?: string;
  underAgency?: string;
  agency?: string;
}

const TabInstitution: React.FC<ITabInstitutionProps> = ({
  onSearch,
  onCancel,
  officeName,
  province,
  underAgency,
  agency,
}) => {
  const [form] = Form.useForm();
  const { optionProvinces } = useContext(MasterDataContext);
  const onSubmit = () => {
    onSearch && onSearch();
  };
  const onReset = () => {
    onCancel && onCancel();
    form.resetFields();
  };

  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} form={form} layout="horizontal">
      <Form.Item label="สํานักจัดการ">
        <Select
          labelInValue
          defaultValue={{ value: '-', label: 'กรุณาเลือก' }}
          // onChange={handleChange}
          options={optionOffices}
        />
      </Form.Item>
      <Form.Item label="จังหวัด">
        <Select
          labelInValue
          defaultValue={{ value: '-', label: 'เลือกทั้งหมด' }}
          // style={{ width: 120 }}
          // onChange={handleChange}
          options={optionProvinces}
        />
      </Form.Item>
      <Form.Item label="สังกัดหน่วยงาน">
        <Select
          labelInValue
          defaultValue={{ value: '-', label: 'เลือกทั้งหมด' }}
          // style={{ width: 120 }}
          // onChange={handleChange}
          options={optionUnderAgency}
        />
      </Form.Item>
      <Form.Item label="หน่วยงาน">
        <Select
          labelInValue
          defaultValue={{ value: '-', label: 'เลือกทั้งหมด' }}
          // style={{ width: 120 }}
          // onChange={handleChange}
          options={optionAgency}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
        <Button
          type="primary"
          style={{ marginRight: '0.75rem', background: '#336633' }}
          onClick={onSubmit}
        >
          ค้นหา
        </Button>
        <Button htmlType="button" onClick={onReset}>
          ยกเลิก
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TabInstitution;
