import {
  ForestAPI$Response$SearchSubject,
  ForestAPI$Response$SearchSuggest,
  ForestAPI$SearchQueryResult,
  ForestAPI$SearchReportResult,
} from '../interfaces/forest_api.interface';
import { instance } from './instance';

const fetchSubjects = async (): Promise<ForestAPI$Response$SearchSubject> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.get('/search/subjects', {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    return { subjects: [] };
  }
};

const fetchSuggest = async (
  subjectCode: string,
  q: string,
  option?: { [key: string]: string }
): Promise<ForestAPI$Response$SearchSuggest> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    if (!subjectCode || !q) return { suggests: [] };

    let moreQueryString = '';
    if (option) {
      Object.keys(option).forEach((key) => {
        moreQueryString += `&${key}=${option[key]}`;
      });
    }

    const result = await instance.get(`search/${subjectCode}/suggest?q=${q}${moreQueryString}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return { suggests: [] };
  }
};

const fetchQuery = async (
  subjectCode: string,
  q: string | null | undefined,
  option?: { [key: string]: string }
): Promise<ForestAPI$SearchQueryResult[]> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    if (!subjectCode) return [];

    let moreQueryString = '';
    if (option) {
      Object.keys(option).forEach((key) => {
        moreQueryString += `&${key}=${option[key]}`;
      });
    }
    const result = await instance.get(`search/${subjectCode}/query?q=${q}${moreQueryString}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result?.data?.results as ForestAPI$SearchQueryResult[];
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    return [];
  }
};

const searchSubjectReport = async (
  subjectCode: string,
  option?: { [key: string]: string }
): Promise<ForestAPI$SearchReportResult> => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    if (!subjectCode)
      return {
        search_results: [],
        report_table: {
          columns: [],
          rows: [],
        },
      };

    let moreQueryString = '';
    if (option) {
      Object.keys(option).forEach((key) => {
        moreQueryString += `&${key}=${option[key]}`;
      });
    }
    const result = await instance.get(`search/${subjectCode}/report?${moreQueryString}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return result?.data?.results as ForestAPI$SearchReportResult;
  } catch (err: any) {
    const errorStatus = err.response.status;
    const unAuthorized = errorStatus === 401;
    if (unAuthorized) {
      localStorage.removeItem('userLogin');
      window.location.reload();
    }
    console.error(err);
    return {
      search_results: [],
      report_table: {
        columns: [],
        rows: [],
      },
    };
  }
};

const apiSearch = {
  fetchSubjects,
  fetchSuggest,
  fetchQuery,
  searchSubjectReport,
};

export default apiSearch;
