import React, { useEffect, useState } from 'react';
import { Map } from 'mapbox-gl';
import CustomControl from './index.custom';
import styled from 'styled-components';
import Tools from '../../assets/icons/tools.svg';
import HiddenTools from '../../assets/icons/tools-hide.svg';
interface CustomControlProps {
  map: Map | null;
  setIsHiddenTools: React.Dispatch<React.SetStateAction<boolean>>;
  isHiddenTools: boolean;
}
const HiddenCustomControl = ({ map, setIsHiddenTools, isHiddenTools }: CustomControlProps) => {
  return (
    <SContainer>
      <CustomControl
        map={map}
        top={'10px'}
        svg={isHiddenTools ? HiddenTools : Tools}
        onClick={() => {
          setIsHiddenTools((prev: boolean) => {
            return !prev;
          });
        }}
      />
    </SContainer>
  );
};

export default HiddenCustomControl;
const SContainer = styled.div`
  position: relative;
`;
