import React, { useEffect, useRef } from 'react';
import { Map } from 'mapbox-gl';
import ReactDOM from 'react-dom';

interface CustomControlProps {
  map: Map | null;
  top?: string;
  bottom?: string;
  icon?: any;
  svg?: any;
  onClick: () => void;
}

const CustomControl: React.FC<CustomControlProps> = ({
  map,
  top = '',
  icon,
  svg,
  onClick,
  bottom = '',
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (map && containerRef.current) {
      const controlEl = document.createElement('div');
      if (svg) {
        const iconEl = document.createElement('img');
        iconEl.src = svg;
        iconEl.style.width = '25px';
        iconEl.style.height = '25px';
        controlEl.appendChild(iconEl);
      } else {
        const iconel = React.createElement(icon, {
          // style: { width: `15px`, height: `15px` },
        });
        ReactDOM.render(iconel, controlEl);
      }
      controlEl.style.backgroundColor = 'white';
      controlEl.style.position = 'absolute';
      if (bottom) {
        controlEl.style.bottom = bottom;
      }
      if (top) {
        controlEl.style.top = top;
      }
      controlEl.style.right = '10px';
      controlEl.style.width = '40px';
      controlEl.style.height = '40px';
      controlEl.style.borderRadius = '5px';
      controlEl.style.display = 'flex';
      controlEl.style.justifyContent = 'center';
      controlEl.style.alignItems = 'center';
      controlEl.style.cursor = 'pointer';
      controlEl.addEventListener('click', onClick);
      containerRef.current.appendChild(controlEl);

      return () => {
        controlEl.addEventListener('click', onClick);
        containerRef.current && containerRef.current.removeChild(controlEl);
      };
    }
  }, [map, svg]);

  return <div ref={containerRef} />;
};

export default CustomControl;
