import React, { useContext, useEffect, useState } from 'react';
import { Map } from 'mapbox-gl';
import CustomControl from './index.custom';
import styled from 'styled-components';
import HomeIcon from '../../assets/icons/home.svg';
import { MainPageContext } from '../../contexts/MainPageContext';
interface HomeControlProps {
  map: Map | null;
  selectedCustomTool: string;
  setSelectedCustomTool: React.Dispatch<React.SetStateAction<string>>;
}
const HomeCustomControl = ({
  map,
  selectedCustomTool,
  setSelectedCustomTool,
}: HomeControlProps) => {
  const [openPopup, setOpenPopup] = useState(false);
  const { resetMap } = useContext(MainPageContext);
  useEffect(() => {
    if (selectedCustomTool !== 'add_map_service') {
      setOpenPopup(false);
    }
  }, [selectedCustomTool]);
  return (
    <SContainer>
      <CustomControl
        map={map}
        top={'290px'}
        svg={HomeIcon}
        onClick={() => {
          resetMap();
        }}
      />
    </SContainer>
  );
};

export default HomeCustomControl;
const SContainer = styled.div`
  position: relative;
`;
