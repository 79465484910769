import React, { useContext, useMemo, useState } from 'react';

import { Button, Form, Select } from 'antd';
import styled from 'styled-components';
import apiSearch from '../../apis/search';
import { optionNoticeYears } from '../../configs/search_choices';
import { MainPageContext, defaultOption } from '../../contexts/MainPageContext';
import { MasterDataContext } from '../../contexts/MasterDataContext';
import { IExportReportData } from '../../interfaces/map.interface';
import { IOption } from '../../interfaces/search.interface';
import MapGeneratorReport, {
  DPI,
  Format,
  Size,
  Unit,
} from '../../libs/mapbox-gl-export-custom/lib/map-generator-report';
import { delayMilliSeconds } from '../../utils/helper';

// antd
const { Option } = Select;
interface ITabExportReportProps {
  onSearch?: () => any;
  onCancel?: () => any;
}
const TabExportReport: React.FC<ITabExportReportProps> = ({ onSearch, onCancel }) => {
  const {
    map,
    isMobile,
    searchType,
    setSearchType,
    searchTypeSelected,
    provinceSelected,
    setProvinceSelected,
    districtSelected,
    setDistrictSelected,
    subDistrictSelected,
    setSubDistrictSelected,
    departmentParentSelected,
    setDepartmentParentSelected,
    departmentGroupSelected,
    setDepartmentGroupSelected,
    searchQuery,
    setSearchQuery,
    shouldShowResult,
    setShouldShowResult,
    searchResults,
    setSearchResults,
    handleSearchResultClick,
    resetMap,
    resetContext,
  } = useContext(MainPageContext);
  const {
    searchSubjects,
    provinces,
    districts,
    subDistricts,
    departmentParents,
    departmentGroups,
    setProvinceCode,
    setDistrictCode,
  } = useContext(MasterDataContext);

  const [form] = Form.useForm();

  // const [suggestions, setSuggestions] = useState<string[]>([]);

  const genQueryString = (): { [key: string]: string } => {
    if (!searchType) return {};

    // prepare query string
    const filterOptional: { [key: string]: string } = {};
    if (searchType.search_field_prov && searchType.search_field_prov != '')
      filterOptional['search_field_prov'] = provinceSelected.value.toString();
    if (searchType.search_field_amp && searchType.search_field_amp != '')
      filterOptional['search_field_amp'] = districtSelected.value.toString();
    if (searchType.search_field_tam && searchType.search_field_tam != '')
      filterOptional['search_field_tam'] = subDistrictSelected.value.toString();
    if (searchType.search_field_frm && searchType.search_field_frm != '')
      filterOptional['search_field_frm'] = departmentParentSelected.value.toString();
    if (searchType.search_field_org && searchType.search_field_org != '')
      filterOptional['search_field_org'] = departmentGroupSelected.value.toString();

    return filterOptional;
  };

  //#region Filter Options
  const [openOption, setOpenOption] = useState(true);
  const optionProvinces = useMemo(() => {
    const options: IOption[] = [defaultOption];
    const op = provinces?.map(({ code, name }) => {
      return { value: code, label: name };
    });
    return [...options, ...op] as IOption[];
  }, [provinces]);
  const optionDistricts = useMemo(() => {
    const options: IOption[] = [defaultOption];
    const op = districts?.map(({ code, name }) => {
      return { value: code, label: name };
    });
    return [...options, ...op] as IOption[];
  }, [districts]);
  const optionSubDistricts = useMemo(() => {
    const options: IOption[] = [defaultOption];
    const op = subDistricts?.map(({ code, name }) => {
      return { value: code, label: name };
    });
    return [...options, ...op] as IOption[];
  }, [subDistricts]);
  const optionDepartmentParents = useMemo(() => {
    const options: IOption[] = [defaultOption];
    const op = departmentParents?.map(({ code, name }) => {
      return { value: code, label: name };
    });
    return [...options, ...op] as IOption[];
  }, [departmentParents]);
  const optionDepartmentGroups = useMemo(() => {
    const options: IOption[] = [defaultOption];
    const op = departmentGroups?.map(({ code, name }) => {
      return { value: code, label: name };
    });
    return [...options, ...op] as IOption[];
  }, [departmentGroups]);

  //#endregion

  //#region Handle Event Change
  const handleSearchTypeChange = (option: IOption) => {
    const searchTypeSelected = searchSubjects.find((subject) => subject.code == option.value);
    setSearchType(searchTypeSelected);
    // reset
    // setSuggestions([]);
    setProvinceSelected(defaultOption);
    setDistrictSelected(defaultOption);
    setSubDistrictSelected(defaultOption);
    setDepartmentParentSelected(defaultOption);
    setDepartmentGroupSelected(defaultOption);
    setProvinceCode('');
  };

  // const handleSearchTextChanged = async () => {
  //   if (!searchQuery || !searchType) return;

  //   // Suggestion
  //   if (searchQuery.length >= 3) {
  //     const filterOptions = genQueryString();
  //     const { suggests } = await apiSearch.fetchSuggest(
  //       searchType.code,
  //       searchQuery,
  //       filterOptions
  //     );
  //     setSuggestions(suggests);
  //   }
  // };

  const handleFilterProvinceChange = (option: IOption) => {
    setProvinceCode(option?.value?.toString() || defaultOption.value.toString());
    setProvinceSelected(option);
    setDistrictSelected(defaultOption);
    setSubDistrictSelected(defaultOption);
  };

  const handleFilterDistrictChange = (option: IOption) => {
    setDistrictCode(option.value.toString());
    setDistrictSelected(option);
    setSubDistrictSelected(defaultOption);
  };

  const handleFilterSubDistrictChange = (option: IOption) => {
    setSubDistrictSelected(option);
  };

  const handleFilterDepartmentParentChange = (option: IOption) => {
    setDepartmentParentSelected(option);
  };

  const handleFilterDepartmentGroupChange = (option: IOption) => {
    setDepartmentGroupSelected(option);
  };

  const handleOnSubmit = async () => {
    if (!searchType || !map) return;

    setShouldShowResult(false);
    resetMap();

    // prepare query string
    const filterOptional = genQueryString();
    const result = await apiSearch.searchSubjectReport(searchType.code, filterOptional);
    setSearchResults(result.search_results);

    const rows = result.report_table.rows.map((row, i) => {
      return { no: i + 1, ...row };
    });

    if (result.report_table.rows.length < 1) {
      window.alert('ไม่พบข้อมูล');
      return;
    }

    const reportData: IExportReportData = {
      header: {
        title: searchTypeSelected.label,
        filter: {
          province_th: provinceSelected.value === '' ? 'ทั้งหมด' : provinceSelected.label,
          district_th: districtSelected.value === '' ? 'ทั้งหมด' : districtSelected.label,
          sub_district_th: subDistrictSelected.value === '' ? 'ทั้งหมด' : subDistrictSelected.label,
          year_start: '',
          year_end: '',
        },
      },
      content: {
        columns: ['ลำดับ', ...result.report_table.columns],
        rows: rows.map((row) => Object.values(row).map(String)),
      },
    };

    await delayMilliSeconds(3000);
    const mapGenerator = new MapGeneratorReport(
      map,
      Size.A4,
      DPI[72],
      Format.PDF,
      Unit.mm,
      process.env.REACT_APP_MAPBOX_KEY || '',
      reportData
    );
    await mapGenerator.generate();
  };

  const handleOnReset = async () => {
    onCancel && onCancel();
    setShouldShowResult(false);
    setSearchQuery('');
    setProvinceCode('');
    setDistrictCode('');
    setProvinceSelected(defaultOption);
    setDistrictSelected(defaultOption);
    setSubDistrictSelected(defaultOption);
    setDepartmentParentSelected(defaultOption);
    setDepartmentGroupSelected(defaultOption);

    form.resetFields();
    resetContext();
  };
  //#endregion

  const SButtonMobile = isMobile ? { fontSize: '3.5vw', height: 'fit-content' } : {};

  return (
    <SForm
      className={isMobile ? 'mobile' : ''}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 16 }}
      form={form}
      layout="horizontal"
      labelWrap
    >
      <Form.Item label="ประเภทข้อมูล" initialValue="กรุณาเลือก">
        <SSelect
          className={`${isMobile ? 'mobile' : ''}`}
          labelInValue
          defaultValue={defaultOption}
          value={searchTypeSelected}
          onChange={(e: any) => handleSearchTypeChange(e)}
        >
          {searchSubjects?.map(({ code, name }) => {
            return (
              <Option key={code} value={code} style={{ fontSize: isMobile ? '5vw' : '14px' }}>
                {name}
              </Option>
            );
          })}
        </SSelect>
      </Form.Item>
      {openOption && searchType && searchType?.search_field_prov && (
        <Form.Item label="จังหวัด">
          <SSelect
            className={`${isMobile ? 'mobile' : ''}`}
            labelInValue
            defaultValue={defaultOption}
            value={provinceSelected}
            showSearch
            filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
            onChange={(e: any) => handleFilterProvinceChange(e)}
          >
            {optionProvinces?.map(({ value, label }) => {
              return (
                <Option key={value} value={value} style={isMobile ? { fontSize: '5vw' } : {}}>
                  {label}
                </Option>
              );
            })}
          </SSelect>
        </Form.Item>
      )}
      {/* filter district อำเภอ */}
      {openOption && searchType && searchType?.search_field_amp && (
        <Form.Item label="อำเภอ">
          <SSelect
            className={`${isMobile ? 'mobile' : ''}`}
            labelInValue
            defaultValue={defaultOption}
            value={districtSelected}
            showSearch
            filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
            onChange={(e: any) => handleFilterDistrictChange(e)}
          >
            {optionDistricts?.map(({ value, label }) => {
              return (
                <Option key={value} value={value} style={isMobile ? { fontSize: '5vw' } : {}}>
                  {label}
                </Option>
              );
            })}
          </SSelect>
        </Form.Item>
      )}
      {/* filter sub district ตำบล */}
      {openOption && searchType && searchType?.search_field_tam && (
        <Form.Item label="ตำบล">
          <SSelect
            className={`${isMobile ? 'mobile' : ''}`}
            labelInValue
            defaultValue={defaultOption}
            value={subDistrictSelected}
            showSearch
            filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
            onChange={(e: any) => handleFilterSubDistrictChange(e)}
          >
            {optionSubDistricts?.map(({ value, label }) => {
              return (
                <Option key={value} value={value} style={isMobile ? { fontSize: '5vw' } : {}}>
                  {label}
                </Option>
              );
            })}
          </SSelect>
        </Form.Item>
      )}
      {/* filter department parent สำนักจัดการ */}
      {openOption && searchType && searchType?.search_field_frm && (
        <Form.Item label="สำนักจัดการ">
          <SSelect
            className={`${isMobile ? 'mobile' : ''}`}
            labelInValue
            defaultValue={defaultOption}
            value={departmentParentSelected}
            showSearch
            filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
            onChange={(e: any) => handleFilterDepartmentParentChange(e)}
          >
            {optionDepartmentParents?.map(({ value, label }) => {
              return (
                <Option key={value} value={value} style={isMobile ? { fontSize: '5vw' } : {}}>
                  {label}
                </Option>
              );
            })}
          </SSelect>
        </Form.Item>
      )}
      {/* filter department group สังกัดหน่วยงาน */}
      {openOption && searchType && searchType?.search_field_frm && (
        <Form.Item label="สังกัดหน่วยงาน" initialValue="กรุณาเลือก">
          <SSelect
            className={`${isMobile ? 'mobile' : ''}`}
            labelInValue
            defaultValue={defaultOption}
            value={departmentGroupSelected}
            showSearch
            filterOption={(input, option: any) => (option?.children ?? '').includes(input)}
            onChange={(e: any) => handleFilterDepartmentGroupChange(e)}
          >
            {optionDepartmentGroups?.map(({ value, label }) => {
              return (
                <Option key={value} value={value} style={isMobile ? { fontSize: '5vw' } : {}}>
                  {label}
                </Option>
              );
            })}
          </SSelect>
        </Form.Item>
      )}

      {/* TODO: thapana - dynamic with database */}
      <Form.Item label="ปีที่เริ่มปลูก">
        <SSelect
          className={`${isMobile ? 'mobile' : ''}`}
          labelInValue
          defaultValue={{ value: '-', label: 'กรุณาเลือก' }}
          onChange={(e: any) => form.setFieldValue('yearStart', e.value)}
        >
          {optionNoticeYears.map((e, i) => {
            return (
              <Option style={{ fontSize: isMobile ? '5vw' : '14px' }} key={i} value={e.value}>
                {e.value}
              </Option>
            );
          })}
        </SSelect>
      </Form.Item>
      <Form.Item label="ถึง">
        <SSelect
          className={`${isMobile ? 'mobile' : ''}`}
          labelInValue
          defaultValue={{ value: '-', label: 'กรุณาเลือก' }}
          onChange={(e: any) => form.setFieldValue('yearEnd', e.value)}
        >
          {optionNoticeYears.map((e, i) => {
            return (
              <Option style={{ fontSize: isMobile ? '5vw' : '14px' }} key={i} value={e.value}>
                {e.value}
              </Option>
            );
          })}
        </SSelect>
      </Form.Item>
      {/* <div style={{ marginBottom: '20px', fontSize: isMobile ? '4vw' : '' }}>
        รายละเอียดการพิมพ์
      </div> */}
      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
        <Button
          type="primary"
          style={{
            marginRight: '0.75rem',
            backgroundColor: 'rgba(102, 153, 102, 0.75)',
            ...SButtonMobile,
          }}
          onClick={handleOnSubmit}
        >
          ออกรายงาน
        </Button>
        <Button htmlType="button" onClick={handleOnReset} style={SButtonMobile}>
          ยกเลิก
        </Button>
      </Form.Item>
    </SForm>
  );
};

export default TabExportReport;
const SForm = styled(Form)`
  overflow-x: hidden;
`;
const SSelect = styled(Select)`
  &.mobile {
    height: 100%;
    display: flex;
    align-items: center;
    & > div {
      height: 50% !important;
      & > span {
        font-size: 3vw !important;
        display: flex;
        align-items: center;
      }
    }
  }
`;
