import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import logo from '../assets/images/forest-logo.png';
interface IPrintExportTemplate {
  openPrintTemplateTable: {
    type: string;
    province: string;
    startYear: string;
    endYear: string;
    district: string;
    subDistrict: string;
    table: { data: any[]; column: ColumnsType<any> };
  };
  setOpenPrintTemplateTable: React.Dispatch<React.SetStateAction<any | undefined>>;
}
const PrintExportTemplate = ({
  openPrintTemplateTable,
  setOpenPrintTemplateTable,
}: IPrintExportTemplate) => {
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    handlePrint();
    setOpenPrintTemplateTable(undefined);
  }, []);
  return (
    <SContainer ref={componentRef}>
      <div>
        <img style={{ width: '50%' }} src={logo} />
      </div>
      <div>รายงานสรุป</div>
      <div>{`ประเภทข้อมูล : ${
        openPrintTemplateTable.type || '....................................'
      }`}</div>
      <div>{`จังหวัด ${openPrintTemplateTable.province || '..............................'} อำเภอ ${
        openPrintTemplateTable.district || `.............`
      }ตำบล ${openPrintTemplateTable.subDistrict || '..........'}`}</div>
      <div>{`ปี พ.ศ.ที่เริ่มปลูก ${
        openPrintTemplateTable.startYear || '...............................'
      } ถึง ${openPrintTemplateTable.endYear || '.............................'} `}</div>
      <Table
        style={{ width: '90%' }}
        columns={openPrintTemplateTable.table.column}
        dataSource={openPrintTemplateTable.table.data}
        pagination={false}
        // dataSource={openPrintTemplateTable.table.data.map((e, i) => {
        //   return {
        //     no: i + 1,
        //     name: e.name,
        //     val: e.val,
        //     year: e.year,
        //   };
        // })}
      />
    </SContainer>
  );
};
export default PrintExportTemplate;
const SContainer = styled.div`
  .ant-table {
    font-size: 10px;
  }
  padding: 8% 10% 0 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: white;
  font-size: 18px;
  & > div {
    &:nth-child(1) {
      display: flex;
      justify-content: center;
    }
    line-height: 40px;
  }
`;
