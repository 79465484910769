import { Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import logo from '../assets/images/logo.png';
import AdminMenu from '../components/admin/menu.component';
import { IAdminMenu } from '../interfaces/admin_menu.interface';
import styled from 'styled-components';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
const { Header, Sider, Content } = Layout;
const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  height: 'fit-content',
  lineHeight: '64px',
  width: '100%;',
};
const AdminPage: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = useState<IAdminMenu>();
  const { userInformation } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!userInformation || userInformation.data?.role === 0) {
      navigate('/');
    } else if (userInformation.data?.role === 1) {
      navigate('/emp');
    } else if (userInformation.data?.role === 999) {
      navigate('/admin');
    }
  }, [userInformation]);
  return (
    <Layout style={{ minHeight: '100vh', width: '100vw' }}>
      <Header style={headerStyle}>
        <SHeaderLogo>
          <img alt="กรมป่าไม้" src={logo} />
          <div>ระบบภูมิสารสนเทศเพื่อการบริหาร กรมป่าไม้</div>
        </SHeaderLogo>
      </Header>
      <Layout>
        <Layout style={{ minWidth: '100%', backgroundColor: '#FFF' }}>
          <Sider
            width={250}
            style={{
              backgroundColor: '#FFF',
              padding: '1rem',
              paddingRight: '0',
              borderRight: '1px solid #ddd',
            }}
          >
            <AdminMenu selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
          </Sider>
          <Content
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              padding: '1rem',
            }}
          >
            {selectedMenu && selectedMenu?.component}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default AdminPage;
const SHeaderLogo = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  & > img {
    margin-right: 10px;
  }
  & > div {
    font-size: 20px;
  }
`;
