import React from 'react';
import UsersManagement from '../components/admin/UsersManagement.component';
import UsersController from '../components/admin/UsersController.component';
import { IAdminMenu } from '../interfaces/admin_menu.interface';
import {
  DatabaseOutlined,
  HeatMapOutlined,
  HistoryOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import HistoryReport from '../components/admin/HistoryReport.component';
import { HISTORY_REPORT_TYPE } from '../interfaces/enums';
import LayerManagement from '../components/admin/LayerManagement.component';
import MapManagement from '../components/admin/MapManagement.component';

const iconStyle: React.CSSProperties = {
  fontSize: '18px',
  paddingRight: '0.5rem',
};

const ListAdminMenu: IAdminMenu[] = [
  {
    id: 'users_controller',
    text: 'จัดการกลุ่มผู้ใช้งาน',
    clickable: true,
    component: <UsersController />,
    icon: <UsergroupAddOutlined style={iconStyle} />,
  },
  {
    id: 'user_controller',
    text: 'จัดการผู้ใช้งาน',
    clickable: true,
    component: <UsersManagement />,
    icon: <UserOutlined style={iconStyle} />,
  },
  {
    id: 'layer_controller',
    text: 'จัดการชั้นข้อมูล',
    clickable: true,
    component: <LayerManagement />,
    icon: <DatabaseOutlined style={iconStyle} />,
  },
  {
    id: 'map_controller',
    text: 'จัดการแผนที่ฐาน',
    clickable: true,
    component: <MapManagement />,
    icon: <HeatMapOutlined style={iconStyle} />,
  },
  {
    id: 'history',
    text: 'ประวัติการใช้งานระบบ',
    clickable: false,
    component: <div>coming soon</div>,
    icon: <HistoryOutlined style={iconStyle} />,
    subMenu: [
      {
        id: '1',
        text: 'ประชาชนทั่วไป',
        clickable: true,
        component: <HistoryReport type={HISTORY_REPORT_TYPE.GUEST} />,
      },
      {
        id: '2',
        text: 'เจ้าหน้าที่กรมป่าไม้',
        clickable: true,
        component: <HistoryReport type={HISTORY_REPORT_TYPE.EMPLOYEE} />,
      },
    ],
  },
];
const serviceType = [
  { value: 'WMS', label: 'WMS' },
  { value: 'WMTS', label: 'WMTS' },
  { value: 'WFS', label: 'WFS' },
];

const baseMapType = [{ value: 'TMS', label: 'TMS' }, ...serviceType];

export { ListAdminMenu, serviceType, baseMapType };
