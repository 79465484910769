/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';

import { Button, Form, Modal, Select } from 'antd';
import styled from 'styled-components';
import { MainPageContext } from '../../contexts/MainPageContext';
import { MasterDataContext } from '../../contexts/MasterDataContext';
import { ForestAPI$Layer } from '../../interfaces/forest_api.interface';

interface ITabImportLayerData {
  onSearch?: () => any;
  onCancel?: () => any;
}
const { Option } = Select;
const TabExportLayerData: React.FC<ITabImportLayerData> = ({ onSearch, onCancel }) => {
  const { isMobile } = useContext(MainPageContext);
  const [form] = Form.useForm();
  const { masterLayer } = useContext(MasterDataContext);
  const [openModal, setOpenModal] = useState(false);
  const SButtonMobile = isMobile ? { fontSize: '3.5vw', height: 'fit-content' } : {};
  const [dataTypeDropdown, setDataTypeDropdown] = useState<ForestAPI$Layer[]>([]);
  const [linkExport, setLinkExport] = useState<string>('');

  useEffect(() => {
    const result: ForestAPI$Layer[] = [];
    masterLayer.forEach((master) => {
      result.push(...master.layers);
    });
    setDataTypeDropdown(result);
  }, []);

  const handleChange = (value: { value: string; label: React.ReactNode }) => {
    const layer = dataTypeDropdown.find(
      (layer: ForestAPI$Layer) => layer.id.toString() == value.value
    );
    if (!layer?.export_file) console.warn('ไม่พบ Sharp file');

    setLinkExport(layer?.export_file || '');
  };

  return (
    <SForm labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} form={form} layout="horizontal">
      <Form.Item label="ประเภทข้อมูล">
        {dataTypeDropdown.length && (
          <Select
            labelInValue
            defaultValue={{
              value: dataTypeDropdown[0].id.toString(),
              label: dataTypeDropdown[0].name,
            }}
            onChange={handleChange}
          >
            {dataTypeDropdown.map((e: any, i) => {
              return (
                <Option style={{ fontSize: isMobile ? '5vw' : '14px' }} key={i} value={e.id}>
                  {e.name}
                </Option>
              );
            })}
          </Select>
        )}
      </Form.Item>
      <Form.Item
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type="primary"
          style={{
            // width: '200px',
            marginRight: '0.75rem',
            background: 'rgba(102, 153, 102, 0.5)',
            ...SButtonMobile,
          }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          ส่งออก Shape File
        </Button>
      </Form.Item>
      <SModal footer={null} open={openModal} title="ข้อความจากระบบ" style={{ fontSize: '500px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div>ยืนยันการส่งออกข้อมูล</div>
          <div>
            <Button
              style={{ background: 'green', color: 'white', ...SButtonMobile }}
              onClick={() => {
                if (linkExport) window.open(linkExport, '_blank');
                setOpenModal(false);
              }}
            >
              ตกลง
            </Button>
            <Button
              style={{ background: 'grey', color: 'white', ...SButtonMobile }}
              onClick={() => setOpenModal(false)}
            >
              ยกเลิก
            </Button>
          </div>
        </div>
      </SModal>
    </SForm>
  );
};

export default TabExportLayerData;
const SModal = styled(Modal)``;
const SForm = styled(Form)``;
