import React, { useContext, useEffect, useRef } from 'react';
import { Map } from 'mapbox-gl';
import { MainPageContext } from '../../contexts/MainPageContext';
interface LatLngHoverProps {
  map: Map | null;
  location: { lat: number; lng: number };
}
const LatLngHover = ({ map, location }: LatLngHoverProps) => {
  const { isMobile } = useContext(MainPageContext);
  const containerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (map && containerRef.current) {
      const controlEl = document.createElement('div');
      const newContent = document.createTextNode(
        isMobile
          ? `latitude: ${location.lat.toFixed(4)}, longitude: ${location.lng.toFixed(4)}`
          : `latitude: ${location.lat.toFixed(5)}, longitude: ${location.lng.toFixed(5)}`
      );
      controlEl.appendChild(newContent);
      controlEl.style.backgroundColor = 'hsla(0,0%,100%,.75)';
      controlEl.style.position = 'absolute';
      controlEl.style.fontSize = isMobile ? '2vw' : '10px';
      controlEl.style.bottom = isMobile ? '33px' : '33px';
      controlEl.style.padding = '0px 10px';
      controlEl.style.border = '1px solid black';
      controlEl.style.right = isMobile ? '12px' : '80px';
      controlEl.style.width = isMobile ? '55%' : '220px';
      controlEl.style.height = isMobile ? '15px' : '20px';
      controlEl.style.borderRadius = '0px';
      controlEl.style.display = 'flex';
      controlEl.style.alignItems = 'center';
      controlEl.style.cursor = 'pointer';

      containerRef.current.appendChild(controlEl);

      return () => {
        containerRef.current && containerRef.current.removeChild(controlEl);
      };
    }
  }, [map, location.lat, location.lng]);

  return <div ref={containerRef} />;
};
export default LatLngHover;
