import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import apiLogin from '../apis/login';
import logo from '../assets/images/logo.png';
import { MainPageContext } from '../contexts/MainPageContext';
import { UserContext } from '../contexts/UserContext';
interface ILoginModal {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const LoginModal = ({ isModalOpen, setIsModalOpen }: ILoginModal) => {
  const { setUserInformation, keycloakInstance } = useContext(UserContext);
  const { isMobile } = useContext(MainPageContext);
  const [usernameInput, setUsernameInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [errorAlert, setErrorAlert] = useState('');
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    handleClearInput();
  };
  const handleClearInput = () => {
    setUsernameInput('');
    setPasswordInput('');
  };
  const onLogin = async () => {
    const checkUser = await apiLogin.login({ username: usernameInput, password: passwordInput });
    handleClearInput();
    if (checkUser === false) {
      setErrorAlert('user is not found');
    } else {
      setUserInformation(checkUser);
      setIsModalOpen(false);
    }
  };
  return (
    <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
      <SContainer>
        <div>
          <img style={{ width: '80px' }} alt="กรมป่าไม้" src={logo} />
        </div>
        <div style={isMobile ? { fontSize: '2rem' } : {}}>เข้าสู่ระบบ</div>
        <Input
          style={isMobile ? { height: '6rem', fontSize: '2rem' } : {}}
          placeholder="ชื่อผู้ใช้งาน"
          value={usernameInput}
          status={errorAlert ? 'error' : ''}
          onChange={(e) => {
            setUsernameInput(e.target.value);
            setErrorAlert('');
          }}
        />
        <Input.Password
          style={isMobile ? { height: '6rem', fontSize: '2rem' } : {}}
          placeholder="รหัสผ่าน"
          value={passwordInput}
          status={errorAlert ? 'error' : ''}
          onChange={(e) => {
            setPasswordInput(e.target.value);
            setErrorAlert('');
          }}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
        <SError>{errorAlert}</SError>
        <Button
          type="primary"
          style={
            isMobile
              ? { background: 'green', width: '100%', height: '5rem', fontSize: '2rem' }
              : { background: 'green', width: '100%' }
          }
          onClick={() => onLogin()}
        >
          เข้าสู่ระบบ
        </Button>
        <Button
          htmlType="button"
          style={
            isMobile
              ? {
                  // background: 'gray',
                  // color: 'white',
                  width: '100%',
                  height: '5rem',
                  fontSize: '2rem',
                }
              : {
                  // background: 'gray',
                  // color: 'white',
                  width: '100%',
                }
          }
          onClick={() => {
            keycloakInstance.login();
          }}
        >
          เข้าสู่ระบบ Keycloak
        </Button>
      </SContainer>
    </Modal>
  );
};
export default LoginModal;
const SError = styled.div`
  width: 100%;
  color: red;
`;
const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    margin-top: 5px;
  }
  & > input {
    margin-top: 5px;
  }
  & > button {
    margin-top: 5px;
  }
`;
