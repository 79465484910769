import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import apiMasterData from '../apis/master_data';
import apiSearch from '../apis/search';
import {
  ForestAPI$DepartmentGroup,
  ForestAPI$DepartmentParent,
  ForestAPI$District,
  ForestAPI$MasterBaseMap,
  ForestAPI$MasterLayer,
  ForestAPI$Province,
  ForestAPI$SearchSubject,
  ForestAPI$SubDistrict,
} from '../interfaces/forest_api.interface';
import { UserContext } from './UserContext';

export interface IMasterDataContext {
  masterLayer: ForestAPI$MasterLayer[];
  masterBaseMaps: ForestAPI$MasterBaseMap[];
  searchSubjects: ForestAPI$SearchSubject[];
  provinces: ForestAPI$Province[];
  districts: ForestAPI$District[];
  subDistricts: ForestAPI$SubDistrict[];
  departmentParents: ForestAPI$DepartmentParent[];
  departmentGroups: ForestAPI$DepartmentGroup[];
  provinceCode: string;
  setProvinceCode: Dispatch<SetStateAction<string>>;
  districtCode: string;
  setDistrictCode: Dispatch<SetStateAction<string>>;
  // Prepare remove
  optionProvinces: ForestAPI$Province[];
  optionDistricts: ForestAPI$District[];
  optionSubDistricts: ForestAPI$SubDistrict[];
  optionDepartmentParent: ForestAPI$DepartmentParent[];
  optionDepartmentGroup: ForestAPI$DepartmentGroup[];
}

export const MasterDataContext = createContext<IMasterDataContext>({
  masterLayer: [],
  masterBaseMaps: [],
  searchSubjects: [],
  provinces: [],
  districts: [],
  subDistricts: [],
  departmentParents: [],
  departmentGroups: [],

  provinceCode: '',
  setProvinceCode: useState,
  districtCode: '',
  setDistrictCode: useState,

  optionProvinces: [],
  optionDistricts: [],
  optionSubDistricts: [],
  optionDepartmentParent: [],
  optionDepartmentGroup: [],
});

interface IMasterDataContextProvider {
  children: ReactNode;
}

const MasterDataContextProvider: React.FC<IMasterDataContextProvider> = ({ children }) => {
  const { userInformation } = useContext(UserContext);
  const [masterLayer, setMasterLayer] = useState<ForestAPI$MasterLayer[]>([]);
  const [masterBaseMaps, setMasterBaseMaps] = useState<ForestAPI$MasterBaseMap[]>([]);
  const [searchSubjects, setSearchSubjects] = useState<ForestAPI$SearchSubject[]>([]);
  const [provinces, setProvinces] = useState<ForestAPI$Province[]>([]);
  const [districts, setDistricts] = useState<ForestAPI$District[]>([]);
  const [subDistricts, setSubDistricts] = useState<ForestAPI$SubDistrict[]>([]);

  const [provinceCode, setProvinceCode] = useState<string>('');
  const [districtCode, setDistrictCode] = useState<string>('');

  const [departmentParents, setDepartmentParent] = useState<ForestAPI$DepartmentParent[]>([]);
  const [departmentGroups, setDepartmentGroup] = useState<ForestAPI$DepartmentGroup[]>([]);

  //#region API request
  const fetchSearchSubjects = async () => {
    const { subjects } = await apiSearch.fetchSubjects();
    setSearchSubjects(subjects);
  };

  const fetchDepartmentParents = async () => {
    const res = await apiMasterData.fetchDepartmentParents();
    setDepartmentParent(res.departments);
  };

  const fetchDepartmentGroups = async () => {
    const res = await apiMasterData.fetchDepartmentGroups();
    setDepartmentGroup(res.departments);
  };

  const fetchMasterLayers = async () => {
    const masterLayer = await apiMasterData.fetchMasterLayers();
    setMasterLayer(masterLayer.master_layers);
  };

  const fetchMasterBaseMaps = async () => {
    const masterBaseMaps = await apiMasterData.fetchMasterBaseMaps();
    setMasterBaseMaps(masterBaseMaps.base_maps);
  };

  const fetchProvinceOptions = async () => {
    const provinceData = await apiMasterData.fetchProvinces();
    setProvinces(provinceData.provinces);
  };

  const fetchDistrictOptions = async (provinceCode: string) => {
    const res = await apiMasterData.fetchDistricts(provinceCode);
    setDistricts(res.districts);
  };

  const fetchSubDistrictOptions = async (provinceCode: string, districtCode: string) => {
    const res = await apiMasterData.fetchSubDistricts(provinceCode, districtCode);
    setSubDistricts(res.sub_districts);
  };
  //#endregion

  useEffect(() => {
    fetchDepartmentParents();
    fetchDepartmentGroups();
    fetchMasterLayers();
    fetchMasterBaseMaps();
    fetchSearchSubjects();
    fetchProvinceOptions();
  }, [userInformation]);

  useEffect(() => {
    if (provinceCode) {
      fetchDistrictOptions(provinceCode);
    }
    setDistricts([]);
    setSubDistricts([]);
  }, [provinceCode]);

  useEffect(() => {
    if (provinceCode && districtCode) {
      fetchSubDistrictOptions(provinceCode, districtCode);
    }
    setSubDistricts([]);
  }, [districtCode]);

  return (
    <MasterDataContext.Provider
      value={{
        searchSubjects,
        departmentParents,
        departmentGroups,
        masterLayer,
        masterBaseMaps,
        provinces,
        districts,
        subDistricts,
        provinceCode,
        setProvinceCode,
        districtCode,
        setDistrictCode,
        optionProvinces: provinces,
        optionDistricts: districts,
        optionSubDistricts: subDistricts,
        optionDepartmentParent: departmentParents,
        optionDepartmentGroup: departmentGroups,
      }}
    >
      {children}
    </MasterDataContext.Provider>
  );
};

export default MasterDataContextProvider;
