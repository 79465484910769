import { instance } from './instance';

interface ILogin {
  username: string;
  password: string;
}
const login = async ({ username, password }: ILogin) => {
  const token = JSON.parse(`${localStorage.getItem('userLogin')}`)?.token || '';
  try {
    const result = await instance.post(
      '/login',
      { username: username, password: password },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    await localStorage.setItem('userLogin', JSON.stringify(result.data));
    return result.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const loginWithKeycloak = async ({ username, password }: ILogin) => {
  try {
    const result = await instance.post('/login/keycloak', { username: username, token: password });

    // await localStorage.setItem('userLoginKeycloak', JSON.stringify(result.data));
    return result.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};
const apiLogin = {
  login,
  loginWithKeycloak,
};
export default apiLogin;
