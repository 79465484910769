import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Map } from 'mapbox-gl';
import Ruler from '../../assets/icons/ruler.svg';
import CustomControl from './index.custom';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { MenuProps, Select } from 'antd';
import { ReactComponent as RulerIcon } from '../../assets/icons/ruler-icon.svg';
import { ReactComponent as AreaIcon } from '../../assets/icons/area.svg';
import { ReactComponent as TableIcon } from '../../assets/icons/area-icon.svg';
import { MainPageContext } from '../../contexts/MainPageContext';
import * as turf from '@turf/turf';
interface CustomControlProps {
  tableResult: { lat: number; lng: number };
  map: Map | null;
  selectedCustomTool: string;
  setSelectedCustomTool: React.Dispatch<React.SetStateAction<string>>;
  setMeasureSelect: React.Dispatch<React.SetStateAction<'area' | 'ruler' | 'table' | undefined>>;
  measureSelect: 'area' | 'ruler' | 'table' | undefined;
  rulerResult: number;
  squareResult: number;
}
const MeasurementCustomControl = ({
  map,
  tableResult,
  selectedCustomTool,
  setSelectedCustomTool,
  setMeasureSelect,
  measureSelect,
  rulerResult,
  squareResult,
}: CustomControlProps) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [measure, setMeasure] = useState('');
  const { isMobile } = useContext(MainPageContext);
  const [optionSelected, setOptionSelected] = useState<{ value: string; label: string }[]>([]);
  useEffect(() => {
    if (measureSelect === 'area') {
      setMeasure('squareKilometers');
      setOptionSelected([
        { value: 'squareKilometers', label: 'ตารางกิโลเมตร' },
        { value: 'squareMeters', label: 'ตารางเมตร' },
        { value: 'rai', label: 'ไร่ งาน วา' },
      ]);
    } else if (measureSelect === 'ruler') {
      setMeasure('kilometers');
      setOptionSelected([
        { value: 'kilometers', label: 'กิโลเมตร' },
        { value: 'meters', label: 'เมตร' },
      ]);
    } else {
      setOptionSelected([]);
    }
  }, [measureSelect]);
  useEffect(() => {
    if (selectedCustomTool !== 'measurement') {
      setOpenPopup(false);
    }
  }, [selectedCustomTool]);

  return (
    <SContainer>
      <CustomControl
        map={map}
        top={'340px'}
        svg={Ruler}
        onClick={() => {
          setSelectedCustomTool('measurement');
          setOpenPopup(true);
        }}
      />
      {openPopup && (
        <SPopupContainer className={`${isMobile ? 'mobile' : ''}`}>
          <div className="head">
            <div style={{ color: 'white' }}>เครื่องมือวัด</div>
            <div
              onClick={() => {
                setOpenPopup(false);
              }}
              style={{ cursor: 'pointer' }}
            >
              <CloseOutlined />
            </div>
          </div>
          <SToolContainer>
            <SIcon
              className={`${measureSelect === 'area' ? 'selected' : ''}`}
              onClick={() => {
                measureSelect === 'area' ? setMeasureSelect(undefined) : setMeasureSelect('area');
              }}
            >
              <AreaIcon />
            </SIcon>
            <SIcon
              className={`${measureSelect === 'ruler' ? 'selected' : ''}`}
              onClick={() => {
                measureSelect === 'ruler' ? setMeasureSelect(undefined) : setMeasureSelect('ruler');
              }}
            >
              <RulerIcon />
            </SIcon>
            <SIcon
              className={`${measureSelect === 'table' ? 'selected' : ''}`}
              onClick={() => {
                measureSelect === 'table' ? setMeasureSelect(undefined) : setMeasureSelect('table');
              }}
            >
              <TableIcon />
            </SIcon>

            <div>
              {optionSelected.length ? (
                <Select
                  // value={'' || optionSelected[0].label}
                  value={measure}
                  defaultValue={'' || optionSelected[0].label}
                  style={{ width: 120 }}
                  onChange={(e) => setMeasure(e)}
                  options={optionSelected}
                />
              ) : (
                <></>
              )}
            </div>
          </SToolContainer>
          <div className="result">ผลลัพธ์</div>
          {measureSelect === 'ruler' && (
            <div>
              {`${
                measure === 'kilometers' ? rulerResult.toFixed(2) : (rulerResult * 1000).toFixed(2)
              }
              ${measure}`}
            </div>
          )}
          {measureSelect === 'area' && (
            <div>
              {` ${
                measure === 'squareMeters'
                  ? squareResult.toFixed(2)
                  : measure === 'squareKilometers'
                  ? (squareResult / 1000000).toFixed(2)
                  : (squareResult * 0.000625).toFixed(2)
              }
             ${measure}`}
            </div>
          )}
          {measureSelect === 'table' && (
            <div>
              <div>{`Latitude: ${tableResult.lat}`}</div>
              <div>{`Longitude: ${tableResult.lng}`}</div>
            </div>
          )}
        </SPopupContainer>
      )}
    </SContainer>
  );
};

export default MeasurementCustomControl;
const SIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 3px;
  &.selected {
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: inset 0 0 2px #363636;
  }
`;
const SToolContainer = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-right: 5px;
  }
`;
const SPopupContainer = styled.div`
  position: absolute;
  min-width: 30vw;
  top: 10px;
  right: 60px;
  background-color: white;
  border-radius: 5px;
  z-index: 400;
  &.mobile {
    width: 300px;
    height: 300px;
  }
  & > div.head {
    background-color: green;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
  }
  & > div.result {
    border-bottom: 1px solid black;
    /* font-size: 20px; */
  }
  & > div {
    padding: 0px 5px 0px 5px;
  }
`;
const SContainer = styled.div`
  position: relative;
`;
