import { IControl, Map as MapboxMap } from 'mapbox-gl';
import CrosshairManager from './crosshair-manager';
import { Translation, english, finnish, french, german, swedish, vietnam } from './local';
import MapGenerator, { DPI, Format, PageOrientation, Size, Unit } from './map-generator';
import PrintableAreaManager from './printable-area-manager';

export const MetaType = {
  NAME: 'map-name',
  CREATOR: 'map-creator',
  DESCRIPTION: 'map-description',
} as const;
type MetaType = typeof MetaType[keyof typeof MetaType];

type Options = {
  PageSize: any;
  PageOrientation: string;
  Format: string;
  DPI: number;
  Crosshair?: boolean;
  PrintableArea: boolean;
  accessToken?: string;
  Local?: 'de' | 'en' | 'fr' | 'fi' | 'sv' | 'vi';
  setSelectedCustomTool: any;
};

/**
 * Mapbox GL Export Control.
 * @param {Object} targets - Object of layer.id and title
 */
export default class MapboxExportControl implements IControl {
  private controlContainer!: HTMLElement;

  private exportContainer: HTMLElement | undefined;

  private crosshair: CrosshairManager | undefined;

  private printableArea: PrintableAreaManager | undefined;

  private map?: MapboxMap;

  private exportButton: HTMLButtonElement | undefined;

  private options: Options = {
    PageSize: Size.A4,
    PageOrientation: PageOrientation.Landscape,
    Format: Format.PDF,
    DPI: DPI[300],
    Crosshair: false,
    PrintableArea: false,
    accessToken: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSelectedCustomTool: () => {},
  };

  constructor(options: Options) {
    if (options) {
      this.options = Object.assign(this.options, options);
    }
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  public getDefaultPosition(): string {
    const defaultPosition = 'top-right';
    return defaultPosition;
  }

  public getTranslation(): Translation {
    switch (this.options.Local) {
      case 'de':
        return german;
      case 'en':
        return english;
      case 'fr':
        return french;
      case 'fi':
        return finnish;
      case 'sv':
        return swedish;
      case 'vi':
        return vietnam;
      default:
        return english;
    }
  }

  public onAdd(map: MapboxMap): HTMLElement {
    this.map = map;
    this.controlContainer = document.createElement('div');
    this.controlContainer.classList.add('mapboxgl-ctrl');
    this.controlContainer.classList.add('mapboxgl-ctrl-group');
    this.exportContainer = document.createElement('div');
    this.exportContainer.classList.add('mapboxgl-export-list');
    this.exportButton = document.createElement('button');
    this.exportButton.classList.add('mapboxgl-ctrl-icon');
    this.exportButton.classList.add('mapboxgl-export-control');
    this.exportButton.type = 'button';
    this.exportButton.addEventListener('click', () => {
      if (this.exportButton) this.exportButton.style.display = 'none';
      if (this.exportContainer) this.exportContainer.style.display = 'block';
      this.toggleCrosshair(true);
      this.togglePrintableArea(true);
    });
    this.controlContainer.addEventListener('click', () =>
      this.options.setSelectedCustomTool('print')
    );
    document.addEventListener('click', this.onDocumentClick);
    this.controlContainer.appendChild(this.exportButton);
    this.controlContainer.appendChild(this.exportContainer);

    const table = document.createElement('TABLE');
    table.className = 'print-table';

    // Print Meta
    const metaForm: { label: string; type: MetaType; placeHolder: string }[] = [
      { label: 'ชื่อแผนที่', type: MetaType.NAME, placeHolder: 'กรุณาระบุชื่อแผนที่' },
      { label: 'ผู้จัดทำ', type: MetaType.CREATOR, placeHolder: 'กรุณาระบุชื่อผู้จัดทำๅ' },
      { label: 'รายละเอียด', type: MetaType.DESCRIPTION, placeHolder: 'รายละเอียด' },
    ];

    // const tr1 = this.createSelection(
    //   Size,
    //   this.getTranslation().PageSize,
    //   'page-size',
    //   this.options.PageSize,
    //   (data: any, key: any) => JSON.stringify(data[key])
    // );
    // table.appendChild(tr1);

    // const tr2 = this.createSelection(
    //   PageOrientation,
    //   this.getTranslation().PageOrientation,
    //   'page-orientaiton',
    //   this.options.PageOrientation,
    //   (data: any, key: any) => data[key]
    // );
    // table.appendChild(tr2);

    metaForm.forEach((meta) => {
      const row = this.createInputRow(meta.label, meta.type, meta.placeHolder);
      table.appendChild(row);
    });

    // const tr3 = this.createSelection(
    //   Format,
    //   this.getTranslation().Format,
    //   'format-type',
    //   this.options.Format,
    //   (data: any, key: any) => data[key]
    // );
    // table.appendChild(tr3);

    // const tr4 = this.createSelection(
    //   DPI,
    //   this.getTranslation().DPI,
    //   'dpi-type',
    //   this.options.DPI,
    //   (data: any, key: any) => data[key]
    // );
    // table.appendChild(tr4);

    this.exportContainer.appendChild(table);

    const generateButton = document.createElement('button');
    generateButton.type = 'button';
    generateButton.textContent = this.getTranslation().Generate;
    generateButton.classList.add('generate-button');
    generateButton.addEventListener('click', async () => {
      // const pageSize: HTMLSelectElement = <HTMLSelectElement>(
      //   document.getElementById(`mapbox-gl-export-page-size`)
      // );
      // const pageOrientation: HTMLSelectElement = <HTMLSelectElement>(
      //   document.getElementById('mapbox-gl-export-page-orientaiton')
      // );

      // const formatType: HTMLSelectElement = <HTMLSelectElement>(
      //   document.getElementById('mapbox-gl-export-format-type')
      // );
      // const dpiType: HTMLSelectElement = <HTMLSelectElement>(
      //   document.getElementById('mapbox-gl-export-dpi-type')
      // );
      // const orientValue = pageOrientation.value;
      // let pageSizeValue = JSON.parse(pageSize.value);
      // if (orientValue === PageOrientation.Portrait) {
      //   pageSizeValue = pageSizeValue.reverse();
      // }

      const metaMapName: HTMLInputElement = <HTMLInputElement>(
        document.getElementById(`mapbox-gl-export-map-name`)
      );
      const metaMapCreator: HTMLInputElement = <HTMLInputElement>(
        document.getElementById(`mapbox-gl-export-map-creator`)
      );
      const metaMapDescription: HTMLInputElement = <HTMLInputElement>(
        document.getElementById(`mapbox-gl-export-map-description`)
      );

      const mapGenerator = new MapGenerator(
        map,
        Size.A4,
        DPI[72],
        Format.PDF,
        Unit.mm,
        this.options.accessToken || '',
        {
          name: metaMapName.value,
          creator: metaMapCreator.value,
          description: metaMapDescription.value,
          is_show_ratio: false,
        }
      );
      await mapGenerator.generate();
    });
    this.exportContainer.appendChild(generateButton);

    return this.controlContainer;
  }

  private createSelection(
    data: any,
    title: string,
    type: string,
    defaultValue: any,
    converter: (data: any, key: any) => any
  ): HTMLElement {
    const label = document.createElement('label');
    label.textContent = title;

    const content = document.createElement('select');
    content.setAttribute('id', `mapbox-gl-export-${type}`);
    content.style.width = '100%';
    Object.keys(data).forEach((key) => {
      const optionLayout = document.createElement('option');
      optionLayout.setAttribute('value', converter(data, key));
      optionLayout.appendChild(document.createTextNode(key));
      optionLayout.setAttribute('name', type);
      if (defaultValue === data[key]) {
        optionLayout.selected = true;
      }
      content.appendChild(optionLayout);
    });
    content.addEventListener('change', () => {
      this.updatePrintableArea();
    });

    const tr1 = document.createElement('TR');
    const tdLabel = document.createElement('TD');
    const tdContent = document.createElement('TD');
    tdLabel.appendChild(label);
    tdContent.appendChild(content);
    tr1.appendChild(tdLabel);
    tr1.appendChild(tdContent);
    return tr1;
  }

  private createInputRow(title: string, type: MetaType, placeHolder: string): HTMLElement {
    const label = document.createElement('label');
    label.textContent = title;

    const content =
      type === MetaType.DESCRIPTION
        ? document.createElement('textarea')
        : document.createElement('input');

    content.setAttribute('type', 'text');
    content.setAttribute('id', `mapbox-gl-export-${type}`);
    content.setAttribute('placeholder', placeHolder);
    content.style.width = '100%';
    // content.addEventListener('change', () => {
    //   //
    // });

    const tr1 = document.createElement('TR');
    const tdLabel = document.createElement('TD');
    const tdContent = document.createElement('TD');
    tdLabel.appendChild(label);
    tdContent.appendChild(content);
    tr1.appendChild(tdLabel);
    tr1.appendChild(tdContent);
    return tr1;
  }

  public onRemove(): void {
    if (
      !this.controlContainer ||
      !this.controlContainer.parentNode ||
      !this.map ||
      !this.exportButton
    ) {
      return;
    }
    this.exportButton.removeEventListener('click', this.onDocumentClick);
    this.controlContainer.parentNode.removeChild(this.controlContainer);
    document.removeEventListener('click', this.onDocumentClick);

    if (this.crosshair !== undefined) {
      this.crosshair.destroy();
      this.crosshair = undefined;
    }

    this.map = undefined;
  }

  private onDocumentClick(event: MouseEvent): void {
    if (
      this.controlContainer &&
      !this.controlContainer.contains(event.target as Element) &&
      this.exportContainer &&
      this.exportButton
    ) {
      this.exportContainer.style.display = 'none';
      this.exportButton.style.display = 'block';
      this.toggleCrosshair(false);
      this.togglePrintableArea(false);
    }
  }

  private toggleCrosshair(state: boolean) {
    if (this.options.Crosshair === true) {
      if (state === false) {
        if (this.crosshair !== undefined) {
          this.crosshair.destroy();
          this.crosshair = undefined;
        }
      } else {
        this.crosshair = new CrosshairManager(this.map);
        this.crosshair.create();
      }
    }
  }

  private togglePrintableArea(state: boolean) {
    if (this.options.PrintableArea === true) {
      if (state === false) {
        if (this.printableArea !== undefined) {
          this.printableArea.destroy();
          this.printableArea = undefined;
        }
      } else {
        this.printableArea = new PrintableAreaManager(this.map);
        this.updatePrintableArea();
      }
    }
  }

  private updatePrintableArea() {
    if (this.printableArea === undefined) {
      return;
    }
    // const pageSize: HTMLSelectElement = <HTMLSelectElement>(
    //   document.getElementById('mapbox-gl-export-page-size')
    // );
    // const pageOrientation: HTMLSelectElement = <HTMLSelectElement>(
    //   document.getElementById('mapbox-gl-export-page-orientaiton')
    // );
    // const orientValue = pageOrientation?.value;
    // let pageSizeValue = JSON.parse(pageSize.value);

    const orientValue = this.options.PageOrientation;
    let pageSizeValue = this.options.PageSize;
    if (orientValue === PageOrientation.Portrait) {
      pageSizeValue = pageSizeValue.reverse();
    }

    this.printableArea.updateArea(pageSizeValue[0], pageSizeValue[1]);
  }
}
