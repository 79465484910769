import { Map } from 'mapbox-gl';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import iCon2D from '../../assets/icons/2d.svg';
import iCon3D from '../../assets/icons/3d.svg';
import CustomControl from './index.custom';
interface HomeControlProps {
  map: Map | null;
}

const Toggle3DCustomControl = ({ map }: HomeControlProps) => {
  const [is3D, setIs3D] = useState<boolean>(false);

  useEffect(() => {
    if (!map) return;

    if (is3D) {
      map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
    } else {
      map.setTerrain({ source: 'mapbox-dem', exaggeration: 0 });
    }
  }, [map, is3D]);

  return (
    <SContainer>
      <CustomControl
        map={map}
        top={'540px'}
        svg={is3D ? iCon2D : iCon3D}
        onClick={() => {
          setIs3D(!is3D);
        }}
      />
    </SContainer>
  );
};

export default Toggle3DCustomControl;
const SContainer = styled.div`
  position: relative;
`;
